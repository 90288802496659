<input
    *ngIf="questionControl"
    class="form-control"
    [attr.type]="type"
    [id]="name"
    [formControl]="questionControl"
    [name]="name"
    [attr.placeholder]="options?.placeholder ? (options.placeholder | localize) : null"
    [attr.appTextTransform]="options?.appTextTransform || null"
    [attr.maxlength]="options?.maxLength || null"
    [attr.mask]="options?.mask || null"
    [attr.pattern]="options?.pattern || null"
    [attr.title]="options?.tooltip || null"
/>
