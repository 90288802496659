import { UiCustomizationSettingsDto } from "./service-proxies/service-proxies";

export class AppConsts {
    static readonly localeMappings: any = [];

    static readonly localization = {
        defaultLocalizationSourceName: 'PreEmploi'
    };

    static readonly grid = {
        defaultPageSize: 10
    };

    static readonly statusColor = {
        new: '#30A0E3',
        draft: '#72768B',
        inProgressGardium: '#EBC743',
        inProgressClient: '#EBC743',
        inProgress: '#EBC743',
        inProgressEzSign: '#EBC743',
        inProgressPpo: '#EBC743',
        missingInfo: '#925CB1',
        cancel: '#C62828',
        completed: '#3CA466',
        completedNotCompleted: '#000000',
        completedPositive: '#e60000',
        completedNegative: '#3CA466',
        completedExcellent: '#3CA466',
        completedVeryGood: '#3CA466',
        completedGood: '#3CA466',
        completedFair: '#e60000',
        completedLow: '#e60000',
        completedInvalid: '#e60000',
        completedConfirmed: '#e60000',
        completedIncomplete: '#e60000',
        completedNotAvailable: '#000000',
        cantBeCompleted: '#C62828',
        billed: '#3498db',
        cantBeCompletedVerif: '#C62828',
        furtherCheckRecommended: "#e60000"
    };

    static readonly dateTimeFormat = 'yyyy/MM/dd HH:mm';
    static readonly dateTimeFormatOnlyDate = 'yyyy/MM/dd';
    static readonly dateTimeFormatDay = 'dd';
    static readonly mtlTimezone = 'America/Montreal';

    static readonly monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    static readonly linkFrench = '/assets/common/images/termesAndConditions/Conditions_dutilisation_portail_Gardium.pdf';
    static readonly linkEnglish = '/assets/common/images/termesAndConditions/Gardium_Portal_Terms_and_Conditions_of_Use.pdf';

    static readonly allowedMimeType = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png'];
    static readonly maxFileSize = 53477376; // 50MB

    static readonly masks = {
        sin: '000 000 000',
        qcdriveLicence: 'S0000-000000-00',
        canadaPostalCode: 'S0S 0S0',
        phoneNumber: '(000) 000-0000',
        price: '0*.00'
    };

    static readonly patterns = {
        email: 'test@test.com || test.test@test.ca || test@test.test.com || test@test.test.ca'
    };

    static readonly regex = {
        email: /^\w+(?:[-.+]\w+)*@\w+(?:[-.]\w+)*(?:\.\w{2,30})+$/,
        multipleEmailsCsv: /^([\w-.]+@[\w-.]+.[a-zA-Z]{2,5})([;.][\w-.]+@[\w-.]+.[a-zA-Z]{2,5})*$/gm,
        invoiceNumber: /^\d{3}[\s.-]?\d{3}[\s.-]?\d{3}$/,
        creditCard: /^(\d{16})$/,
        postalCodeCA: /^[a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]\d$|^$/,
        postalCodeUS: /^\d{5}(?:[-\s]\d{4})?$|^$/,
        phoneNumber: /^\D*(\d{3})\D*(\d{3})\D*(\d{4})\D*$/
    };

    static readonly theme = {
        baseSettings:
        {
            theme: "default",
            layout:
            {
                layoutType: "fluid",
                darkMode: false,
                contentSkin: null,
                themeColor: null,
                fixedBody: false,
                mobileFixedBody: false
            },
            header:
            {
                minimizeDesktopHeaderType: null
            },
            subHeader:
            {
                fixedSubHeader: true,
                subheaderStyle: "solid",
                subheaderSize: 1,
                titleStyle: "gardium-title mt-2 mb-2 mr-5",
                containerStyle: "subheader py-2 py-lg-4 subheader-solid",
                subContainerStyle: null
            },
            menu: {
                position: "left",
                asideSkin: "light",
                fixedAside: true,
                allowAsideMinimizing: true,
                defaultMinimizedAside: false,
                allowAsideHiding: false,
                defaultHiddenAside: false,
                submenuToggle: "false",
                searchActive: false,
                enableSecondary: false,
                hoverableAside: false
            },
            footer: {
                fixedFooter: false
            }
        },
        isLeftMenuUsed: true,
        isTopMenuUsed: false,
        isTabMenuUsed: false,
        allowMenuScroll: true
    } as UiCustomizationSettingsDto;
}
