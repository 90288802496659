import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { AppConsts } from '@shared/AppConsts';

/**
 * This is a standard phone input for a form anywhere on the website. If you need to add additional composed validators for special cases like
 * {@link atLeastOneFieldRequired}, you can add them to the parent's controller after the form is created using the {@link PhoneInputComponent.formControlName}.
 * If you need to pass validators for this field only, you can use the {@link FormQuestionOptions.extraValidators} property.
 * @params *__parentFormControl__* - The form group in which we will add this question's control.
 * @params *__isMandatory__* - *(Optional)* If the question is mandatory or not. If true, a required validator will be added to the control.
 * @params *__startingValue__* - *(Optional)* The starting value for the input.
 * @params *__options__* - *(Optional)* Additional options for the question. See {@link FormQuestionOptions}. This component adds the following options:
 * - maxLength: 256
 * - isMandatory: Either what was provided or false by default
 * - mask {@link AppConsts.masks.phoneNumber}
 */
@Component({
    standalone: true,
    selector: 'phone-input[parentFormControl]',
    imports: [NgxMaskModule, FormGroupComponent, ReactiveFormsModule],
    templateUrl: 'phone-input.component.html'
})
export class PhoneInputComponent implements OnInit {
    @Input() protected readonly parentFormControl: FormGroup;
    @Input() protected readonly isMandatory = false;
    @Input() protected readonly startingValue: string | null = null;
    @Input() protected options: FormQuestionOptions = {};

    public static readonly formControlName = 'phoneNumber';

    protected readonly formControl = new FormControl(this.startingValue);

    ngOnInit(): void {
        this.parentFormControl.addControl(PhoneInputComponent.formControlName, this.formControl);

        this.options = {
            ...this.options,
            maxLength: 256,
            isMandatory: this.isMandatory
        };
    }

    protected get name(): string {
        return PhoneInputComponent.formControlName;
    }

    protected get mask(): string {
        return AppConsts.masks.phoneNumber;
    }

    protected get label(): string {
        return 'PhoneNumber';
    }
}
