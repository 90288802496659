import { Component, OnInit, ViewChild, Input, Injector, EventEmitter, Output } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AppComponentBase } from '@shared/common/app-component-base';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { FilesOutput } from '@shared/models/files/filesOutput';
import { AddRequestFilesComponent } from '../add-request-files/add-request-files.component';
import { RequestFileInput } from '@shared/models/requestFile/requestFileInput';
import { FileDownloadService } from '@shared/utils/file-download.service';
import { ToastrService } from 'ngx-toastr';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { fileType } from '@shared/models/files/fileType';
import { GridBtnComponent } from '@app/shared/grid/grid-btn/grid-btn.component';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import {RequestFilesService} from "@shared/services/request-files.service";
import {FileDto} from "@shared/service-proxies/service-proxies";

@Component({
    selector: 'list-request-files',
    templateUrl: './list-request-files.component.html',
    providers: [DatePipe]
})
export class ListRequestFilesComponent extends AppComponentBase implements OnInit {
    @Input() private readonly requestId: number;
    @Input() private readonly requestIsOpen: boolean = true;
    @Input() protected title: string | null = null;
    @Input() protected fileListType: fileType;
    @Input() protected allReadOnly: boolean = false;

    @Output() public onFileListUpdate: EventEmitter<FilesOutput[]> = new EventEmitter<FilesOutput[]>();

    @ViewChild('agGrid') private readonly agGrid: AgGridAngular;
    @ViewChild('addRequestFiles') private readonly addRequestFiles: AddRequestFilesComponent;

    private gridApi;

    protected defaultColDef;
    protected paginationNumberFormatter = (params: any): string => params.value.toLocaleString();
    protected paginationPageSize: number = 20;
    protected rowData: FilesOutput[] = [];
    protected rowSelection: string = 'single';
    protected columnDefs: any;
    protected description: string;

    constructor(
        private readonly injector: Injector,
        private readonly _requestFileService: RequestFilesService,
        private readonly downloadService: FileDownloadService,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly datePipe: DatePipe,
        private readonly toastr: ToastrService,
        private readonly _appAuthService: AppAuthService,
        private readonly _dateTimeService: DateTimeService
    ) {
        super(injector);
    }

    ngOnInit() {
        if (this.requestId !== null && this.requestId !== undefined) {
            this.getAllFiles();

            //If request is closed (statut "Terminé"), we need a special permission to update it.
            if (!this.allReadOnly && !this.requestIsOpen) {
                this.allReadOnly = !this._appAuthService.hasPermission('Pages.Management.Requests.UpdateWhenDone');
            }
        }

        this.setListType();
        this.setColumn();
    }

    protected onGridReady(params: any) {
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    protected onPageSizeChanged(e: number) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    protected getAllFiles() {
        this._requestFileService.getRequestFilesOfGivenType(this.requestId, this.fileListType).subscribe({
            next: (response: FilesOutput[]) => {
                this.rowData = response;
                this.onFileListUpdate.emit(this.rowData);
            },
            error: (_) => {
                this.showErrorMessage('FileErrorGet', 'FileTryAgainLater');
            }
        });
    }

    protected addFile() {
        this.addRequestFiles.requestId = this.requestId;
        this.addRequestFiles.show();
    }

    private setListType() {
        switch(this.fileListType) {
            case fileType.Filled:
                this.description = this._appLocalizationService.l('FileToFiledDescription');
                this.title = this.title || this._appLocalizationService.l('FilesFilled');
                break;
            case fileType.MandatoryToComplete:
                this.description = this._appLocalizationService.l('MandatoryFileDescription');
                this.allReadOnly = this.allReadOnly || !this._appAuthService.hasPermission('Pages.Management.Requests.CanAddConsentFiles');
                this.title = this._appLocalizationService.l('FilesMandatory');
                break;
            case fileType.Reports:
                this.description = this._appLocalizationService.l('FileReportsDescription');
                this.allReadOnly = this.allReadOnly || !this._appAuthService.hasPermission('Pages.Management.Requests.CanAddReports');
                this.title = this.title || this._appLocalizationService.l('FilesReports');
                break;
        }
    }

    private setColumn() {
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Name'),
                field: 'filesName',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Size'),
                field: 'fileSize',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                filter: true,
                valueFormatter: this.dateFormater,
                sort: 'desc',
                width: 200
            },
            // If allReadOnly is true, we don't want to show the delete button
            ...(this.allReadOnly ? [] : [{
                headerName: '',
                width: 90,
                cellRendererFramework: GridBtnDeleteComponent,
                cellRendererParams: {
                    action: this.deleteFile,
                    templateIconStyle: true
                }
            }]),
            {
                headerName: '',
                width: 90,
                cellClass: 'text-end',
                cellRendererFramework: GridBtnComponent,
                cellRendererParams: {
                    action: (data : FilesOutput) => this.getFile(data),
                    icon: 'fas fa-download'
                }
            }
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    private readonly dateFormater = (params: ValueFormatterParams) => {
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    private getFile(data: FilesOutput) {
        const requestFile: RequestFileInput = {
            files: data,
            requestId: this.requestId,
        };
        this._requestFileService.getFileDto(requestFile).subscribe((fileDto: FileDto) => {
            if (fileDto.fileName !== '') {
                this.downloadService.downloadTempFile(fileDto);
            } else {
                this.showErrorMessage('FileNotFound', 'FileTryAgainLater');
            }
        });
    }

    private readonly deleteFile = (data: FilesOutput) => {
        this.message.confirm(this.l('FileDeleteQuestion', data.filesName), this.l('AreYouSure'), (isConfirmed: boolean) => {
            if (isConfirmed) {
                this._requestFileService.deleteFile(data.publicId, this.requestId).subscribe({
                    next: () => {
                        this.showSuccess('FileDeleteTitle', 'FileDeleted');
                        this.getAllFiles();
                    },
                    error: () => {
                        this.showErrorMessage('FileErrorDelete', 'FileTryAgainLater');
                    }
                });
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        });
    };

    private showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    private showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }
}
