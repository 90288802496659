import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgIf, AsyncPipe } from '@angular/common';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { AgGridModule, AgGridAngular } from '@ag-grid-community/angular';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { DateTime } from 'luxon';
import { AppConsts } from '@shared/AppConsts';
import { UtilsModule } from '@shared/utils/utils.module';
import { SharedModule } from '@app/shared/shared.module';
import { GridPaginationModule } from '@app/shared/grid-pagination/grid-pagination.module';
import { ReferencesModalComponent } from '@app/requests/requests-informations/references/references-modal/references-modal.component';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { DialogService } from '@shared/services/dialog-service';
import { ReferenceService } from '@shared/services/reference.service';
import Reference from '@shared/models/reference/reference';
import ReferenceCreationInput from '@shared/models/reference/referenceCreationInput';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';

@Component({
    standalone: true,
    imports: [AgGridModule, AsyncPipe, GridPaginationModule, NgIf, ReferencesModalComponent, SharedModule, UtilsModule],
    selector: 'references-list',
    templateUrl: './references-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferencesListComponent implements OnInit, OnDestroy {
    @Input() protected readOnly: boolean = true;
    @Input() protected request: RequestOutput;

    @ViewChild(ReferencesModalComponent) private readonly referenceModalComponent: ReferencesModalComponent;

    // Ag-Grid
    @ViewChild('agGrid') private readonly agGrid: AgGridAngular;
    protected columnDefs: any[] = [];
    protected paginationPageSize = 20;

    private readonly destroy$ = new Subject<void>();
    private readonly referencesSubject = new BehaviorSubject<Reference[]>([]);
    protected references$ = this.referencesSubject.asObservable();
    protected requestVerificationForReferences: RequestVerificationOutput[];
    protected showModal: boolean = false;

    constructor(
        private readonly referenceService: ReferenceService,
        private readonly localizationService: AppLocalizationService,
        private readonly toast: ToastrService,
        private readonly _messageService: DialogService,
        private readonly cdr: ChangeDetectorRef
    ) { }

    ngOnInit() {
        this.requestVerificationForReferences = this.request.requestVerifications.filter((rv) => RequiredFieldsForCodes.isReferenceRequired(rv.verification.code));
        this.buildColumnDefs();
        this.fetchReferences();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    protected openModal(existingRef?: Reference | null) {
        this.showModal = true;
        // Force change detection to update the modal component
        this.cdr.detectChanges();
        if (this.referenceModalComponent) {
            this.referenceModalComponent.show(existingRef);
        }
    }

    protected onReferenceCreated(_: ReferenceCreationInput) {
        this.fetchReferences();
    }

    protected onReferenceUpdated(_: Reference) {
        this.fetchReferences();
    }

    protected onModalClosed() {
        this.showModal = false;
        this.cdr.detectChanges();
    }

    protected onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    private fetchReferences() {
        this.referenceService
            .getReferencesByRequestId(this.request.publicId)
            .pipe(takeUntil(this.destroy$))
            .subscribe((refs) => {
                this.referencesSubject.next([...refs]);
            });
    }

    private deleteReference(selectedReference: Reference) {
        const referenceName = `${selectedReference.firstName} ${selectedReference.lastName}`;

        this._messageService
            .error(this.localizationService.l('References.DeleteMessage', [referenceName]), this.localizationService.l('Delete'), {
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: this.localizationService.l('Yes'),
                cancelButtonText: this.localizationService.l('No')
            })
            .then((result) => {
                if (result.isConfirmed) {
                    this.referenceService
                        .deleteReference(selectedReference.id)
                        .pipe(takeUntil(this.destroy$))
                        .subscribe(
                            () => {
                                this.showToast(true, 'Delete', 'SuccessfullyDeleted');
                                this.fetchReferences();
                            },
                            () => {
                                this.showToast(false, 'Delete', 'AnErrorOccurred');
                            }
                        );
                }
            });
    }

    private showToast(isSuccess: boolean, titleKey: string, messageKey: string) {
        const title = this.localizationService.l(titleKey);
        const successMessage = this.localizationService.l(messageKey);
        isSuccess ? this.toast.success(successMessage, title) : this.toast.error(successMessage, title);
    }

    private buildColumnDefs() {
        this.columnDefs = [
            {
                headerName: this.localizationService.l('Name'),
                flex: 1,
                valueGetter: (params: any) => `${params.data.firstName || ''} ${params.data.lastName || ''}`,
                minWidth: 200,
                editable: false
            },
            {
                headerName: this.localizationService.l('Email'),
                field: 'email',
                flex: 1,
                minWidth: 200,
                editable: false
            },
            {
                headerName: this.localizationService.l('Phone'),
                field: 'phoneNumber',
                flex: 1,
                minWidth: 150,
                editable: false
            },
            {
                headerName: this.localizationService.l('Language'),
                field: 'language',
                flex: 1,
                minWidth: 125,
                editable: false
            },
            {
                headerName: this.localizationService.l('Status'),
                field: 'status',
                flex: 1,
                minWidth: 150,
                valueFormatter: (params: ValueFormatterParams) => this.localizationService.ls('PreEmploiReferenceStatus', params.value.toString()),
                editable: false
            },
            {
                headerName: this.localizationService.l('References.Type'),
                field: 'verificationType',
                flex: 1,
                minWidth: 100,
                valueFormatter: (params: ValueFormatterParams) => params.data.requestVerification?.verification?.code,
                editable: false
            },
            {
                headerName: this.localizationService.l('References.LastTimeSentToReference'),
                field: 'lastTimeSentToReference',
                flex: 1,
                minWidth: 200,
                valueFormatter: (params: ValueFormatterParams) => params.value ? DateTime.fromISO(params.value, {zone: 'utc'}).setZone(AppConsts.mtlTimezone).toFormat(AppConsts.dateTimeFormat) : null,
                editable: false
            }
        ];

        if (!this.readOnly) {
            this.columnDefs.push(
                {
                    headerName: '',
                    flex: 1,
                    minWidth: 150,
                    cellRendererFramework: GridBtnUpdateComponent,
                    cellRendererParams: { action: (data) => this.openModal(data) }
                },
                {
                    headerName: '',
                    flex: 1,
                    minWidth: 150,
                    cellRendererFramework: GridBtnDeleteComponent,
                    cellRendererParams: { action: (data) => this.deleteReference(data) }
                }
            );
        }
    }
}
