<div [@routerTransition]>
    <ng-container *ngIf="request && requestVerificationsHasChanges()">
        <app-request-report #requestReportComponent [request]="request"></app-request-report>
    </ng-container>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Requests' | localize" [description]="'UpdateRequest' | localize">
            <div role="actions">
                <button (click)="returnToRequestDetails()" class="btn btn-secondary">
                    <i class="fas fa-times"></i>
                    {{ 'Cancel' | localize }}
                </button>
                <button [disabled]="loading" class="btn btn-primary" (click)="update()"
                    data-test-id="request-update-save-btn">
                    <i class="la la-floppy-o"></i>
                    {{ 'Save' | localize }}
                    <i *ngIf="loading" class="fas fa-spinner fa-spin ml-1"></i>
                    <i *ngIf="generatingReport" class="fas fa-file-alt fa-pulse ml-1"></i>
                </button>
            </div>
        </sub-header>
    </div>

    <div [class]="containerClass">
        <div class="row" class="breadCrumbContainer">
            <div class="col pl-0 pr-0">
                <p-breadcrumb [model]="items"></p-breadcrumb>
            </div>
        </div>
    </div>

    <div class="m-content">
        <form [formGroup]="formGroup" autocomplete="off">
            <div [class]="containerClass">
                <div class="card card-custom mb-10">
                    <div class="card-body">
                        <div class="form-group">
                            <label for="status">
                                {{ 'Status' | localize }}
                                <span class="asterix">*</span>
                            </label>
                            <select id="status" formControlName="status" type="text" name="status" class="form-control"
                                data-test-id="request-update-status-select">
                                <option *ngFor="let status of statusOptions" [value]="status.id">{{ status.name |
                                    localize }}</option>
                            </select>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="organization">
                                    {{ 'Organization' | localize }}
                                    <span class="asterix">*</span>
                                </label>
                                <input id="organization" formControlName="organization" type="text" name="organization"
                                    class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="candidat">
                                    {{ 'Candidat' | localize }}
                                    <span class="asterix">*</span>
                                </label>
                                <input id="candidat" formControlName="candidat" type="text" name="candidat"
                                    class="form-control" maxlength="256" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6"
                                *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                                <label for="userNameCreator">{{ 'CreatedBy' | localize }}</label>
                                <input id="userNameCreator" formControlName="userNameCreator" type="text"
                                    name="userNameCreator" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6"
                                *ngIf="userList?.length > 0 && isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                                <label for="user">{{ 'AssignedTo' | localize }}</label>
                                <input id="user" formControlName="user" type="text" name="user" class="form-control"
                                    maxlength="256" [typeahead]="userList" typeaheadOptionField="userName"
                                    [typeaheadMinLength]="0" [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="5" autocomplete="off"
                                    (typeaheadOnSelect)="onSelectUser($event)"
                                    (typeaheadNoResults)="typeaheadNoResultsUser($event)" />
                                <div class="alert alert-danger" *ngIf="noResultUser">
                                    {{ 'ResultNotFoundElementInList' | localize }}
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                            <div class="form-group col-md-6">
                                <label for="additionalContact">
                                    {{ 'AdditionalContact' | localize }}
                                    <i class="fas fa-info-circle" data-toggle="tooltip"
                                        title="{{ 'TooltipAdditionalContact' | localize }}" id="tooltipInfo"></i>
                                </label>
                                <input id="additionalContact" formControlName="additionalContact" type="text"
                                    name="additionalContact" class="form-control" maxlength="256"
                                    [typeahead]="userListFiltered" typeaheadOptionField="userName"
                                    [typeaheadMinLength]="0" [typeaheadScrollable]="true"
                                    [typeaheadOptionsInScrollableView]="5" autocomplete="off"
                                    (typeaheadOnSelect)="onSelectAdditionalContactUser($event)"
                                    (typeaheadNoResults)="typeaheadNoResultsAdditionalContactUser($event)" />
                                <div class="alert alert-danger" *ngIf="noResultAdditionalContactUser">
                                    {{ 'ResultNotFoundElementInList' | localize }}
                                </div>
                            </div>
                            <div class="form-group col-md-6"
                                *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                                <label for="billingPeriod">{{ 'BillingPeriod' | localize }}</label>
                                <input id="billingPeriod" formControlName="billingPeriod" type="text"
                                    name="billingPeriod" class="form-control" maxlength="256" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6" *ngIf="shouldShowInvoiceStatus">
                                <label for="invoiceStatus">{{ 'StatusInvoice' | localize }}</label>
                                <input id="invoiceStatus" formControlName="invoiceStatus" type="text"
                                    name="invoiceStatus" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="purchaseOrder">{{ 'PurchaseOrder' | localize }}</label>
                                <input id="purchaseOrder" formControlName="purchaseOrder" type="text"
                                    name="purchaseOrder" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="reportLanguage">{{ 'ReportLanguage' | localize }}</label>
                                <select name="reportLanguage" class="form-control" id="reportLanguage"
                                    (change)="changeLanguage($event.target.value)" formControlName="reportLanguage"
                                    data-test-id="request-update-language-select"
                                    [attr.disabled]="isGranted('Pages.Management.Requests.CanUpdateReportLanguage')">
                                    <option value="">{{ 'Select' | localize }}</option>
                                    <option value="english">{{ 'English' | localize }}</option>
                                    <option value="french">{{ 'French' | localize }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-custom mb-10">
                    <div class="verifications">
                        <h5>{{ 'Verifications' | localize }}</h5>
                        <h6 style="font-weight: normal">{{ 'VerificationsDescription' | localize }}</h6>
                        <div>
                            <grid-pagination class="grid-pagination"
                                (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
                            <ag-grid-angular localizeText class="ag-theme-material gardium-small-grid"
                                [paginationNumberFormatter]="paginationNumberFormatter" [rowData]="requestVerifications"
                                [columnDefs]="columnDefsRequestVerifications"
                                [defaultColDef]="defaultColDefRequestVerifications"
                                [paginationPageSize]="paginationPageSize" [pagination]="true"
                                (gridReady)="onRequestVerificationGridReady($event)"></ag-grid-angular>
                        </div>
                    </div>
                </div>

                <div [class]="containerClass">
                    <div class="row" class="mb-5">
                        <div class="col pl-0 pr-0"></div>
                    </div>
                </div>

                <div class="card card-custom mb-10">
                    <div class="candidatDetails">
                        <h4>{{ 'CandidateInformation' | localize }}</h4>
                        <h6 style="font-weight: normal">{{ 'CandidateInformationDescription' | localize }}</h6>
                        <tabset class="tab-container tabbable-line">
                            <tab heading="{{ 'CandidatDetails' | localize }}">
                                <ng-template tabHeading>
                                    <span class="invalidTab"
                                        *ngIf="candidatsDetailsLoaded && isFirstTabInvalid()">*</span>
                                </ng-template>
                                <request-candidats-details #candidatsDetails
                                    [selectedCandidat]="selectedCandidat" [canUpdate]="!isAgentPPO"></request-candidats-details>
                            </tab>
                            <tab heading="{{ 'AddressCurrent' | localize }}">
                                <ng-template tabHeading>
                                    <span class="invalidTab"
                                        *ngIf="candidatsAddressLoaded && isSecondTabInvalid()">*</span>
                                </ng-template>
                                <request-address-history #candidatsAddress [selectedCandidat]="selectedCandidat"
                                    [requestVerifications]="requestVerifications" [readOnly]="isAgentPPO"></request-address-history>
                            </tab>
                            <tab heading="{{ 'AddressHistory' | localize }}" *ngIf="showAddressList">
                                <request-address-history-list *ngIf="selectedCandidat"
                                    [readOnly]="!isAllowedToUpdateRequest"
                                    [selectedCandidat]="selectedCandidat">
                                </request-address-history-list>
                            </tab>
                            <tab heading="{{ 'References.Title' | localize}}" *ngIf="showReferenceList">
                                <references-list
                                    [readOnly]="!isAllowedToUpdateRequest"
                                    [request]="request">
                                </references-list>
                            </tab>
                            <tab heading="{{ 'ProfessionnalAccreditations' | localize }}" *ngIf="showAccreditationList">
                                <professionnal-accreditations-list #accreditationList *ngIf="request"
                                    (modalRefresh)="refreshVerificationList()"
                                    [candidateId]="request.candidat.id"
                                    (modalRefresh)="refreshVerificationList()"
                                    [candidateId]="request.candidat.id"
                                    [candidatePublicId]="request.candidat.publicId"
                                    [readOnly]="!isAllowedToUpdateRequest"
                                    [requestPublicId]="request.publicId">
                                </professionnal-accreditations-list>
                            </tab>
                            <tab heading="{{ 'AcademicRecord' | localize }}" *ngIf="showAcademicRecordList">
                                <academic-record-list #academicRecordList *ngIf="request"
                                    (modalRefresh)="refreshVerificationList()"
                                    [candidateId]="request.candidat.id"
                                    (modalRefresh)="refreshVerificationList()"
                                    [candidateId]="request.candidat.id"
                                    [candidatePublicId]="request.candidat.publicId"
                                    [readOnly]="!isAllowedToUpdateRequest"
                                    [requestPublicId]="request.publicId">
                                </academic-record-list>
                            </tab>
                        </tabset>
                    </div>
                </div>

                <div class="accordion-item files-accordion mb-10">
                    <h2 class="accordion-header" id="filesSectionLabel">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseFilesSection" aria-expanded="false"
                            aria-controls="collapseFilesSection">
                            <strong>{{ 'Files' | localize }}</strong>
                        </button>
                    </h2>
                    <div id="collapseFilesSection" class="accordion-collapse collapse"
                        aria-labelledby="filesSectionLabel">
                        <div class="accordion-body align-left">
                            <div class="card card-custom mb-10" *ngIf="canSeeReports">
                                <div class="requestFileBorder" *ngIf="request">
                                    <list-request-files #filesReports
                                        [fileListType]="fileTypeEnum.Reports"
                                        [requestId]="request.id"></list-request-files>
                                </div>
                            </div>

                            <div [class]="containerClass">
                                <div class="row" class="mb-5">
                                    <div class="col pl-0 pr-0"></div>
                                </div>
                            </div>

                            <div class="card card-custom mb-10">
                                <div class="requestFileBorder" *ngIf="request">
                                    <list-request-files #filesList
                                        [fileListType]="fileTypeEnum.Filled" [requestId]="request.id"
                                        (onFileListUpdate)="onFileListUpdateHandler($event)">
                                        <span class="asterix" *ngIf="isMissingFiles">*</span>
                                    </list-request-files>
                                </div>
                            </div>
                            <div [class]="containerClass">
                                <div class="row" class="mb-5">
                                    <div class="col pl-0 pr-0"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="accordion-item files-accordion mb-10" style="padding-bottom: 1%">
                    <h2 class="accordion-header" id="requestUpdateNotesList">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                            data-bs-target="#collapseRequestUpdateNotesList" aria-expanded="false"
                            aria-controls="collapseRequestUpdateNotesList">
                            <strong>{{ 'Notes' | localize }}</strong>
                        </button>
                    </h2>
                    <div id="collapseRequestUpdateNotesList" class="accordion-collapse collapse"
                        aria-labelledby="requestUpdateNotesList">
                        <div class="accordion-body align-left">
                            <div class="requestFileBorder" *ngIf="request">
                                <notes-list #notes [requestPublicId]="request.publicId"
                                    [requestId]="request.id"></notes-list>
                            </div>
                        </div>
                    </div>
                </div>

                <div [class]="containerClass">
                    <div class="row" class="mb-5">
                        <div class="col pl-0 pr-0"></div>
                    </div>
                </div>

                <div class="col text-end mt-3 mt-md-0">
                    <button (click)="returnToRequestDetails()" class="btn btn-secondary">
                        <i class="fas fa-times"></i>
                        {{ 'Cancel' | localize }}
                    </button>
                    <button [disabled]="loading" class="btn btn-primary" (click)="update()">
                        <i class="la la-floppy-o"></i>
                        {{ 'Save' | localize }}
                        <i *ngIf="loading" class="fas fa-spinner fa-spin ml-1"></i>
                    </button>
                </div>
                <btn-scroll-top></btn-scroll-top>
            </div>
        </form>
    </div>
</div>
