import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as env } from 'environments/environment';
import { Observable, of } from 'rxjs';
import { VerificationForm } from '@shared/models/custom-form/verificationForm';
import { catchError, map } from '@node_modules/rxjs/operators';

@Injectable()
export class CustomFormService {
    private static readonly getUrl = (url: string): string => (env.api + '/api/verificationForm' + url).replace(/[?&]$/, '');

    constructor(private readonly http: HttpClient) {}

    // This method is not used in the application at the moment but is kept for a future implementation.
    public getVerificationForm(formId: string): Observable<VerificationForm> {
        return of(null);
    }

    public getVerificationFormFromVerificationId(verificationId: number): Observable<VerificationForm> {
        return this.http.get<{ result: VerificationForm }>(CustomFormService.getUrl(`/by-verification-id?verificationId=${verificationId}`)).pipe(
            map(response => response.result),
            catchError(err => {
                throw err;
            })
        );
    }
}
