import { Component } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import {ICellRendererParams} from "@ag-grid-community/core";

interface IGridBtnDeleteComponentParams extends ICellRendererParams {
    action: (data: any) => void;
    templateIconStyle?: boolean;
    preventPropagation?: boolean;
}

@Component({
    selector: 'grid-btn-delete',
    templateUrl: './grid-btn-delete.component.html',
    styleUrls: ['./grid-btn-delete.component.scss']
})
export class GridBtnDeleteComponent extends AppComponentBase implements ICellRendererAngularComp {
    private data: any;
    private action: (data: any) => void;
    private preventPropagation: boolean;
    protected templateIconStyle: boolean;

    refresh(_: any): boolean {
        return false;
    }

    agInit(params: IGridBtnDeleteComponentParams): void {
        this.action = params.action;
        this.data = params.data;
        this.templateIconStyle = params.templateIconStyle || false;
        this.preventPropagation = params.preventPropagation || false;
    }

    onActivationHandler(event: Event): void {
        if (this.preventPropagation) {
            event.stopPropagation();
        }
        this.action(this.data);
    }
}
