import { Component, Input, OnInit, AfterContentInit, ContentChild, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Validators, ReactiveFormsModule, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { minimumSelected } from '@shared/utils/validation/minimumSelected-validator';
import { UtilsModule } from '@shared/utils/utils.module';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';

/**
 * This component is a wrapper used to create a standard question in a form with its label and validation messages. You must provide the inner input
 * using either custom code or you can use the pre-made models like {@link TextAreaInputComponent}, {@link MultipleSelectionListComponent},
 * {@link SingleSelectionListComponent} and {@link BooleanInputComponent} or {@link StandardInputComponent}. It will create a form control in the parent
 * form group and add the necessary validators. If you provide custom input, don't forget to add the *#inputControl* tag on it so the form control can be added.
 * For an implementation example of the form group with custom input, see {@link PhoneInputComponent} and for a pre-made mode, see {@link EmailInputComponent} or {@link LanguageDropdownInputComponent}.
 * @params *__name__* - The name of the question to be used for the control and DOM. For the text to show, use the label input.
 * @params *__label__* - The text to show as the question's label.
 * @params *__parentFormControl__* - The form control group in which we will add this question's control.
 * @params *__startingValue__* - *(Optional)* The initial value of the question. If not provided, the value will be null.
 * @params *__options__* - *(Optional)* Extra options for the question's html element like masks, text transform and other optional directives.
 */
@Component({
    selector: 'form-group[name][label][parentFormControl]',
    standalone: true,
    imports: [UtilsModule, CommonModule, ReactiveFormsModule],
    templateUrl: './form-group.component.html'
})
export class FormGroupComponent implements OnInit, AfterContentInit {
    @Input() protected readonly name: string;
    @Input() protected readonly label: string;
    @Input() protected readonly parentFormControl: FormGroup;
    @Input() protected readonly startingValue: any;
    @Input() protected readonly options: FormQuestionOptions;
    @ContentChild('inputControl, [formControl]') child: any;

    protected questionControl: FormControl;

    constructor(private cdr: ChangeDetectorRef) {
    }

    ngOnInit() {
        this.setupFormControl();
    }

    /**
     *  Because the input is in a <ng-content> tag, we need to wait for the child component to be initialized before
     *  we can set the questionControl property and assign it to the child component's input.
     */
    ngAfterContentInit() {
        if (this.child) {
            if (this.child.nativeElement) {
                this.child.formControl = this.questionControl;
            } else if (!this.child.questionControl) {
                this.child.questionControl = this.questionControl;
            }
        }

        this.cdr.markForCheck();
    }

    // Create the control in the parent form if it doesn't exist and add the validators required by the options
    private setupFormControl() {
        const validators = this.getValidators();
        const existingControl = this.parentFormControl.get(this.name);

        if (existingControl) {
            this.questionControl = existingControl as FormControl;
            this.questionControl.addValidators(validators);
        } else {
            const initialFormValue = this.startingValue != null ? this.startingValue : null
            this.questionControl = new FormControl(initialFormValue, validators);
            this.parentFormControl.addControl(this.name, this.questionControl);
        }
    }

    // Get the validators for the question from the options
    private getValidators(): ValidatorFn[] {
        const validators: ValidatorFn[] = [];

        if (!this.options) {
            return validators;
        }

        if (this.options.maxLength) {
            validators.push(Validators.maxLength(this.options.maxLength));
        }

        if (this.options.isMandatory) {
            if (this.options.isMultiSelection && !this.options.multiSelectionMin) {
                validators.push(minimumSelected(1));
            } else {
                validators.push(Validators.required);
            }
        }

        /**
         * The maximum number of selections is handled in the {@link MultipleSelectionListComponent.maxSelections} component directly
         */
        if (this.options.isMultiSelection && this.options.multiSelectionMin) {
            validators.push(minimumSelected(this.options.multiSelectionMin));
        }

        if (this.options.extraValidators) {
            validators.push(...this.options.extraValidators);
        }

        return validators;
    }
}
