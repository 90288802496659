import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { Component } from '@angular/core';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';

@Component({
    selector: 'app-request-status-pill',
    templateUrl: './request-status-pill.component.html',
    styleUrls: ['./request-status-pill.component.scss']
})
export class RequestStatusPillComponent implements ICellRendererAngularComp {
    name: string;
    color: string;
    textColor: string = 'var(--color-black)';

    refresh(params: any): boolean {
        return false;
    }

    agInit(params): void {
        const legend = RequestStatusLegend.getLegendFromId(params.value);
        this.name = legend.name;
        this.color = legend?.color;
        this.textColor = legend?.textColor;
    }
}
