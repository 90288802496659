import { Component, Input } from '@angular/core';
import { FormQuestionConfiguration, FormQuestionType } from '@shared/models/custom-form/formQuestionConfiguration';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormQuestionTranslation } from '@shared/models/custom-form/formQuestionTranslation';
import { CommonModule } from '@angular/common';
import { BooleanInputComponent } from '@app/shared/form/base-inputs/boolean-input/boolean-input.component';
import { SingleSelectionListComponent } from '@app/shared/form/base-inputs/single-selection-list/single-selection-list.component';
import { MultipleSelectionListComponent } from '@app/shared/form/base-inputs/multiple-selection-list/multiple-selection-list.component';
import { TextAreaInputComponent } from '@app/shared/form/base-inputs/textarea-input/textarea-input.component';
import { StandardInputComponent } from '@app/shared/form/base-inputs/standard-input/standard-input.component';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';
import { DropdownChoice } from '@shared/models/dropdownChoice';

/**
 * Component that displays a question in a dynamic form.
 * @params *__question__* - The question's configuration for this component.
 * @params *__formLanguage__* - The language of the form.
 * @params *__parentFormControl__* - The form control group in which we will add this question's control.
 * @params *__ignoreIsMandatory__* - *(Optional)* This allows to bypass the option isMandatory of the question. It prevents forcing the agent to fill the form before sending it to someone else.
 */
@Component({
    standalone: true,
    selector: 'custom-form-question[question][parentFormControl]',
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BooleanInputComponent,
        SingleSelectionListComponent,
        MultipleSelectionListComponent,
        TextAreaInputComponent,
        StandardInputComponent,
        FormGroupComponent
    ],
    templateUrl: './custom-form-question.component.html'
})
export class CustomFormQuestionComponent {
    @Input() protected readonly question: FormQuestionConfiguration;
    @Input() protected readonly formLanguage: string;
    @Input() protected readonly parentFormControl: FormGroup;
    @Input() protected readonly ignoreIsMandatory: boolean = false;

    protected readonly FormQuestionType = FormQuestionType;

    constructor() {
    }

    protected get translation(): FormQuestionTranslation {
        if (this.question.translations?.length > 1) {
            return this.question.translations.find((t) => t.language === this.formLanguage);
        } else if (this.question.translations?.length === 1) {
            return this.question.translations[0];
        } else {
            return null;
        }
    }

    protected get htmlType(): string {
        return this.question.type === FormQuestionType.standard ? 'text' : FormQuestionType[this.question.type] ?? 'text';
    }

    protected get options(): FormQuestionOptions {
        return {
            maxLength: this.question.maxLength,
            isMandatory: this.ignoreIsMandatory ? false : this.question.isMandatory,
            isMultiSelection: this.question.isMultiSelection,
            subText: this.translation.subText
        };
    }

    protected get name(): string {
        return String(this.question.id);
    }

    protected get choices(): DropdownChoice[] {
        return this.translation?.choices?.map((c) => ({
            value: c.index,
            display: c.text
        })) ?? [];
    }

    protected get answer(): string | number[] | boolean {
        switch (this.question.type) {
            case FormQuestionType.list:
                return this.question.answerChoicesIndexes ?? [];
            case FormQuestionType.boolean:
                if (this.question.answer == null) {
                    return null;
                }
                return this.question.answer === 'true' ? true :
                    this.question.answer === 'false' ? false :
                       null;
            default:
                return this.question.answer ?? '';
        }
    }

    protected get questionLabel(): string {
        return `${this.question.index}) ${this.translation?.text}`;
    }
}
