import { Component, Input, OnInit, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';
import { NgForOf, NgIf } from '@angular/common';
import { DropdownChoice } from '@shared/models/dropdownChoice';

/**
 * This component is used to display a list of choices in a single selection list.
 * @params *__name__* - The name used for the control's name. Will also be used for the html id.
 * @params *__choices__* - The choices to be displayed see {@link DropdownChoice }, can also be strings.
 * @params *__questionControl__* - The form control connected to the question. If used inside a {@link FormGroupComponent} the control will be added automatically.
 * @params *__disabled__* - *(Optional)* If the list should be disabled.
 */
@Component({
    standalone: true,
    selector: 'single-selection-list[name][choices]',
    imports: [UtilsModule, ReactiveFormsModule, NgForOf, NgIf],
    templateUrl: './single-selection-list.component.html'
})
export class SingleSelectionListComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() protected readonly name: string;
    @Input() protected readonly choices: DropdownChoice[] | string[] = [];
    @Input() protected readonly questionControl: FormControl;
    @Input() protected readonly disabled: boolean = false;

    protected values: DropdownChoice[] = [];

    ngOnInit(): void {
        this.values =
            typeof this.choices[0] === 'string'
                ? this.choices.map(
                    (choice) =>
                    ({
                        value: choice,
                        display: choice
                    } as DropdownChoice)
                )
                : (this.choices as DropdownChoice[]);
    }

    ngAfterViewInit() {
        this.toggleDisabled();
    }

    ngOnChanges(event: SimpleChanges): void {
        if (event['disabled']) {
            this.toggleDisabled();
        }
    }

    private toggleDisabled(): void {
        if (this.disabled) {
            this.questionControl?.disable();
        } else {
            this.questionControl?.enable();
        }
    }
}
