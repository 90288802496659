import { Component, OnInit, Injector, ViewChild, HostListener } from '@angular/core';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '@shared/services/requests.service';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ToastrService } from 'ngx-toastr';
import { RequestInput } from '@shared/models/request/requestInput';
import { CandidatsDetailsComponent } from '../requests-informations/candidats-details/candidats-details.component';
import { AddressHistoryComponent } from '../requests-informations/address-history/address-history-current/address-history-current.component';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { simpleUserOutput } from '@shared/models/user/SimpleUserOutput';
import { OrganizationUserService } from '@shared/services/organizationUser.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { RequestAllInformation } from '@shared/models/request/requestInformation/requestInformation';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';
import { AddressHistoryService } from '@shared/services/addressHistory.service';
import { ColDef, GridApi } from '@ag-grid-enterprise/all-modules';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { GridBtnComponent } from '@app/shared/grid/grid-btn/grid-btn.component';
import { RequestVerificationUpdateComponent } from '../request-verification-update/request-verification-update.component';
import { RequestVerificationStatus } from '@shared/models/requestVerification/requestVerificationStatus';
import { To_RequestVerificationInput } from '@shared/models/requestVerification/requestVerificationInput';
import { MenuItem } from 'primeng/api';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { resultsCodes } from '@shared/models/requestVerification/requestVerificationResults';
import { RequestReportComponent } from '../request-reports/request-report.component';
import { fileType } from '@shared/models/files/fileType';
import { RequestReportsService } from '@shared/services/request-reports.service';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { RequestUserListService } from '@shared/services/request-user-list.service';
import { BillingPeriodTypeDTO } from '@shared/service-proxies/service-proxies';
import { BinaryResponse } from '@shared/models/boolean-string.enum';
import { IRequestStatusLegend, RequestStatusLegend } from '@shared/models/request/requestStatusLegend';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { cloneDeep } from 'lodash-es';
import {FilesOutput} from "@shared/models/files/filesOutput";

@Component({
    selector: 'app-request-update',
    templateUrl: './request-update.component.html',
    styleUrls: ['./request-update.component.scss'],
    animations: [appModuleAnimation()]
})
export class RequestUpdateComponent extends AppComponentBase implements OnInit {
    @ViewChild('requestReportComponent') requestReportComponent: RequestReportComponent;

    public candidatsAddressLoaded = false;
    public candidatsDetailsLoaded = false;
    fixLanguage = false;
    request: RequestOutput;
    requestId: number;
    publicId: string;
    selectedCandidat: CandidatOutput;
    informationSource: InformationSourceDTO = InformationSourceDTO.Candidate;
    statusHasChanged = false;
    showAddressList = false;
    showReferenceList = false;
    showAccreditationList = false;
    showAcademicRecordList = false;
    canSeeReports = false;
    canCancelRequest = true;
    shouldShowInvoiceStatus = false;
    userList: simpleUserOutput[] = [];
    userListFiltered: simpleUserOutput[] = [];
    userListOriginal: simpleUserOutput[] = [];
    selectedUser: simpleUserOutput;
    selectedAdditionalContactUser: simpleUserOutput;
    noResultUser = false;
    noResultAdditionalContactUser = false;
    containsDJI = false;
    containsADD = false;
    isAgentPPO = false;
    formGroup = new UntypedFormGroup({
        organization: new UntypedFormControl({ value: '', disabled: true }),
        candidat: new UntypedFormControl({ value: '', disabled: true }),
        status: new UntypedFormControl({ value: '' }),
        sendingMethod: new UntypedFormControl({ value: '' }),
        user: new UntypedFormControl(''),
        additionalContact: new UntypedFormControl(''),
        userNameCreator: new UntypedFormControl({ value: '', disabled: true }),
        termsAndAccept: new UntypedFormControl(''),
        reportLanguage: new UntypedFormControl({ value: '', disabled: true }),
        invoiceStatus: new UntypedFormControl({ value: '', disabled: true }),
        purchaseOrder: new UntypedFormControl({
            value: '',
            disabled: !this.isGranted('Pages.Management.Requests.CanSeePrivateInformation')
        }),
        billingPeriod: new UntypedFormControl({ value: '', disabled: true })
    });

    // Verifications
    gridRequestVerificationApi: GridApi;
    columnDefsRequestVerifications: ColDef[];
    defaultColDefRequestVerifications: ColDef;
    requestVerifications: RequestVerificationOutput[] = [];
    initialRequestVerifications: RequestVerificationOutput[] = [];
    // Modal
    currentLanguage: any;
    items: MenuItem[];
    isAllowedToUpdateRequest = false;
    fileTypeEnum = fileType;
    paginationNumberFormatter;
    paginationPageSize = 20;
    loading: boolean;
    generatingReport: boolean;
    isMissingFiles: boolean;
    candidateFullName: string;
    statusOptions: IRequestStatusLegend[];
    private modalRef: BsModalRef;
    private candidatsAddress: AddressHistoryComponent;
    private candidatsDetails: CandidatsDetailsComponent;

    constructor(
        injector: Injector,
        private readonly _route: ActivatedRoute,
        private readonly _service: RequestService,
        private readonly _addressHistoryService: AddressHistoryService,
        private readonly organizationUserService: OrganizationUserService,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly toastr: ToastrService,
        private readonly modalService: BsModalService,
        private readonly _router: Router,
        private readonly requestReportService: RequestReportsService,
        private readonly agentListService: RequestUserListService,
        private readonly _appAuthService: AppAuthService
    ) {
        super(injector);

        this.defaultColDefRequestVerifications = {
            resizable: true,
            sortable: true,
            filter: true
        };

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };

        this.statusOptions = this.getRequestStatusOptions();
    }

    @ViewChild('candidatsDetails', { static: true })
    set candidatsDetailsContent(content) {
        this.candidatsDetails = content;

        if (this.candidatsDetails) {
            this.candidatsDetailsLoaded = true;
        }
    }

    @ViewChild('candidatsAddress', { static: true })
    set candidatsAddressContent(content) {
        this.candidatsAddress = content;

        if (this.candidatsAddress) {
            this.candidatsAddressLoaded = true;
        }
    }
    // Events

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridRequestVerificationApi) {
            this.gridRequestVerificationApi.sizeColumnsToFit();
        }
    }

    async ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');

            this.items = [
                {
                    label: this._appLocalizationService.l('Requests'),
                    routerLink: ['/requests']
                },
                {
                    label: this._appLocalizationService.l('RequestDetails'),
                    routerLink: [`/requests-details/${this.publicId}`]
                },
                { label: this._appLocalizationService.l('UpdateRequest') }
            ];

            this._service.getRequest(this.publicId).subscribe({
                next: (response) => {
                    this.request = response;
                    this.candidateFullName = this.request.candidat.firstName + ' ' + this.request.candidat.lastName;
                    this.formGroup.controls['user'].setValue(this.request.assignedUserName);
                    this.formGroup.controls['userNameCreator'].setValue(this.request.userNameCreator);
                    this.formGroup.controls['organization'].setValue(this.request.organization.organizationName);
                    this.formGroup.controls['status'].setValue(this.request.status);
                    this.formGroup.controls['candidat'].setValue(this.candidateFullName);
                    this.formGroup.controls['additionalContact'].setValue(this.request.additionalContactName);
                    this.formGroup.controls['sendingMethod'].setValue(this.request.sendingMethod);

                    if (this.isGranted('Pages.Management.Requests.CanUpdateReportLanguage') && this.request.status !== RequestStatus.Completed) {
                        this.formGroup.controls['reportLanguage'].enable();
                    }
                    this.formGroup.controls['reportLanguage'].setValue(this.request.reportLanguage);
                    this.formGroup.controls['purchaseOrder'].setValue(this.request.purchaseOrder);
                    this.formGroup.controls['billingPeriod'].setValue(this.l(BillingPeriodTypeDTO[this.request.organization.billingInfo.billingPeriod]));

                    //disable le changement de langues si un resultat détaillé a été surchargé
                    this.request.requestVerifications.forEach((element) => {
                        if (element.detailedResult != null && element.detailedResult.length > 1) {
                            this.fixLanguage = true;
                            this.formGroup.controls['reportLanguage'].disable();
                        } else {
                            this.fixLanguage = false;
                        }
                    });

                    this.initializeInvoiceStatusControl();

                    if (this.isAgentPPO) {
                        this.requestVerifications = this.request.requestVerifications.filter(el => el.verification.code === 'CIPC' || el.verification.code === 'CIPC+');
                    } else {
                        this.requestVerifications = this.request.requestVerifications;
                    }

                    this.initialRequestVerifications = cloneDeep(this.requestVerifications);

                    this.canCancelRequest =
                        this.requestVerifications.find((rv) => rv.status === RequestVerificationStatus.Completed) === undefined ||
                        this.requestVerifications.find((rv) => rv.status === RequestVerificationStatus.Completed) === null;

                    this.setColumns();
                    this.tabsToShow();
                    this.selectedCandidat = this.request.candidat;
                    this.candidatsDetails.formGroupCandidate.patchValue(this.request.candidat);
                    this.isAllowedToUpdateRequest = this.getUserUpdatePermission();
                    this.informationSource = this.request.myInformationSource;
                    this.canSeeSectionReports();

                    this._addressHistoryService
                        .getAddressHistoryByCandidat(this.selectedCandidat.publicId)
                        .toPromise()
                        .then((response) => {
                            this.initCurrentAddressHistory(response.result);
                        });

                    this.agentListService.getAgentList(this.request.organization.id).subscribe((value) => {
                        this.userList = this.organizationUserService.sortUserList(value.result);
                    });

                    this.organizationUserService.getUserList(this.request.organization.id).subscribe((value) => {
                        this.userListOriginal = this.organizationUserService.sortUserList(value.result);
                        this.filterUserList();
                    });

                    this.statusOptions = this.getRequestStatusOptions();
                },
                error: (err) => {
                    this.notify.error('Server error: ' + err.error.result);
                }
            });

            this.currentLanguage = this._appLocalizationService.currentLanguage.displayName;
        }
        this.setIsAgentPPO();
        if (this.isAgentPPO) {
            this.formGroup.controls['status'].disable();
        }
    }

    setIsAgentPPO() {
        this.isAgentPPO = this._appAuthService.hasRole('AgentPPO');
    }

    getRequestStatusOptions(): IRequestStatusLegend[] {
        const legends = [
            RequestStatusLegend.New,
            RequestStatusLegend.InProgressClient,
            RequestStatusLegend.InProgressGardium,
            RequestStatusLegend.Completed,
            RequestStatusLegend.MissingInformations,
            RequestStatusLegend.CantBeCompleted,
            RequestStatusLegend.InProgressPPO
        ];
        if (this.canCancelRequest) {
            legends.push(RequestStatusLegend.Canceled);
        }
        return legends;
    }

    onFileListUpdateHandler(files: FilesOutput[]) {
        //We want to filter verification files that are mandatory
        const requestHasMandatoryFiles = this.requestVerifications
            .flatMap(x => x.verification.verificationFiles)
            .some(vf => vf.files != null && vf.isMandatory);

        this.isMissingFiles = requestHasMandatoryFiles && files.length === 0;
    }

    getUserUpdatePermission(): boolean {
        return this.request.status === RequestStatus.Completed ? this.isGranted('Pages.Management.Requests.UpdateWhenDone') : this.isGranted('Pages.Management.Requests.Update');
    }

    initializeInvoiceStatusControl() {
        this.shouldShowInvoiceStatus = this.request.invoiceStatus != null;
        if (this.shouldShowInvoiceStatus) {
            this.formGroup.controls['invoiceStatus'].setValue(this.l(InvoiceStatusEnum[this.request.invoiceStatus]));
        }
    }

    async loadRequestVerifications() {
        await this._service
            .getRequestByPublicId(this.publicId)
            .toPromise()
            .then((response) => {
                this.request = response.result;
            });

        if (this.request) {
            if (this.isAgentPPO) {
                this.requestVerifications = this.request.requestVerifications.filter(el => el.verification.code === 'CIPC' || el.verification.code === 'CIPC+');
            } else {
                this.requestVerifications = this.request.requestVerifications;
            }
            this.initialRequestVerifications = cloneDeep(this.requestVerifications);
            this.setColumns();
        }
    }

    setColumns() {
        this.initColumns();
        const canSeePrivateInfo = this.isGranted('Pages.Management.Requests.CanSeePrivateInformation');
        this.containsADD = this.requestVerifications.some((rv) => rv.verification.code === 'ADD');
        this.containsDJI = this.requestVerifications.some((rv) => rv.verification.code === 'DJI');

        if (this.containsADD) {
            if (canSeePrivateInfo) {
                this.addPriceColumn();
            }
            this.addADDColumn();
        }

        if (this.containsDJI) {
            if (canSeePrivateInfo && !this.containsADD) {
                this.addPriceColumn();
            }
            this.addDJIColumn();
        }

        this.addEditColumn();
    }

    initColumns() {
        this.columnDefsRequestVerifications = [
            {
                headerName: this.l('Code'),
                flex: 1,
                valueGetter: (params) => params.data.verification.code,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Type'),
                flex: 1,
                valueGetter: (params) => params.data.verification.type,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Status'),
                field: 'status',
                flex: 1,
                valueFormatter: (params) => this.l(RequestVerificationStatus[params.value]),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Quantity'),
                field: 'quantity',
                flex: 1,
                valueFormatter: (params) => params.data.quantity,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('VerificationExpressService'),
                field: 'verificationExpressService',
                valueFormatter: (params) => {
                    const isExpress = params.data.isExpress as boolean;
                    const canBeExpressValue = isExpress ? 'Yes' : 'No';
                    const canBeExpress = params.data.verification.canBeExpress;
                    const translation = canBeExpress ? BinaryResponse[canBeExpressValue] : 'NonEligible';

                    return this.l(translation);
                },
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Result'),
                field: 'result',
                flex: 1,
                valueFormatter: (params) => this.l(resultsCodes[params.data.result]),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            }
        ];
    }

    addEditColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: '',
            flex: 1,
            cellClass: 'text-end',
            cellRendererFramework: GridBtnComponent,
            cellRendererParams: {
                action: (data) => this.editRequestVerification(data),
                icon: 'fas fa-edit'
            }
        });
    }

    addDJIColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: this.l('Country'),
            flex: 1,
            field: 'country.countryName',
            valueFormatter: (params) => params.data.verification.countryId,
            onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
        });
    }

    addADDColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: this.l('Service'),
            flex: 1,
            field: 'verificationCustomField',
            valueFormatter: (params) => params.data.verificationCustomField,
            onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
        });
    }

    addPriceColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: this.l('CustomPrice'),
            flex: 1,
            field: 'verificationCustomPrice',
            valueGetter: this.totalGetter,
            onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
        });
    }

    totalGetter(params) {
        if (params.data.verificationCustomPrice != null) {
            const customPrice = parseFloat(params.data.verificationCustomPrice);
            return customPrice.toFixed(2) + ' $';
        }
    }

    formatPrice(verificationCustomPrice: any): string {
        if (verificationCustomPrice.data.verificationCustomPrice === null) {
            return '';
        }
        return verificationCustomPrice.data.verificationCustomPrice + ' $';
    }

    initNoDJIColumns() {
        this.columnDefsRequestVerifications = [
            {
                headerName: this.l('Code'),
                flex: 1,
                valueGetter: (params) => params.data.verification.code,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Type'),
                flex: 1,
                valueGetter: (params) => params.data.verification.type,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Status'),
                field: 'status',
                flex: 1,
                valueFormatter: (params) => this.l(RequestVerificationStatus[params.value]),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Quantity'),
                field: 'quantity',
                flex: 1,
                valueFormatter: (params) => params.data.quantity,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('VerificationExpressService'),
                field: 'verificationExpressService',
                valueFormatter: (params) => this.l(BinaryResponse[params.data.isExpress as boolean ? 'Yes' : 'No']),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Result'),
                field: 'result',
                flex: 1,
                valueFormatter: (params) => this.l(resultsCodes[params.data.result]),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: '',
                flex: 1,
                cellClass: 'text-end',
                cellRendererFramework: GridBtnComponent,
                cellRendererParams: {
                    action: (data) => this.editRequestVerification(data),
                    icon: 'fas fa-edit'
                }
            }
        ];
    }

    onPageSizeChanged(e) {
        this.gridRequestVerificationApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    canSeeSectionReports() {
        this.canSeeReports =
            this.isGranted('Pages.Management.Requests.CanUpdateReportLanguage') &&
            (this.isGranted('Pages.Management.Requests.CanAddReports') ||
                (!this.isGranted('Pages.Management.Requests.CanAddReports') &&
                    (this.request.status === RequestStatus.Completed ||
                        this.request.status === RequestStatus.MissingInformations ||
                        this.request.status === RequestStatus.InProgressGardium ||
                        this.request.status === RequestStatus.CantBeCompleted ||
                        this.atLeastOneVerificationIsCompleted())));
    }

    initCurrentAddressHistory(addressHistories: AddressHistoryOutput[]) {
        addressHistories.forEach((element) => {
            if (element.type === AddressHistoryType.current) {
                this.candidatsAddress.initFormWithAddressHistory(element);
            }
        });
    }

    tabsToShow() {
        for (const verificationCode of this.requestVerifications.map((rv) => rv.verification.code)) {
            if (RequiredFieldsForCodes.isDriverLicenseRequired(verificationCode)) {
                this.candidatsDetails.formGroupCandidate.controls['driverLicenceNumber'].setValidators(Validators.required);
            }

            if (RequiredFieldsForCodes.isMoreThanOneAddressFormerRequired(verificationCode)) {
                this.showAddressList = true;
            }

            if (RequiredFieldsForCodes.isReferenceRequired(verificationCode)) {
                this.showReferenceList = true;
            }

            if (RequiredFieldsForCodes.isProfessionnalAccreditationsRequired(verificationCode)) {
                this.showAccreditationList = true;
            }

            if (RequiredFieldsForCodes.isAcademicRecordsRequired(verificationCode)) {
                this.showAcademicRecordList = true;
            }

            if (RequiredFieldsForCodes.isEmailRequired(verificationCode)) {
                this.candidatsDetails.formGroupCandidate.controls['email'].setValidators([Validators.required, emailAddressValidator]);
            } else {
                this.candidatsDetails.formGroupCandidate.controls['email'].setValidators([emailAddressValidator]);
            }

            this.candidatsDetails.formGroupCandidate.controls['driverLicenceNumber'].updateValueAndValidity();
            this.candidatsDetails.formGroupCandidate.controls['socialInsuranceNumber'].updateValueAndValidity();
        }
    }

    isFormInvalid(): boolean {
        return this.formGroup.invalid || this.candidatsAddress.formGroupAddress.invalid || this.candidatsDetails.formGroupCandidate.invalid;
    }

    isFirstTabInvalid(): boolean {
        if (this.candidatsDetails !== undefined) {
            return this.candidatsDetails.isFormInvalid();
        }
    }

    markAllControlsAsTouch() {
        this.formGroup.markAsTouched();
    }

    onSelectUser(event: TypeaheadMatch): void {
        this.selectedUser = event.item as simpleUserOutput;
        this.filterUserList();
    }

    onSelectAdditionalContactUser(event: TypeaheadMatch): void {
        this.selectedAdditionalContactUser = event.item as simpleUserOutput;
    }

    typeaheadNoResultsUser(event: boolean): void {
        this.noResultUser = event;
    }

    typeaheadNoResultsAdditionalContactUser(event: boolean): void {
        this.noResultAdditionalContactUser = event;
    }

    filterUserList() {
        this.noResultAdditionalContactUser = false;
        this.userListFiltered = this.userListOriginal.filter((user) => user.userId !== this.request.createdBy && user.userId !== this.selectedUser?.userId);

        if (this.userListFiltered?.length === 0) {
            this.noResultAdditionalContactUser = true;
        }

        if (
            this.userListFiltered &&
            this.userListFiltered.length !== 0 &&
            this.selectedAdditionalContactUser != null &&
            !this.userListFiltered.includes(this.selectedAdditionalContactUser)
        ) {
            this.formGroup.controls['additionalContact'].setValue(null);
            this.selectedAdditionalContactUser = null;
        }
    }

    showForNotValidMessage() {
        const title = this.l('VerifyAllControlsTitle');
        const message = this.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }

    showSuccess(title: string, successMessage: string) {
        title = this.l(title);
        successMessage = this.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this.l(title);
        errorMessage = this.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    returnToRequestDetails() {
        this._router.navigate(['/requests-details', this.request.publicId]);
    }

    isSecondTabInvalid(): boolean {
        return this.candidatsAddress.isFormInvalid();
    }

    showFilesMissingMessage() {
        const title = this.l('FilesFilledMissing');
        const message = this.l('FilesFilledMissingMessage');
        this.toastr.error(message, title);
    }

    async update() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }

        this.loading = true;

        const addressHistory = this.candidatsAddress.getUpdatedCurrentAddress();
        const candidat = this.candidatsDetails.returnCandidate();
        const request = this.returnRequest();

        // tslint:disable-next-line: triple-equals
        if (this.request.status !== request.status) {
            this.statusHasChanged = true;

            if (this.isMissingFiles && request.status === RequestStatus.InProgressGardium) {
                this.showFilesMissingMessage();
                this.loading = false;
                return;
            }
        }

        const requestInformation: RequestAllInformation = {
            requestInput: request,
            candidateInput: candidat,
            addressHistoryInput: addressHistory,
            statusChanged: this.statusHasChanged
        };

        if (this.candidatsAddress.isFormEmpty()) {
            requestInformation.addressHistoryInput = null;
        }

        if (this.atLeastOneVerificationIsCompleted() && this.requestVerificationsHasChanges()) {
            this.generatingReport = true;
            await this.requestReportService.updateReport(request, fileType.Reports).toPromise();
            this.generatingReport = false;
        }

        await this._service
            .updateAllRequest(requestInformation)
            .toPromise()
            .then((result) => {
                if (result.success) {
                    this.showSuccess('RequestUpdateTitle', 'RequestUpdated');
                    this.returnToRequestDetails();
                } else {
                    this.showErrorMessage('RequestUpdateIssue', 'RequestUpdateTryAgainLater');
                }

                this.loading = false;
            });
    }

    returnRequest() {
        let selectedUserId = null;
        if (this.selectedUser !== null && this.selectedUser !== undefined) {
            selectedUserId = this.selectedUser.userId;
        } else {
            selectedUserId = this.formGroup.controls['user'].value === '' ? null : this.request.assignedId;
        }

        let selectedAdditionalContactId = null;
        if (this.selectedAdditionalContactUser != null && this.selectedAdditionalContactUser !== undefined) {
            selectedAdditionalContactId = this.selectedAdditionalContactUser.userId;
        } else {
            selectedAdditionalContactId = this.formGroup.controls['additionalContact'].value === '' ? null : this.request.additionalContactId;
        }

        const request: RequestInput = {
            id: this.request.id,
            publicId: this.publicId,
            candidat: this.selectedCandidat,
            assignedId: selectedUserId,
            additionalContactId: selectedAdditionalContactId,
            organization: this.request.organization,
            requestVerifications: this.request.requestVerifications.map((x) => To_RequestVerificationInput(x, this.request.id)),
            status: this.formGroup.controls['status'].value,
            creationTime: this.request.creationTime,
            myInformationSource: InformationSourceDTO.Candidate,
            reportLanguage: this.formGroup.controls['reportLanguage'].value,
            createdBy: this.request.createdBy,
            generatedReportPublicId: this.request.generatedReportPublicId,
            purchaseOrder: this.formGroup.controls['purchaseOrder'].value,
            sendingMethod: this.formGroup.controls['sendingMethod'].value
        };

        return request;
    }

    changeLanguage(event: string) {
        this.request.reportLanguage = event;
    }

    // Request Verification
    onRequestVerificationGridReady(params) {
        this.gridRequestVerificationApi = params.api;
        this.gridRequestVerificationApi.sizeColumnsToFit();
    }

    onRequestVerificationRowClicked(params) {
        const initialState = {
            requestVerification: params.data,
            isReadOnly: true,
            invoiceStatus: this.request.invoiceStatus,
            request: this.request
        };
        this.modalRef = this.modalService.show(RequestVerificationUpdateComponent, { initialState, class: 'modal-dialog-centered' });
    }

    editRequestVerification(requestVerification: RequestVerificationOutput) {
        const initialState = {
            requestVerification: requestVerification,
            isReadOnly: false,
            invoiceStatus: this.request.invoiceStatus,
            request: this.request,
            isAgentPpo: this.isAgentPPO
        };

        this.modalRef = this.modalService.show(RequestVerificationUpdateComponent, { initialState, class: 'modal-dialog-centered', backdrop: 'static' });
        (this.modalRef.content as RequestVerificationUpdateComponent).onSave.subscribe((requestVerification: RequestVerificationOutput) => {
            const updatedRequestVerifications: RequestVerificationOutput[] = [...this.requestVerifications];
            const index = updatedRequestVerifications.findIndex((x) => x.id === requestVerification.id);
            if (index < 0) {
                return;
            }
            const requestVerificationFound = updatedRequestVerifications[index];
            requestVerificationFound.status = requestVerification.status;
            requestVerificationFound.quantity = requestVerification.quantity;
            requestVerificationFound.isExpress = requestVerification.isExpress;
            requestVerificationFound.requestVerificationNotes = requestVerification.requestVerificationNotes;
            requestVerificationFound.detailedResult = requestVerification.detailedResult;
            requestVerificationFound.result = requestVerification.result;

            if (requestVerificationFound.verification.code === 'DJI' || requestVerificationFound.verification.code === 'ADD') {
                requestVerificationFound.verificationCustomPrice = requestVerification.verificationCustomPrice;
            }

            if (requestVerificationFound.verification.code === 'DJI') {
                requestVerificationFound.country = requestVerification.country;
            }

            if (requestVerificationFound.verification.code === 'ADD') {
                requestVerificationFound.verificationCustomField = requestVerification.verificationCustomField;
            }

            this.gridRequestVerificationApi.updateRowData({
                update: [requestVerificationFound]
            });
            updatedRequestVerifications[index] = requestVerificationFound;
            this.requestVerifications = [...updatedRequestVerifications];

            if (requestVerificationFound.detailedResult != null && requestVerificationFound.detailedResult.length > 1) {
                this.fixLanguage = true;
                this.formGroup.controls['reportLanguage'].disable();
            }
        });
    }

    refreshVerificationList() {
        this.loadRequestVerifications();
    }

    requestVerificationsHasChanges(): boolean {
        let hasChanges = false;

        // Local methods for code clarity
        const notesHasChanges = (rvA: RequestVerificationOutput, rvB: RequestVerificationOutput): boolean => {
            return rvA.requestVerificationNotes.length !== rvB.requestVerificationNotes.length ||
                rvA.requestVerificationNotes.some(noteA => rvB.requestVerificationNotes.some(noteB => noteA.id === noteB.id &&
                    noteA.note !== noteB.note));
        };

        const verificationHasChanges = (rvA: RequestVerificationOutput, rvB: RequestVerificationOutput): boolean => {
            return rvA.verification.code != rvB.verification.code ||
                rvA.status != rvB.status ||
                rvA.quantity != rvB.quantity ||
                rvA.isExpress != rvB.isExpress ||
                notesHasChanges(rvA, rvB) ||
                rvA.result != rvB.result ||
                rvA.detailedResult != rvB.detailedResult
        };

        // Search for updates on requestVerifications
        for (let rvInitial of this.initialRequestVerifications) {
            const rvUpdated = this.requestVerifications.find(rv => rv.id === rvInitial.id);

            if (verificationHasChanges(rvInitial, rvUpdated)) {
                hasChanges = true;
                break;
            }
        }
        return hasChanges;
    }

    private atLeastOneVerificationIsCompleted(): boolean {
        return this.requestVerifications.some(rv => rv.status === RequestVerificationStatus.Completed);
    }
}
