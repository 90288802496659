<ng-container *ngIf="verificationFormModel?.questions.length > 0">
    <div *ngFor="let question of verificationFormModel.questions" class="row form-group">
        <custom-form-question
            class="col-12"
            [question]="question"
            [parentFormControl]="existingFormControl"
            [ignoreIsMandatory]="ignoreIsMandatory"
            [formLanguage]="formLanguage"
        ></custom-form-question>
    </div>
</ng-container>
