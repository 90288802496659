<app-request-table-legend *ngIf="includeLegend"></app-request-table-legend>
<ag-grid-angular
    #agGrid
    localizeText
    class="ag-theme-material gardium-grid"
    [class.hide-pagination]="widgetMode"
    domLayout="autoHeight"
    data-test-id="request-page-grid"
    [gridOptions]="gridOptions"
>
</ag-grid-angular>
