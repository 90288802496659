<div class="tab-body">
    <div class="m-subheader">
        <div class="row align-items-center">
            <div class="col text-end mt-3 mt-md-0">
                <a href="javascript:" (click)="openModal()" class="btn btn-primary"
                    *ngIf="!readOnly">
                    <i class="fa fa-plus"></i>
                    {{ 'New' | localize }}
                </a>
            </div>
        </div>
    </div>
    <div class="grid-pagination-space">
        <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
        <ag-grid-angular
            #agGrid
            localizeText
            class="ag-theme-material gardium-small-grid"
            [rowData]="references$ | async"
            [columnDefs]="columnDefs"
            [suppressCellSelection]="true"
            [suppressRowHoverHighlight]="true"
            [paginationPageSize]="paginationPageSize"
            [pagination]="true">
        </ag-grid-angular>
    </div>
    <references-modal *ngIf="showModal"
        [requestVerifications]="requestVerificationForReferences"
        (referenceCreated)="onReferenceCreated($event)"
        (referenceUpdated)="onReferenceUpdated($event)"
        (onClose)="onModalClosed()"
    >
    </references-modal>
</div>
