import { NgModule } from '@angular/core';
import { Route, Router, RouterModule, Routes, NavigationStart, NavigationEnd } from '@angular/router';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';

import { CandidatsListComponent } from './app/candidats/candidats-list/candidats-list.component';
import { VerificationsListComponent } from './app/verifications/verifications-list/verifications-list.component';
import { OrganizationListComponent } from './app/organizations/organizations-list/organizations-list.component';
import { OrganizationCreateComponent } from './app/organizations/organization-create/organization-create.component';
import { OrganizationUpdateComponent } from './app/organizations/organization-update/organization-update.component';
import { OrganizationDetailsComponent } from './app/organizations/organization-details/organization-details.component';
import { VerificationDetailsComponent } from './app/verifications/verification-details/verification-details.component';
import { VerificationUpdateComponent } from './app/verifications/verification-update/verification-update.component';
import { CandidatCreateComponent } from './app/candidats/candidat-create/candidat-create.component';
import { CandidatDetailsComponent } from './app/candidats/candidat-details/candidat-details.component';
import { RequestPageComponent } from './app/requests/request-page/request-page.component';
import { OrganizationVerificationAddComponent } from './app/organizations/organization-verification-add/organization-verification-add.component';
import { OrganizationVerificationsListComponent } from './app/organizations/organization-verifications-list/organization-verifications-list.component';
import { OrganizationVerificationUpdateComponent } from './app/organizations/organization-verification-update/organization-verification-update.component';
import { CandidatUpdateComponent } from './app/candidats/candidat-update/candidat-update.component';
import { RequestDetailsComponent } from './app/requests/request-details/request-details.component';
import { RequestUpdateComponent } from './app/requests/request-update/request-update.component';
import { UsersOrganizationsComponent } from './app/users/users-organizations/users-organizations.component';
import { MainInvoiceListComponent } from './app/invoiceElements/main-invoice-list/main-invoice-list.component';
import { InvoiceDetailsComponent } from './app/invoiceElements/invoice-details/invoice-details.component';
import { InvoiceUpdateComponent } from './app/invoiceElements/invoice-update/invoice-update.component';
import { WizzardIntroComponent } from './app/invoice-wizzards/wizzard-intro/wizzard-intro.component';
import { FilesComponent } from './app/files/files.component';
import { WizzardRequestCreatorComponent } from './app/requests/request-wizzard-creator/wizzard-request/wizzard-request.component';
import { RequestReportComponent } from './app/requests/request-reports/request-report.component';
import { WizzardStatusInvoiceIntroComponent } from './app/status-invoice-wizzards/wizzard-status-invoice-intro/wizzard-status-invoice-intro.component';
import { AppComponent } from './app/app.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { NgxSpinnerService } from 'ngx-spinner';

export const anonymousApiRoutes: Route[] = [
    {
        path: 'api/guides/active',
    },
    {
        path: 'api/faq',
    }
];

export const anonymousRoutes: Route[] = [
    {
        path: 'tutorials/guides',
        loadChildren: () => import('./app/tutorials/guides/guides.module').then((m) => m.TutorialGuidesModule),
    },
    {
        path: 'tutorials/faq',
        loadChildren: () => import('./app/tutorials/faq/faq-list.module').then((m) => m.FaqListModule),
        data: { preload: true }
    },
    {
        path: 'account/b2b',
        loadChildren: () =>
            import('./app/create-organisation/create-organisation.module').then((m) => m.CreateOrganisationModule),
        data: { preload: true }
    }
];

export const allRoutes: Routes = [
    {

        path: '',
        component: AppComponent,
        children: anonymousRoutes,
    },
    {

        path: '',
        component: AppComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'dashboard',
                loadChildren: () => import('./app/dashboard/dashboard.module').then((m) => m.DashboardModule),
                data: { permission: 'Pages.Administration.Host.Dashboard' }
            },
            {
                path: 'users',
                loadChildren: () => import('./app/users/users.module').then((m) => m.UsersModule),
                data: { permission: 'Pages.Administration.Users' }
            },
            {
                path: 'users-organizations/:id/:userName',
                component: UsersOrganizationsComponent,
                data: { permission: 'Pages.Administration.Users.Organizations' }
            },
            {
                path: 'users-organizations/:userName',
                component: UsersOrganizationsComponent,
                data: { permission: 'Pages.Administration.Users.Organizations' }
            },
            {
                path: 'statistics',
                loadChildren: () => import('./app/statistics/statistics.module').then((m) => m.StatisticsModule),
                data: { permission: 'Pages.Administration.Statistics' }
            },
            {
                path: 'overview',
                loadChildren: () => import('./app/overview/overview.module').then((m) => m.OverviewModule),
                data: { permission: 'Pages.Administration.Overview' }
            },
            {
                path: 'roles',
                loadChildren: () => import('./app/roles/roles.module').then((m) => m.RolesModule),
                data: { permission: 'Pages.Administration.Roles' }
            },
            {
                path: 'candidats',
                component: CandidatsListComponent,
                data: { permission: 'Pages.Management.Candidat.List' }
            },
            {
                path: 'candidats/:organizationName',
                component: CandidatsListComponent,
                data: { permission: 'Pages.Management.Candidat.List' }
            },
            {
                path: 'candidats/:organizationPublicId',
                component: CandidatsListComponent,
                data: { permission: 'Pages.Management.Candidat.List' }
            },
            {
                path: 'candidats-create',
                component: CandidatCreateComponent,
                data: { permission: 'Pages.Management.Candidat.Create' }
            },
            {
                path: 'candidats-create/:publicId',
                component: CandidatCreateComponent,
                data: { permission: 'Pages.Management.Candidat.Create' }
            },
            {
                path: 'candidats-create/:publicId/:fromOrganization',
                component: CandidatCreateComponent,
                data: { permission: 'Pages.Management.Candidat.Create' }
            },
            {
                path: 'candidats-create/:createRequestToo',
                component: CandidatCreateComponent,
                data: { permission: 'Pages.Management.Candidat.Create' }
            },
            {
                path: 'candidats-details/:publicId',
                component: CandidatDetailsComponent,
                data: { permission: 'Pages.Management.Candidat.Details' }
            },
            {
                path: 'candidats-details/:publicId/:fromOrganization',
                component: CandidatDetailsComponent,
                data: { permission: 'Pages.Management.Candidat.Details' }
            },
            {
                path: 'candidats-update/:publicId',
                component: CandidatUpdateComponent,
                data: { permission: 'Pages.Management.Candidat.Update' }
            },
            {
                path: 'candidats-update/:publicId/:fromOrganization',
                component: CandidatUpdateComponent,
                data: { permission: 'Pages.Management.Candidat.Update' }
            },
            {
                path: 'organizations-candidats-update/:candidatPublicId/:organizationPublicId',
                component: CandidatUpdateComponent,
                data: { permission: 'Pages.Management.Candidat.Update' }
            },
            {
                path: 'organizations',
                component: OrganizationListComponent,
                data: { permission: 'Pages.Management.Organizations' }
            },
            {
                path: 'organizations-create',
                component: OrganizationCreateComponent,
                data: { permission: 'Pages.Management.Organizations.Create' }
            },
            {
                path: 'organizations-update/:publicId',
                component: OrganizationUpdateComponent,
                data: { permission: 'Pages.Management.Organizations.Update' }
            },
            {
                path: 'organizations-details/:publicId',
                component: OrganizationDetailsComponent,
                data: { permission: 'Pages.Management.Organizations.Details' }
            },
            {
                path: 'organizations-verificationAdd/:publicId',
                component: OrganizationVerificationAddComponent
            },

            {
                path: 'organizations-verificationAdd/:publicId/:fromOrganization',
                component: OrganizationVerificationAddComponent
            },
            { path: 'organizationVerifications/:publicId', component: OrganizationVerificationsListComponent },
            {
                path: 'organizations-verification-update/:organizationId/:verificationId',
                component: OrganizationVerificationUpdateComponent
            },
            {
                path: 'suborganization-create/:publicId/:organizationName',
                component: OrganizationCreateComponent,
                data: { permission: 'Pages.Management.Organizations.Create' }
            },
            {
                path: 'requests',
                component: RequestPageComponent,
                data: { permission: 'Pages.Management.Requests' }
            },
            {
                path: 'requests-wizard/:publicId',
                component: WizzardRequestCreatorComponent,
                data: { permission: 'Pages.Management.Requests.Create.Wizzard' }
            },
            {
                path: 'requests-details/:publicId',
                component: RequestDetailsComponent,
                data: { permission: 'Pages.Management.Requests.Update' }
            },
            { path: 'requests-update/:publicId', component: RequestUpdateComponent },
            { path: 'requests-report/:publicId', component: RequestReportComponent },
            {
                path: 'verifications',
                component: VerificationsListComponent,
                data: { permission: 'Pages.Management.Verifications' }
            },
            {
                path: 'verifications-details/:publicId',
                component: VerificationDetailsComponent,
                data: { permission: 'Pages.Management.Verifications.Details' }
            },
            {
                path: 'verifications-update/:publicId',
                component: VerificationUpdateComponent,
                data: { permission: 'Pages.Management.Verifications.Update' }
            },
            {
                path: 'tutorials/verifications',
                loadChildren: () => import('./app/tutorials/verifications/verification-list.module').then((m) => m.VerificationListModule),
                data: { preload: true, permission: 'Pages.VerificationListInfo' },
            },
            {
                path: 'guides-management',
                loadChildren: () => import('./app/tutorials/guides/management/guides-management.module').then((m) => m.GuidesManagementModule),
            },
            {
                path: 'faq-management',
                loadChildren: () => import('./app/tutorials/faq/management/faq-management.module').then((m) => m.FaqManagementModule),
                data: { preload: true, permission: 'Pages.Administration.FAQMenu.Edit' }
            },
            {
                path: 'invoice-list',
                component: MainInvoiceListComponent,
                data: { permission: 'Pages.Administration.Invoices' }
            },
            { path: 'invoice-details/:publicId', component: InvoiceDetailsComponent },
            { path: 'invoice-update/:publicId', component: InvoiceUpdateComponent },
            { path: 'invoice-generator', component: WizzardIntroComponent },
            { path: 'files', component: FilesComponent, data: { permission: 'Pages.Management.Files' } },
            {
                path: 'status-invoice',
                component: WizzardStatusInvoiceIntroComponent,
                data: { permission: 'Pages.Administration.Invoices' }
            },
            { path: '**', redirectTo: 'dashboard' }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(allRoutes)],
    exports: [RouterModule],
    providers: [],
})
export class RootRoutingModule {
    constructor(private router: Router, private spinnerService: NgxSpinnerService, private _uiCustomizationService: AppUiCustomizationService) {
        spinnerService.show();
        router.events.subscribe((event: NavigationEnd) => {

            if (event instanceof NavigationStart) {
                spinnerService.show();
            }

            if (event instanceof NavigationEnd) {
                document.querySelector('meta[property=og\\:url').setAttribute('content', window.location.href);
                spinnerService.hide();
                window.scroll(0, 0);
            }

            setTimeout(() => {
                this.toggleBodyCssClass(event.url);
            }, 0);
        });
    }

    toggleBodyCssClass(url: string): void {
        this.setAppModuleBodyClassInternal();
    }

    setAppModuleBodyClassInternal(): void {
        const currentBodyClass = document.body.className;
        let classesToRemember = '';

        if (currentBodyClass.indexOf('brand-minimize') >= 0) {
            classesToRemember += ' brand-minimize ';
        }

        if (currentBodyClass.indexOf('aside-left-minimize') >= 0) {
            classesToRemember += ' aside-left-minimize';
        }

        if (currentBodyClass.indexOf('brand-hide') >= 0) {
            classesToRemember += ' brand-hide';
        }

        if (currentBodyClass.indexOf('aside-left-hide') >= 0) {
            classesToRemember += ' aside-left-hide';
        }

        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }

        document.body.className = this._uiCustomizationService.getAppModuleBodyClass() + ' ' + classesToRemember;
    }

    setAccountModuleBodyClassInternal(): void {
        const currentBodyClass = document.body.className;
        let classesToRemember = '';

        if (currentBodyClass.indexOf('swal2-toast-shown') >= 0) {
            classesToRemember += ' swal2-toast-shown';
        }

        document.body.className = this._uiCustomizationService.getAccountModuleBodyClass() + ' ' + classesToRemember;
    }
}
