import {Injectable} from '@angular/core';
import {IAjaxResponse} from '@shared/models/ajax-response';
import {fileType} from '@shared/models/files/fileType';
import {Observable} from 'rxjs';
import {environment as env} from 'environments/environment';
import {HttpClient, HttpParams} from "@angular/common/http";
import {FileValidationResult} from "@shared/models/files/fileValidationResult";
import {AppConsts} from "@shared/AppConsts";
import {RequestFileInput} from "@shared/models/requestFile/requestFileInput";
import {FilesOutput} from "@shared/models/files/filesOutput";
import {FileDto} from "@shared/service-proxies/service-proxies";
import {catchError, map} from "rxjs/operators";

@Injectable()
export class RequestFilesService {
    private readonly _acceptedFileExtensions = ['.jpeg','.jpg','.png','.pdf'] as ReadonlyArray<string>;
    private readonly _sizesFormat = ['o', 'Ko', 'Mo', 'Go', 'To'] as ReadonlyArray<string>;
    private readonly _baseUrl: string = env.api + '/api/request-file';

    constructor(
        private readonly _http: HttpClient
    ) { }

    public getAcceptedFileExtensions(): string[] {
        return [...this._acceptedFileExtensions];
    }

    public blobToFile = (theBlob: Blob, fileName: string): File => {
        const b: any = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return b as File;
    };

    public getReadableSize(sizeInOctets: number): string {
        if (sizeInOctets === 0) {
            return '0 o';
        }

        const i = Math.floor(Math.log(sizeInOctets) / Math.log(1024));
        const readableSize = (sizeInOctets / Math.pow(1024, i)).toFixed(2);
        return `${readableSize} ${this._sizesFormat[i]}`;
    }

    public validateFile(file: File): FileValidationResult {
        if (file.size > AppConsts.maxFileSize) {
            return FileValidationResult.InvalidSize;
        } else if (!this.validateFileExtension(file.name)) {
            return FileValidationResult.InvalidExtension;
        } else {
            return FileValidationResult.Valid;
        }
    }

    public validateFileExtension(fileName: string): boolean {
        const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
        return this._acceptedFileExtensions.includes(fileExtension);
    }

    public uploadFiles(files: File[], fileType: fileType, requestId: number, verificationId: number = 0): Observable<FilesOutput[]> {
        const formData: FormData = new FormData();
        files.forEach(file => {
            formData.append('files', file, file.name);
        });
        formData.append('FileType', fileType.toString());
        formData.append('RequestId', requestId.toString());
        formData.append('VerificationId', verificationId.toString());

        return this._http.post<IAjaxResponse<FilesOutput[]>>(this._baseUrl + '/add-request-files', formData).pipe(
            map((response: IAjaxResponse<FilesOutput[]>) => {
                if (response.success) {
                    return response.result;
                } else {
                    throw new Error(response.error.message);
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    public getRequestFilesOfGivenType(requestId: number, fileType: fileType): Observable<FilesOutput[]> {
        const url_ = this.getUrl('/GetRequestFilesOfGivenType');
        const params = new HttpParams().set('requestId', requestId).set('fileType', fileType);
        return this._http.get<IAjaxResponse<FilesOutput[]>>(url_, {params}).pipe(
            map((response: IAjaxResponse<FilesOutput[]>) => {
                if (response.success) {
                    return response.result;
                } else {
                    throw new Error(response.error.message);
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    public getFileDto(requestFile: RequestFileInput): Observable<FileDto> {
        return this._http.post<IAjaxResponse<FileDto>>(this.getUrl('/GetRequestFile'), requestFile).pipe(
            map((response: IAjaxResponse<FileDto>) => {
                if (response.success) {
                    return response.result;
                } else {
                    throw new Error(response.error.message);
                }
            }),
            catchError(err => {
                throw err;
            })
        );
    }

    public deleteFile(filePublicId: string, requestId: number): Observable<void> {
        const url_ = this.getUrl(`/${requestId}/DeleteRequestFile/${filePublicId}`);
        return this._http.delete<void>(url_).pipe(
            catchError((err) => {
                throw err;
            })
        );
    }

    private getUrl(url: string): string {
        let url_ = this._baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
