export enum verificationsCodes {
    'CIPC' = 'CIPC',
    'CIPC+' = 'CIPC+',
    'VDJP' = 'VDJP',
    'DCQC' = 'DCQC',
    'PCQ' = 'PCQ',
    'CSPQ' = 'CSPQ',
    'CRE' = 'CRE',
    'SAAQ' = 'SAAQ',
    'USAL' = 'USAL',
    'PRO' = 'PRO',
    'CEMP' = 'CEMP',
    'PERS' = 'PERS',
    'FACA' = 'FACA',
    'FACA+' = 'FACA+',
    'ASSP' = 'ASSP',
    'DJI' = 'DJI',
    'ADD' = 'ADD',
    'INTER' = 'INTER',
    'ATMN' = 'ATMN',
    'ATMN+' = 'ATMN+',
    'EDPR' = 'EDPR',
    'AUT' = 'AUT',
    'VPT' = 'VPT',
    'EDIG' = 'EDIG',
    'PMUN' = 'PMUN',
    'LOC' = 'LOC',
    'RSM' = 'RSM',
    'FORFAIT_A' = 'FORFAIT A',
    'FORFAIT_B' = 'FORFAIT B',
    'FORFAIT_C' = 'FORFAIT C',
    'FORFAIT_D' = 'FORFAIT D',
}

export class RequiredFieldsForCodes {
    static getDriverLicenseRequiredCodes(): string[] {
        return [verificationsCodes.SAAQ, verificationsCodes.USAL];
    }

    static getEmailRequiredCodes(): string[] {
        return [verificationsCodes.ATMN, verificationsCodes['ATMN+']];
    }

    static isEmailRequired(verificationCode: string): boolean {
        return verificationCode === verificationsCodes.ATMN || verificationCode === verificationsCodes['ATMN+'];
    }

    static isDriverLicenseRequired(verificationCode: string): boolean {
        return verificationCode === verificationsCodes.SAAQ || verificationCode === verificationsCodes.USAL;
    }

    static isMoreThanOneAddressFormerRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.INTER:
            case verificationsCodes.DJI:
            case verificationsCodes.CIPC:
            case verificationsCodes['CIPC+']:
            case verificationsCodes.ADD:
                return true;
            default:
                return false;
        }
    }

    static isReferenceRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.CEMP:
            case verificationsCodes.PERS:
            case verificationsCodes.PRO:
                return true;
            default:
                return false;
        }
    }

    static isAcademicRecordsRequired(verificationCode: string): boolean {
        return verificationCode === verificationsCodes.FACA || verificationCode === verificationsCodes['FACA+'];
    }

    static isProfessionnalAccreditationsRequired(verificationCode: string): boolean {
        return verificationCode === verificationsCodes.ASSP;
    }

    static isAddressRequired(verificationCode: string): boolean {
        switch (verificationCode) {
            case verificationsCodes.PCQ:
            case verificationsCodes.CRE:
            case verificationsCodes.PERS:
            case verificationsCodes.DJI:
                return true;
            default:
                return false;
        }
    }
}
