import { Directive, ElementRef, HostListener, Input } from '@angular/core';

export enum TextTransform {
    Title = 'title',
    Upper = 'upper',
    Lower = 'lower'
}

export type Case = 'title' | 'upper' | 'lower';

@Directive({
    selector: '[appTextTransform]'
})
export class TextTransformDirective {

    @Input() public appTextTransform: Case;
    private isInternalChange = false;

    @HostListener('input', ['$event']) public onInput(event: Event) {
        if (!this.appTextTransform || this.isInternalChange) {
            this.isInternalChange = false;
            return;
        }

        const inputElement = event.target as HTMLInputElement;
        const inputValue = inputElement.value;
        let transformedValue: string;
        switch (this.appTextTransform) {
            case 'title':
                transformedValue = inputValue.replace(/([A-Za-zÀ-ÿ])+/g, (word) => word[0].toUpperCase() + word.substring(1).toLowerCase());
                break;
            case 'upper':
                transformedValue = inputValue.toUpperCase();
                break;
            case 'lower':
                transformedValue = inputValue.toLowerCase();
                break;
            default:
                transformedValue = inputValue;
        }

        // Update input value
        inputElement.value = transformedValue;

        // Manually dispatch a new 'input' event to notify Angular of a change and propagate it to the bound model
        this.isInternalChange = true;
        const newEvent = new Event('input', { bubbles: true });
        inputElement.dispatchEvent(newEvent);
    }
}
