<textarea
    *ngIf="questionControl"
    class="form-control"
    [id]="name"
    [formControl]="questionControl"
    [attr.maxlength]="maxLength || null"
    [attr.placeholder]="placeholderText"
>
</textarea>
<div class="d-flex justify-end">
    <small *ngIf="maxLength" class="character-counter">
        <span>{{ characterCount }}</span> / <span>{{ maxLength }}</span>
    </small>
</div>
