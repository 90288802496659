<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Verifications' | localize" [description]="'ListVerification' | localize">
            <div role="actions"></div>
        </sub-header>
        <div [class]="containerClass">
            <div class="card card-custom listContainer">
                <div class="card-body">
                    <form class="horizontal-form" autocomplete="off">
                        <div class="m-form m-form--label-align-right">
                            <div class="row align-items-center m--margin-bottom-10">
                                <div class="col-xl-12">
                                    <div class="form-group m-form__group align-items-center">
                                        <div class="input-group">
                                            <input [(ngModel)]="filterText" name="filterText" id="filterText" autoFocus
                                                class="form-control m-input" [placeholder]="l('Search')" type="text"
                                                (keydown.enter)="search()" (ngModelChange)="search()"/>
                                            <span class="input-group-btn">
                                                <button (click)="search()" class="btn btn-primary"
                                                    type="submit"><i class="flaticon-search-1"
                                                        [attr.aria-label]="l('Search')"></i></button>
                                            </span>
                                            <span class="input-group-btn" style="padding-left:5px;">
                                                <button class="btn btn-secondary" (click)="refresh()"><i
                                                        class="la la-refresh"></i>
                                                    {{'Refresh' | localize}}</button>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="action-container">
                        <div class="input-group-btn">
                            <button class="btn btn-outline-secondary" (click)="resetFilters()">
                                <i class="la la-refresh"></i>
                                {{ "Grid.resetFilters" | localize }}
                            </button>
                            <button class="btn btn-outline-secondary" (click)="resetSort()">
                                <i class="la la-refresh"></i>
                                {{ "Grid.resetColumns" | localize }}
                            </button>
                        </div>
                        <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)">
                        </grid-pagination>
                    </div>
                    <ag-grid-angular localizeText StyleRow [updateList]="'verificationsList'" #agGrid
                        class="ag-theme-material gardium-grid" [rowSelection]="rowSelection" [rowData]="rowData$ | async"
                        [paginationPageSize]="paginationPageSize" [gridOptions]="gridOptions"
                        [paginationNumberFormatter]="paginationNumberFormatter" [pagination]="true"
                        [columnDefs]="columnDefs" (selectionChanged)="onSelectionChanged()"
                        (gridReady)="onGridReady($event)" domLayout="autoHeight" (sortChanged)="onSortChanged($event)"
                        (filterChanged)="onFilterChanged($event)" (firstDataRendered)="onFirstDataRendered()">
                    </ag-grid-angular>
                </div>
            </div>
        </div>
    </div>
</div>
