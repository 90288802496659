import { CommonModule, DatePipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { ServiceProxyModule } from '@shared/service-proxies/service-proxy.module';
import { UtilsModule } from '@shared/utils/utils.module';
import { FileUploadModule } from 'ng2-file-upload';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { BsDatepickerModule, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FileUploadModule as PrimeNgFileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './shared/layout/themes/default/default-layout.component';
import { AppCommonModule } from './shared/common/app-common.module';
import { SideBarMenuComponent } from './shared/layout/nav/side-bar-menu.component';
import { TopBarMenuComponent } from './shared/layout/nav/top-bar-menu.component';
import { LanguageSwitchDropdownComponent } from './shared/layout/topbar/language-switch-dropdown.component';
import { UserMenuComponent } from './shared/layout/topbar/user-menu.component';
import { DefaultBrandComponent } from './shared/layout/themes/default/default-brand.component';
import { ImageCropperModule } from 'ngx-image-cropper';

import { DefaultLogoComponent } from './shared/layout/themes/default/default-logo.component';

// Metronic
import { MenuSearchBarComponent } from './shared/layout/nav/menu-search-bar/menu-search-bar.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ScrollTopComponent } from './shared/layout/scroll-top.component';

import { OrganizationService } from '@shared/services/organization.service';
import { VerificationService } from '@shared/services/verification.service';
import { GridBtnUpdateComponent } from './shared/grid/grid-btn-update/grid-btn-update.component';
import { CountryService } from '@shared/services/country.service';
import { StatesService } from '@shared/services/states.service';
import { GridBtnDeleteComponent } from './shared/grid/grid-btn-delete/grid-btn-delete.component';
import { RequestService } from '@shared/services/requests.service';
import { RequestFilesService } from '@shared/services/request-files.service';
import { GuidesService } from '@shared/services/guides.service';
import { OrganizationVerificationCostService } from '@shared/services/organizationVerificationCost.service';
import { GridBtnUpdateTwoparametersComponent } from './shared/grid/grid-btn-update-twoparameters/grid-btn-update-twoparameters.component';
import { NotesService } from '@shared/services/note.service';
import { OrganizationCreditCardService } from '@shared/services/organizationCreditCard.service';
import { BtnListComponent } from './shared/button/btn-list/btn-list.component';
import { GridBtnSendEmailComponent } from './shared/grid/grid-btn-sendEmail/grid-btn-sendEmail.component';
import { ConfirmDialogComponent } from './shared/dialog/confirm-dialog/confirm-dialog.component';
import { FilesSelectionModalComponent } from './shared/files/files-selection-modal/files-selection-modal.component';
import { GridBtnUpdateOrganizationVerificationComponent } from './shared/grid/grid-btn-update-organizationVerification/grid-btn-update-organizationVerification.component';
import { GridBtnComponent } from './shared/grid/grid-btn/grid-btn.component';
import { GridCheckboxIsMandatoryComponent } from './shared/grid/grid-checkbox-is-mandatory/grid-checkbox-is-mandatory.component';
import { FaqService } from '@shared/services/faq.service';
import { DialogService } from '@shared/services/dialog-service';
import { RequestVerificationService } from '@shared/services/requestVerification.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ToastrModule } from 'ngx-toastr';
import { LayoutConfigService } from '@metronic/app/core/services/layout-config.service';
import { UtilsService } from '@metronic/app/core/services/utils.service';
import { LayoutRefService } from '@metronic/app/core/_base/layout/services/layout-ref.service';
import { UserEmailNotificationService } from '@shared/services/userEmailNotification.service';
import { EmailNotificationService } from '@shared/services/emailNotification.service';
import { InvoiceDataSourceService } from '@shared/services/invoice-data-source.service';
import { GridBtnCancelComponent } from './shared/grid/grid-btn-cancel/grid-btn-cancel.component';
import { GridDownloadLinksForVerificationFilesComponent } from './shared/grid/grid-download-links-for-verification-files/grid-download-links-for-verification-files.component';
import { RequestReportsService } from '@shared/services/request-reports.service';
import { CkeditorService } from '@shared/services/ckeditor.service';
import { ErrorHandlerService } from '@shared/services/error-handler.service';
import { CandidateDatasourceService } from '@shared/services/candidates.datasource.service';
import { OrganizationDatasourceService } from '@shared/services/organization.datasource.service';
import { UserOrganizationDatasourceService } from '@shared/services/userOrganization.datasource.service';
import { ChangeProfilePictureModalComponent } from './shared/layout/profile/change-profile-picture-modal.component';
import { CoreModule } from '@metronic/app/core/core.module';

import { SubheaderModule } from '@app/shared/common/sub-header/subheader.module';
import { TreeDragDropService } from 'primeng/api';
import { BsModalRef, ModalModule } from 'ngx-bootstrap/modal';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import { TreeModule } from 'primeng/tree';
import { DragDropModule } from 'primeng/dragdrop';
import { ContextMenuModule } from 'primeng/contextmenu';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CountoModule } from 'angular2-counto';
import { NgxBootstrapDatePickerConfigService } from 'assets/ngx-bootstrap/ngx-bootstrap-datepicker-config.service';
import { DropdownModule } from 'primeng/dropdown';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AppBsModalModule } from '@shared/common/appBsModal/app-bs-modal.module';
import { RequestPageComponent } from './requests/request-page/request-page.component';
import { CandidatsListComponent } from './candidats/candidats-list/candidats-list.component';
import { OrganizationListComponent } from './organizations/organizations-list/organizations-list.component';
import { VerificationsListComponent } from './verifications/verifications-list/verifications-list.component';
import { OrganizationCreateComponent } from './organizations/organization-create/organization-create.component';
import { VerificationCreateComponent } from './verifications/verification-create/verification-create.component';
import { VerificationDetailsComponent } from './verifications/verification-details/verification-details.component';
import { VerificationUpdateComponent } from '././verifications/verification-update/verification-update.component';
import { OrganizationUpdateComponent } from './organizations/organization-update/organization-update.component';
import { OrganizationDetailsComponent } from './organizations/organization-details/organization-details.component';
import { CandidatCreateComponent } from './candidats/candidat-create/candidat-create.component';
import { CandidatDetailsComponent } from './candidats/candidat-details/candidat-details.component';
import { CandidatUpdateComponent } from './candidats/candidat-update/candidat-update.component';
import { OrganizationVerificationAddComponent } from './organizations/organization-verification-add/organization-verification-add.component';
import { OrganizationVerificationUpdateComponent } from './organizations/organization-verification-update/organization-verification-update.component';
import { OrganizationVerificationsListComponent } from './organizations/organization-verifications-list/organization-verifications-list.component';
import { UpdateOrganizationCandidatesListComponent } from './organizations/update-organization-candidates-list/update-organization-candidates-list.component';
import { RequestDetailsComponent } from './requests/request-details/request-details.component';
import { ListSearchComponent } from './organizations/list-search/list-search.component';
import { RequestUpdateComponent } from './requests/request-update/request-update.component';
import { CandidatsDetailsComponent } from './requests/requests-informations/candidats-details/candidats-details.component';
import { AddressHistoryComponent } from './requests/requests-informations/address-history/address-history-current/address-history-current.component';
import { UsersOrganizationsComponent } from './users/users-organizations/users-organizations.component';
import { NoteListComponent } from './requests/requests-informations/notes/notes-list/notes-list.component';
import { NoteAddComponent } from './requests/requests-informations/notes/notes-add/notes-add.component';
import { NoteDetailsComponent } from './requests/requests-informations/notes/notes-details/notes-details.component';
import { RequestAddressHistoryListComponent } from './requests/requests-informations/address-history/address-history-fomer/request-address-history-list/request-address-history-list.component';
import { CreateAddressHistoryFormerComponent } from './requests/requests-informations/address-history/address-history-fomer/create-address-history-former/create-address-history-former.component';
import { UpdateAddressHistoryFormerComponent } from './requests/requests-informations/address-history/address-history-fomer/update-address-history-former/update-address-history-former.component';
import { AddRequestFilesComponent } from './requests/requests-informations/request-files/add-request-files/add-request-files.component';
import { ListRequestFilesComponent } from './requests/requests-informations/request-files/list-request-files/list-request-files.component';
import { ProfessionnalAccreditationsAddComponent } from './requests/requests-informations/professionnal-accreditations/professionnal-accreditations-add/professionnal-accreditations-add.component';
import { ProfessionnalAccreditationsListComponent } from './requests/requests-informations/professionnal-accreditations/professionnal-accreditations-list/professionnal-accreditations-list.component';
import { ProfessionnalAccreditationsUpdateComponent } from './requests/requests-informations/professionnal-accreditations/professionnal-accreditations-update/professionnal-accreditations-update.component';
import { AcademicRecordAddComponent } from './requests/requests-informations/accademic-records/academic-record-add/academic-record-add.component';
import { AcademicRecordListComponent } from './requests/requests-informations/accademic-records/academic-record-list/academic-record-list.component';
import { AcademicRecordUpdateComponent } from './requests/requests-informations/accademic-records/academic-record-update/academic-record-update.component';
import { VerificationDescriptionUpdateModule } from './tutorials/verifications/management/update/verification-description-update.module';
import { VerificationDescriptionAddModule } from './tutorials/verifications/management/add/verification-description-add.module';
import { InvoiceListComponent } from './invoiceElements/invoice-list/invoice-list.component';
import { MainInvoiceListComponent } from './invoiceElements/main-invoice-list/main-invoice-list.component';
import { InvoiceDetailsComponent } from './invoiceElements/invoice-details/invoice-details.component';
import { InvoiceDetailsCandidatsComponent } from './invoiceElements/invoice-details/invoice-details-candidats/invoice-details-candidats-component';
import { InvoiceUpdateComponent } from './invoiceElements/invoice-update/invoice-update.component';
import { WizzardIntroComponent } from './invoice-wizzards/wizzard-intro/wizzard-intro.component';
import { WizzardOrganizationsComponent } from './invoice-wizzards/wizzard-organizations/wizzard-organizations.component';
import { WizzardRequestsComponent } from './invoice-wizzards/wizzard-requests/wizzard-requests.component';
import { WizzardInvoiceDateComponent } from './invoice-wizzards/wizzard-invoice-date/wizzard-invoice-date.component';
import { FilesComponent } from './files/files.component';
import { RequestVerificationUpdateComponent } from './requests/request-verification-update/request-verification-update.component';
import { InvoiceDetailsCustomizeComponent } from './invoiceElements/invoice-details/invoice-details-customize/invoice-details-customize.component';
import { OrganizationInvoiceListComponent } from './organizations/organization-invoice/organization-invoice.component';
import { OrganizationUserListComponent } from './organizations/organization-details/tabs/users/organization-user.component';
import { InvoiceDesktopComponent } from './invoiceElements/invoice-details/invoice-desktop/invoice-desktop.component';
import { InvoiceMobileComponent } from './invoiceElements/invoice-details/invoice-mobile/invoice-mobile.component';
import { RequestverificationQuantityConfirmationComponent } from './requests/requestverification-quantity-confirmation/requestverification-quantity-confirmation.component';
import { VerificationDescriptionService } from '@shared/services/verificationDescription.service';
import { AgGridModule } from '@ag-grid-community/angular';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ArchwizardModule } from 'angular-archwizard';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { InvoiceCreditCardPopupComponent } from './invoiceElements/invoice-credit-card-popup/invoice-credit-card-popup.component';
import { OrganizationB2BService } from '@shared/services/organizationB2B.service';
import { WizzardRequestCreatorComponent } from './requests/request-wizzard-creator/wizzard-request/wizzard-request.component';
import { RequestWizardOrganizationComponent } from './requests/request-wizzard-creator/request-wizard-organization/request-wizard-organization.component';
import { RequestWizardCandidateSelectComponent } from './requests/request-wizzard-creator/request-wizard-candidate-select/request-wizard-candidate-select.component';
import { RequestWizardCandidateUpdateComponent } from './requests/request-wizzard-creator/request-wizard-candidate-update/request-wizard-candidate-update.component';
import { RequestWizardVerificationsComponent } from './requests/request-wizzard-creator/request-wizard-verifications/request-wizard-verifications.component';
import { RequestWizardCandidateCreateComponent } from './requests/request-wizzard-creator/request-wizard-candidate-create/request-wizard-candidate-create.component';
import { RequestWizardCandidateSearchComponent } from './requests/request-wizzard-creator/request-wizard-candidate-search/request-wizard-candidate-search.component';
import { RequestWizardInformationsFilesComponent } from './requests/request-wizzard-creator/request-wizard-informations-files/request-wizard-informations-files.component';
import { RequestWizardCandidateBasicInfosComponent } from './requests/request-wizzard-creator/request-wizard-candidate-basic-infos/request-wizard-candidate-basic-infos.component';
import { RequestReportComponent } from './requests/request-reports/request-report.component';
import { RequestSummaryReportComponent } from './requests/request-reports/reports/request-summary-report/request-summary-report.component';
import { ReportFooterComponent } from './requests/request-reports/shared/report-footer/report-footer.component';
import { ReportHeaderComponent } from './requests/request-reports/shared/report-header/report-header.component';
import { ReportLastPageFooterComponent } from './requests/request-reports/shared/report-last-page-footer/report-last-page-footer.component';
import { ReportPageComponent } from './requests/request-reports/shared/report-page/report-page.component';
import { CompanyInfoComponent } from './requests/request-reports/reports/request-summary-report/company-info/company-info.component';
import { ReportSectionComponent } from './requests/request-reports/shared/report-section/report-section.component';
import { NgxMarkjsModule } from '@matej.cupka/ngx-markjs';
import { WizzardEmailSendComponent } from './status-invoice-wizzards/wizzard-email-send/wizzard-email-send.component';
import { WizzardStatusInvoiceIntroComponent } from './status-invoice-wizzards/wizzard-status-invoice-intro/wizzard-status-invoice-intro.component';
import { WizzardStatusInvoiceComponent } from './status-invoice-wizzards/wizzard-invoice-status/wizzard-status-invoice.component';
import { SendingMethodComponent } from './requests/requests-informations/sending-method/sending-method.component';
import { UsersRoutingModule } from './users/users-routing.module';
import { UsersModule } from './users/users.module';
import { FaqListModule } from './tutorials/faq/faq-list.module';
import { FaqManagementModule } from './tutorials/faq/management/faq-management.module';
import { GridPaginationModule } from './shared/grid-pagination/grid-pagination.module';
import { GuidesManagementModule } from './tutorials/guides/management/guides-management.module';
import { SharedModule } from '@app/shared/shared.module';
import { RequestTableModule } from './requests/request-table/request-table.module';
import { RequestVerificationStatusGridModule } from './requests/request-verification-status-grid/request-verification-status-grid.module';
import { MySettingsModalComponent } from './shared/layout/profile/my-settings-modal.component';
import LocalizationService from '@shared/services/localization.service';
import { AppSharedModule } from './shared/app-shared.module';
import { CreateOrganisationModule } from './create-organisation/create-organisation.module';
import { AppSettingService } from '@shared/services/appSetting.service';
import { ReferencesListComponent } from '@app/requests/requests-informations/references/references-list/references-list.component';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { CustomFormComponent } from '@app/custom-form/custom-form/custom-form.component';
import { CustomFormService } from '@shared/services/custom-form.service';
import { EmailInputComponent } from '@app/shared/form/custom-inputs/email-input/email-input.component';
import { LanguageDropdownInputComponent } from '@app/shared/form/custom-inputs/language-dropdown-input/language-dropdown-input.component';
import { NameInputComponent } from '@app/shared/form/custom-inputs/name-input/name-input.component';
import { PhoneInputComponent } from '@app/shared/form/custom-inputs/phone-input/phone-input.component';
import { StandardInputComponent } from '@app/shared/form/base-inputs/standard-input/standard-input.component';
import { BooleanInputComponent } from '@app/shared/form/base-inputs/boolean-input/boolean-input.component';
import { SingleSelectionListComponent } from '@app/shared/form/base-inputs/single-selection-list/single-selection-list.component';
import { MultipleSelectionListComponent } from '@app/shared/form/base-inputs/multiple-selection-list/multiple-selection-list.component';
import { TextAreaInputComponent } from '@app/shared/form/base-inputs/textarea-input/textarea-input.component';
import { CustomFormQuestionComponent } from '@app/custom-form/custom-form-question/custom-form-question.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        SideBarMenuComponent,
        TopBarMenuComponent,
        ScrollTopComponent,
        MySettingsModalComponent,
        LanguageSwitchDropdownComponent,
        UserMenuComponent,
        DefaultBrandComponent,
        MenuSearchBarComponent,
        GridBtnUpdateComponent,
        GridBtnUpdateOrganizationVerificationComponent,
        GridCheckboxIsMandatoryComponent,
        GridBtnSendEmailComponent,
        GridBtnDeleteComponent,
        GridBtnUpdateTwoparametersComponent,
        BtnListComponent,
        ConfirmDialogComponent,
        FilesSelectionModalComponent,
        DefaultLogoComponent,
        GridBtnComponent,
        GridBtnCancelComponent,
        GridDownloadLinksForVerificationFilesComponent,
        ChangeProfilePictureModalComponent,
        RequestPageComponent,
        CandidatsListComponent,
        OrganizationListComponent,
        OrganizationDetailsComponent,
        OrganizationUserListComponent,
        VerificationsListComponent,
        OrganizationCreateComponent,
        VerificationCreateComponent,
        VerificationDetailsComponent,
        VerificationUpdateComponent,
        OrganizationUpdateComponent,
        CandidatCreateComponent,
        CandidatDetailsComponent,
        CandidatUpdateComponent,
        OrganizationVerificationAddComponent,
        OrganizationVerificationUpdateComponent,
        OrganizationVerificationsListComponent,
        UpdateOrganizationCandidatesListComponent,
        RequestDetailsComponent,
        ListSearchComponent,
        RequestUpdateComponent,
        CandidatsDetailsComponent,
        AddressHistoryComponent,
        UsersOrganizationsComponent,
        NoteListComponent,
        NoteAddComponent,
        NoteDetailsComponent,
        RequestAddressHistoryListComponent,
        CreateAddressHistoryFormerComponent,
        UpdateAddressHistoryFormerComponent,
        UsersOrganizationsComponent,
        AddRequestFilesComponent,
        ListRequestFilesComponent,
        ProfessionnalAccreditationsAddComponent,
        ProfessionnalAccreditationsListComponent,
        ProfessionnalAccreditationsUpdateComponent,
        AcademicRecordAddComponent,
        AcademicRecordListComponent,
        AcademicRecordUpdateComponent,
        InvoiceListComponent,
        MainInvoiceListComponent,
        InvoiceDetailsComponent,
        InvoiceDetailsCandidatsComponent,
        InvoiceUpdateComponent,
        WizzardIntroComponent,
        WizzardOrganizationsComponent,
        WizzardRequestsComponent,
        FilesComponent,
        RequestVerificationUpdateComponent,
        InvoiceDetailsCustomizeComponent,
        OrganizationInvoiceListComponent,
        InvoiceDesktopComponent,
        InvoiceMobileComponent,
        RequestverificationQuantityConfirmationComponent,
        WizzardInvoiceDateComponent,
        WizzardEmailSendComponent,
        InvoiceCreditCardPopupComponent,
        WizzardRequestCreatorComponent,
        RequestWizardOrganizationComponent,
        RequestWizardCandidateSelectComponent,
        RequestWizardCandidateUpdateComponent,
        RequestWizardVerificationsComponent,
        RequestWizardCandidateCreateComponent,
        RequestWizardCandidateSearchComponent,
        RequestWizardInformationsFilesComponent,
        RequestReportComponent,
        RequestSummaryReportComponent,
        ReportFooterComponent,
        ReportHeaderComponent,
        ReportLastPageFooterComponent,
        ReportPageComponent,
        CompanyInfoComponent,
        ReportSectionComponent,
        RequestWizardCandidateBasicInfosComponent,
        WizzardStatusInvoiceComponent,
        WizzardStatusInvoiceIntroComponent,
        WizzardStatusInvoiceIntroComponent,
        SendingMethodComponent
    ],
    exports: [UtilsModule],
    providers: [
        DatePipe,
        LayoutConfigService,
        UtilsService,
        LayoutRefService,
        OrganizationService,
        VerificationService,
        CountryService,
        CkeditorService,
        StatesService,
        OrganizationVerificationCostService,
        RequestService,
        RequestFilesService,
        RequestReportsService,
        InvoiceDataSourceService,
        CandidateDatasourceService,
        OrganizationDatasourceService,
        UserOrganizationDatasourceService,
        NotesService,
        DialogService,
        LocalizationService,
        FaqService,
        OrganizationCreditCardService,
        RequestVerificationService,
        UserEmailNotificationService,
        EmailNotificationService,
        TreeDragDropService,
        OrganizationB2BService,
        VerificationDescriptionService,
        GuidesService,
        BsModalRef,
        { provide: BsLocaleService, useFactory: NgxBootstrapDatePickerConfigService.getDatepickerLocale },
        { provide: ErrorHandler, useClass: ErrorHandlerService },
        AppSettingService,
        CustomFormService
    ],
    imports: [
        CoreModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        HttpClientJsonpModule,
        ModalModule.forRoot(),
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        AppCommonModule.forRoot(),
        ServiceProxyModule,
        TableModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        ProgressBarModule,
        PerfectScrollbarModule,
        ImageCropperModule,
        AutoCompleteModule,
        NgxSpinnerModule,
        AppBsModalModule,
        InputMaskModule,
        ImageCropperModule,
        AgGridModule.withComponents([
            GridBtnUpdateComponent,
            GridBtnUpdateOrganizationVerificationComponent,
            GridBtnDeleteComponent,
            GridBtnUpdateTwoparametersComponent,
            GridBtnSendEmailComponent,
            GridBtnCancelComponent,
            GridDownloadLinksForVerificationFilesComponent
        ]),
        ToastrModule.forRoot(),
        ReactiveFormsModule,
        TypeaheadModule.forRoot(),
        NgxMaskModule.forRoot(options),
        SharedModule,
        BreadcrumbModule,
        SubheaderModule,
        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        FileUploadModule,
        SubheaderModule,
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        TooltipModule.forRoot(),
        PopoverModule.forRoot(),
        BsDropdownModule.forRoot(),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        UtilsModule,
        AppCommonModule,
        TableModule,
        TreeModule,
        DragDropModule,
        ContextMenuModule,
        PaginatorModule,
        PrimeNgFileUploadModule,
        AutoCompleteModule,
        EditorModule,
        InputMaskModule,
        NgxChartsModule,
        CountoModule,
        ImageCropperModule,
        PerfectScrollbarModule,
        DropdownModule,
        AppBsModalModule,
        AgGridModule.withComponents(null),
        TypeaheadModule.forRoot(),
        ArchwizardModule,
        NgxMaskModule.forChild(),
        CarouselModule.forRoot(),
        AccordionModule.forRoot(),
        SelectDropDownModule,
        BreadcrumbModule,
        NgxMarkjsModule,
        UsersRoutingModule,
        UsersModule,
        VerificationDescriptionAddModule,
        VerificationDescriptionUpdateModule,
        FaqListModule,
        FaqManagementModule,
        GuidesManagementModule,
        GridPaginationModule,
        SharedModule,
        RequestTableModule,
        RequestVerificationStatusGridModule,
        AppSharedModule,
        CreateOrganisationModule,
        ReferencesListComponent,
        CustomFormComponent,
        FormGroupComponent,
        EmailInputComponent,
        LanguageDropdownInputComponent,
        NameInputComponent,
        PhoneInputComponent,
        StandardInputComponent,
        BooleanInputComponent,
        SingleSelectionListComponent,
        MultipleSelectionListComponent,
        TextAreaInputComponent,
        CustomFormQuestionComponent
    ]
})
export class AppModule {}
