import { Component, Input } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AbstractControl } from '@angular/forms';

class ErrorDef {
    localizationKey: string;
    errorProperty?: string;
    type?: ErrorType;
    properties?: any;
}

enum ErrorType {
    Pattern
}

@Component({
    selector: '<validation-messages>',
    template: `
        <div class="has-danger" *ngIf="formCtrl?.invalid && (formCtrl.dirty || formCtrl.touched)">
            <div *ngFor="let error of errors">
                <div class="form-control-feedback">
                    {{ error }}
                </div>
            </div>
        </div>
    `
})
export class ValidationMessagesComponent {
    @Input() protected readonly formCtrl: AbstractControl;

    protected readonly standardErrorDefs: { [key: string]: ErrorDef } = {
        required: { localizationKey: 'ThisFieldIsRequired' },
        minlength: { localizationKey: 'PleaseEnterAtLeastNCharacter', errorProperty: 'requiredLength' },
        maxlength: { localizationKey: 'PleaseEnterNoMoreThanNCharacter', errorProperty: 'requiredLength' },
        email: { localizationKey: 'InvalidEmailAddress', type: ErrorType.Pattern },
        BadSocialInsuranceNumber: { localizationKey: 'InvalidSocialInsuranceNumber', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        BadPhoneNumber: { localizationKey: 'InvalidPhoneNumber', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        BadEmailAddress: { localizationKey: 'InvalidEmailAddress', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        BadPostalCode: { localizationKey: 'InvalidPostalCode', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        BadExpirationDate: { localizationKey: 'InvalidExpirationDate', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        BadCreditCard: { localizationKey: 'InvalidCreditCard', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        UserNameAlreadyInUse: { localizationKey: 'UserNameAlreadyInUse' },
        atLeastOneRequired: { localizationKey: 'Forms.ThisFieldIsPotentiallyRequired' },
        mustChooseAtLeast: { localizationKey: 'Forms.MustChooseAtLeastOne', errorProperty: 'minimum' },
        mask: { localizationKey: 'Forms.InvalidFormat', errorProperty: 'requiredMask', type: ErrorType.Pattern },
        pattern: { localizationKey: 'Forms.InvalidFormat', errorProperty: 'requiredPattern', type: ErrorType.Pattern },
        unknown: { localizationKey: 'Invalid' }
    };

    constructor(private readonly appLocalizationService: AppLocalizationService) {}

    protected get errors(): string[] {
        if (!this.formCtrl.errors) {
            return [];
        }

        // Prevent showing two messages for the same reason.
        // This happens when we use a custom Validator, a regex pattern and/or a Mask at the same time for example.
        const displayedErrorTypes = new Set<ErrorType>();

        return Object.entries(this.formCtrl.errors)
            .map(([key, value]) => {
                const errorDef = this.standardErrorDefs[key] ?? this.standardErrorDefs['unknown'];
                errorDef.properties = value;
                return errorDef;
            })
            .filter((errorDef) => {
                if (errorDef.type && displayedErrorTypes.has(errorDef.type)) {
                    return false;
                }

                const hasLocalizationKey = !!errorDef?.localizationKey;

                if (hasLocalizationKey && errorDef.type) {
                    displayedErrorTypes.add(errorDef.type);
                }

                return hasLocalizationKey;
            })
            .map((error) => this.getErrorMessage(error));
    }

    protected getErrorMessage(errorDef: ErrorDef): string {
        const localizationKey = errorDef.localizationKey;

        if (errorDef.errorProperty) {
            return this.appLocalizationService.l(localizationKey, errorDef.properties[errorDef.errorProperty]);
        }

        return this.appLocalizationService.l(localizationKey);
    }
}
