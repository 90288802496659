<form-group *ngIf="translation && question && parentFormControl"
    [name]="name"
    [label]="questionLabel"
    [parentFormControl]="parentFormControl"
    [startingValue]="answer"
>
    <ng-container [ngSwitch]="question.type">
        <textarea-input #inputControl *ngSwitchCase="FormQuestionType.text" [name]="name"
            [maxLength]="question.maxLength"/>
        <boolean-input #inputControl *ngSwitchCase="FormQuestionType.boolean" [name]="name" [language]="translation.language"/>
        <ng-container *ngSwitchCase="FormQuestionType.list">
            <ng-container *ngIf="!question.isMultiSelection else multi_list">
                <single-selection-list #inputControl [name]="name" [choices]="choices"/>
            </ng-container>
            <ng-template #multi_list>
                <multiple-selection-list #inputControl [choices]="choices" [maxSelections]="options?.multiSelectionMax"/>
            </ng-template>
        </ng-container>
        <standard-input #inputControl *ngSwitchCase="FormQuestionType.standard" [name]="name" [type]="htmlType" [options]="options"/>
        <standard-input #inputControl *ngSwitchDefault [name]="name" [type]="htmlType" [options]="options"/>
    </ng-container>
</form-group>
