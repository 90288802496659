import { Component, Injector, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { DatePipe } from '@angular/common';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { FormGroup, FormControl } from '@angular/forms';
import { RequestStatusArrayObject } from '@shared/models/request/requestStatusArrayObject';
import { Router } from '@angular/router';
import { RequestTableComponent } from '../request-table/request-table.component';
import { Subscription } from 'rxjs';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';
import { GridConfigurationChangedEvent } from '@shared/models/shared/gridConfigurationChangedEvent';

/**
 * The request page.
 */
@Component({
    selector: 'request-page',
    templateUrl: './request-page.component.html',
    animations: [appModuleAnimation()],
    providers: [DatePipe],
})
export class RequestPageComponent extends AppComponentBase implements AfterViewInit, OnDestroy {
    @ViewChild(RequestTableComponent) gridTableComponent: RequestTableComponent;
    private subscriptions = new Subscription();
    protected filterText = '';
    protected advancedSearchFormGroup: FormGroup;
    protected statusDropdownConfig: any;
    protected statusDropdownOptions: RequestStatusArrayObject[];

    constructor(injector: Injector, private readonly _router: Router) {
        super(injector);

        this.advancedSearchFormGroup = new FormGroup({
            requestStatus: new FormControl<RequestStatusArrayObject[]>([], {nonNullable: true}),
            onlyMyRequests: new FormControl<boolean>(false, {nonNullable: true}),
            startCreationTimeBetween: new FormControl<Date | null>(null),
            endCreationTimeBetween: new FormControl<Date | null>(null),
            organizationName: new FormControl<string>(''),
            verificationIds: new FormControl<string[]>([], {nonNullable: true})
        });

        this.statusDropdownOptions = RequestStatusLegend.getAllLegends()
            .filter((value) => value.id !== RequestStatus.Draft || this.appAuthService.hasPermission('Pages.Management.Requests.Create.Wizzard'))
            .map((status) => ({
                id: status.id,
                description: this.localization.l(status.name)
            } as RequestStatusArrayObject));

        this.statusDropdownConfig = {
            displayKey: 'description',
            search: false,
            height: '1000px',
            placeholder: this.localization.l('SelectAll'),
            moreText: this.localization.l('More')
        };
    }

    ngAfterViewInit(): void {
        this.subscriptions.add(this.gridTableComponent?.gridConfigurationChanged.subscribe(this.setupSettingsFromTable.bind(this)));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    // If a user can filter or not by assignments
    protected get canSeeAssignments(): boolean {
        return this.isGranted('Pages.Management.Requests.CanSeePrivateInformation');
    }

    // Submits the advanced search to the table.
    protected submitAdvancedSearch(): void {
        this.gridTableComponent.filter({
            requestStatus: this.advancedSearchFormGroup.controls['requestStatus'].value?.map(s => s.id as RequestStatus),
            assignedUsername: this.advancedSearchFormGroup.controls['onlyMyRequests'].value ? this.appAuthService.user?.name : undefined,
            to: this.advancedSearchFormGroup.controls['endCreationTimeBetween'].value,
            from: this.advancedSearchFormGroup.controls['startCreationTimeBetween'].value,
            globalFilter: this.filterText,
            organizationName: this.advancedSearchFormGroup.controls['organizationName'].value,
            verificationIds: this.advancedSearchFormGroup.controls['verificationIds'].value
        });
    }

    /** Reset the advanced search form without touching the global search and refresh the table by
     submitting the empty form. */
    protected refreshAdvancedSearch(): void {
        this.advancedSearchFormGroup.reset();
        this.submitAdvancedSearch();
    }

    /** Reset the global search form without touching the advanced search and refresh the table by
     submitting the empty text. */
    protected refreshGlobalSearch(): void {
        this.filterText = '';
        this.submitAdvancedSearch();
    }

    // Navigate to the request wizard page
    protected createRequest(): void {
        this._router.navigate(['/requests-wizard', '']);
    }

    // Completely reset the filters of the table
    protected resetFilters(): void {
        this.gridTableComponent.resetFilters();
    }

    // Reset the sorting of the table
    protected resetSort(): void {
        this.gridTableComponent.resetSorting();
    }

    // Reset the visual configurations of the columns in the table
    protected resetColumns(): void {
        this.gridTableComponent.resetColumns();
    }

    // Setups the form with the value initialized in the table
    private setupSettingsFromTable(event: GridConfigurationChangedEvent): void {
        if (!event.filterModel) return;

        const filters = event.filterModel;

        this.advancedSearchFormGroup.controls['requestStatus'].setValue(
            filters.status?.filter.split(';').map(s =>
                this.statusDropdownOptions.find(st => st.id.toString() === s)
            )
        );
        this.advancedSearchFormGroup.controls['onlyMyRequests'].setValue(filters.assignedUserName != null);
        this.advancedSearchFormGroup.controls['startCreationTimeBetween'].setValue(filters.creationTime?.dateFrom);
        this.advancedSearchFormGroup.controls['endCreationTimeBetween'].setValue(filters.creationTime?.dateTo);
        this.advancedSearchFormGroup.controls['organizationName'].setValue(filters.organizationName);
        this.advancedSearchFormGroup.controls['verificationIds'].setValue(filters.verifications?.filter.split(';'));
        this.filterText = filters.globalFilter?.filter;
    }
}
