import { VerificationForm } from '@shared/models/custom-form/verificationForm';

export enum ReferenceProgressStatus {
    New = 0,
    InProgress = 1,
    WaitingForValidation = 2,
    Completed = 3,
    CannotBeCompleted = 4
}

interface Reference {
    id?: number;
    firstName: string;
    lastName: string;
    email?: string;
    phoneNumber?: string;
    status: ReferenceProgressStatus;
    language: string;
    creationDate: Date;
    lastUpdateDate: Date;
    lastTimeSentToReference: Date;
    verificationForm: VerificationForm;
    requestVerificationId: number;
    verificationType?: string;
}

export default Reference;
