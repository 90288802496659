import { Component, OnInit, ViewChild, Injector, Output, EventEmitter, HostListener } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestService } from '@shared/services/requests.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ValueFormatterParams, RowSelectedEvent } from '@ag-grid-enterprise/all-modules';
import { organizationOutput } from '@shared/models/organization/organizationOutput';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';
import { Extensions } from '@shared/utils/extensions';
import { WizardRequestGridOutput } from '@shared/models/request/wizardRequestGridOutput';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';

@Component({
    selector: 'wizzard-requests',
    templateUrl: './wizzard-requests.component.html',
    providers: [DatePipe]
})
export class WizzardRequestsComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @Output() requestsSelected = new EventEmitter<number>();
    // private gridOptions:GridOptions;
    private numberOfRequestSelected = 0;
    private gridApi;
    public columnDefs;
    paginationNumberFormatter;
    paginationPageSize = 20;
    autoGroupColumnDef;

    //nombre max de requetes par lot
    maxLot = 500;

    filterText = '';
    rowData: WizardRequestGridOutput[];
    indexCount = 0;
    rowSelection: string;
    rowMultiSelectWithClick: boolean;
    selectedOrganizations: organizationOutput[];
    requestOutputList: WizardRequestGridOutput[];
    temp: WizardRequestGridOutput[];

    groupIndexCount = 1;
    organizationName: string;

    maxNextLot: number;
    organizationGroup: WizardRequestGridOutput[];

    constructor(
        private readonly injector: Injector,
        private readonly service: RequestService,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly datePipe: DatePipe,
        private readonly _dateTimeService: DateTimeService
    ) {
        super(injector);
        this.rowSelection = 'multiple';

        this.rowMultiSelectWithClick = true;

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    ngOnInit() {
        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('group'),
                field: 'groupIndex',
                sortable: true,
                filter: 'text',
                resizable: true,
                flex: 2,
                hide: true,
                rowGroup: true
            },
            {
                headerName: this._appLocalizationService.l('OrganizationName'),
                field: 'organizationName',
                sortable: true,
                filter: 'text',
                resizable: true,
                flex: 2,
                rowGroup: true,
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                comparator: Extensions.customComparator
            },
            {
                headerName: this._appLocalizationService.l('CandidateName'),
                field: 'candidateName',
                sortable: true,
                filter: 'text',
                resizable: true,
                flex: 2,
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                comparator: Extensions.customComparator
            },

            {
                headerName: this._appLocalizationService.l('ClientId'),
                field: 'clientId',
                sortable: true,
                filter: 'text',
                resizable: true,
                flex: 1
            },
            {
                headerName: this._appLocalizationService.l('Status'),
                field: 'status',
                sortable: true,
                filter: true,
                resizable: true,
                flex: 1,
                valueFormatter: this.statusGetter.bind(this),
                getQuickFilterText: (params) => Extensions.replaceAccentedCharacters(this.statusGetter.bind(this, params))
            },
            {
                headerName: this._appLocalizationService.l('CreatedBy'),
                field: 'createdBy',
                sortable: true,
                filter: 'text',
                resizable: true,
                flex: 1,
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this)
            },
            {
                headerName: this._appLocalizationService.l('CreationTime'),
                field: 'creationTime',
                sortable: true,
                sort: 'desc',
                resizable: true,
                flex: 2,
                valueFormatter: this.dateFormater.bind(this),
                filterParams: this.getAgGridDefaultDateFilterParams,
                getQuickFilterText: this.dateFormater.bind(this),
                filter: 'agDateColumnFilter'
            }
        ];

        this.autoGroupColumnDef = {
            headerName: 'Lots',
            field: '',
            minWidth: 250,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: { checkbox: true }
        };

        this.requestsSelected.emit(this.numberOfRequestSelected);
    }

    dateFormater(params: ValueFormatterParams) {
        if (!params.value) {
            return '';
        }
        return this.datePipe.transform(this._dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat);
    }

    async getRequestListForInvoices() {
        this.rowData = [];
        // provide a list of publicIds and get a list of organizationRequestByPublicId in 1 call
        const organizationPublicIds: string[] = this.selectedOrganizations.map((x) => x.publicId);

        await this.service
            .getRequestsWithNoInvoiceByOrganizationPublicIds(organizationPublicIds)
            .toPromise()
            .then((response) => {
                if (response.success) {
                    this.rowData = response.result;
                }
            });
        //filter out drafts and canceled requests
        this.rowData = this.rowData.filter((x) => x.status !== 1 && x.status !== 7);
        //create groups of 500 requests
        if (this.rowData.length === 0) {
            return;
        }

        this.rowData[0].groupIndex = 1;
        this.organizationName = this.rowData[0].organizationName;
        this.rowData.forEach((element) => {
            this.indexCount += 1;
            element.index = this.indexCount;
            element.groupIndex = this.groupIndexCount;
            this.organizationGroup = this.rowData.filter((x) => x.organizationName === element.organizationName);

            //check if organization request needs to be in a new lot (in order to be all in the same lot - max 500)
            //change of organisation
            if (element.organizationName !== this.organizationName) {
                this.maxNextLot = element.groupIndex * this.maxLot;
                this.organizationName = element.organizationName;

                //check if number of requests for this organization can be in 1 lot of max 500 -- force a new group -- we Don't want to split the lot
                if (
                    this.organizationGroup.length > this.maxNextLot - element.index ||
                    this.organizationGroup.length > this.maxLot ||
                    this.rowData.filter((x) => x.groupIndex === element.groupIndex).length > this.maxLot
                ) {
                    this.groupIndexCount += 1;
                    element.groupIndex = this.groupIndexCount;
                }
            }

            //same organisation as last element
            else {
                if (element.index !== this.maxLot && Number.isInteger(element.index / this.maxLot) && this.organizationGroup.length < this.maxLot) {
                    this.groupIndexCount += 1;
                    element.groupIndex = this.groupIndexCount;
                } else if (
                    element.index !== this.maxLot &&
                    this.rowData.filter((x) => x.groupIndex === element.groupIndex).length > this.maxLot &&
                    this.organizationName !== element.organizationName
                ) {
                    this.groupIndexCount += 1;
                    element.groupIndex = this.groupIndexCount;
                    this.organizationName = element.organizationName;
                } else {
                    element.groupIndex = this.groupIndexCount;
                }
            }
        });
    }

    statusGetter(params: ValueFormatterParams): string {
        if (params.value != null) {
            const status = RequestStatusLegend.getLegendFromId(params.value)?.name;
            return this._appLocalizationService.l(status);
        }
    }

    onPageSizeChanged(e) {
        this.gridApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    onGridReady(params) {
        this.gridApi = params.api;
        if (screen.availWidth > 414 && this.gridApi.width > 0) {
            this.gridApi.sizeColumnsToFit();
        }
    }

    refresh() {
        this.filterText = '';
        this.agGrid.api.setQuickFilter('');
    }

    search() {
        const filterWithoutAccents = Extensions.replaceAccentedCharacters(this.filterText);
        this.agGrid.api.setQuickFilter(filterWithoutAccents);
    }

    onRequestSelected(event: RowSelectedEvent) {
        if (event.node.isSelected()) {
            this.numberOfRequestSelected++;
        } else {
            this.numberOfRequestSelected--;
        }

        this.requestsSelected.emit(this.numberOfRequestSelected);
    }

    getSelectedRequests() {
        this.requestOutputList = [];
        const selectedNodes = this.gridApi.getSelectedNodes();
        selectedNodes.forEach((groupNode) => {
            groupNode.childrenAfterFilter.forEach((childNode) => {
                if (childNode !== undefined) {
                    if (childNode.data === undefined) {
                        childNode.childrenAfterFilter.forEach((childChildNode) => {
                            this.requestOutputList.push(childChildNode.data);
                        });
                    } else {
                        this.requestOutputList.push(childNode.data);
                    }
                }
            });
        });
        return this.requestOutputList;
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridApi) {
            if (screen.availWidth > 414 && this.gridApi.width > 0) {
                this.gridApi.sizeColumnsToFit();
            }
        }
    }

    private readonly getAgGridDefaultDateFilterParams = (): any => ({
        comparator: function (filterLocalDateAtMidnight, cellValue) {
            const dateAsString = cellValue;

            if (dateAsString === null) {
                return 0;
            }

            // In the example application, dates are stored as dd/mm/yyyy
            // We create a Date object for comparison against the filter date
            const dateParts = dateAsString.split('T')[0].split('-');
            const day = Number(dateParts[2]);
            const month = Number(dateParts[1]) - 1;
            const year = Number(dateParts[0]);
            const cellDate = new Date(year, month, day);

            // Now that both parameters are Date objects, we can compare
            if (cellDate < filterLocalDateAtMidnight) {
                return -1;
            } else if (cellDate > filterLocalDateAtMidnight) {
                return 1;
            } else {
                return 0;
            }
        }
    });
}
