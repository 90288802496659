import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { DropdownChoice } from '@shared/models/dropdownChoice';
import { UtilsModule } from '@shared/utils/utils.module';

/**
 * This component is used to display a list of choices in a multiple selection list.
 * @params *__choices__* - The choices to be displayed see {@link DropdownChoice }, can also be strings.
 * @params *__questionControl__* - The form control connected to the question. If used inside a {@link FormGroupComponent} the control will be added automatically.
 * @params *__maxSelections__* - *(Optional)* The maximum number of selections allowed. If null, there is no maximum.
 */
@Component({
    standalone: true,
    selector: 'multiple-selection-list[choices]',
    imports: [ReactiveFormsModule, NgForOf, NgIf, UtilsModule],
    templateUrl: './multiple-selection-list.component.html'
})
export class MultipleSelectionListComponent implements OnInit, OnChanges {
    @Input() protected readonly questionControl: FormControl;
    @Input() private readonly choices: DropdownChoice[] | string[] = [];
    @Input() protected readonly maxSelections: number | null = null;
    protected values: DropdownChoice[] = [];

    constructor(
        private readonly cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.values =
            typeof this.choices[0] === 'string'
                ? this.choices.map(
                    (choice) =>
                    ({
                        value: choice,
                        display: choice
                    } as DropdownChoice)
                )
                : (this.choices as DropdownChoice[]);
    }


    ngOnChanges(changes: SimpleChanges): void {
        if (changes['choices'] && changes['choices'].currentValue) {
            const isDropDownChoices = typeof this.choices[0] === 'object' && 'value' in this.choices[0] && 'display' in this.choices[0];
            const newValues = this.choices.map(choice => {
                if (isDropDownChoices) {
                    return { ...choice };
                } else {
                    return { value: choice, display: choice };
                }
            });

            if (this.values?.length == 0) {
                this.values = newValues;
                return;
            }
            
            if (isDropDownChoices) {
                // Update the display value of the existing values 
                // if it is DropdownChoices Array
                this.values.forEach((value) => {
                    const newValue = newValues.find((newValue) => newValue.value === value.value);
                    if (newValue) {
                        value.display = newValue.display;
                    }
                });
            }
            else {
                // If it is a string array then we need to update the value and the display 
                // And update the questionControl value
                this.questionControl.setValue(newValues.map((newValue) => newValue.value));
                for (let i = 0; i < this.values.length; i++) {
                    const value = this.values[i];
                    const newValue = newValues.find((newValue) => newValue.value === value.value);
                    if (newValue) {
                        value.display = newValue.display;
                        value.value = newValue.value;
                    }
                }
            }
            this.cdr.detectChanges();
        }
    }

    protected get maxSelectionsReached(): boolean {
        return this.maxSelections !== null && (this.questionControl?.value?.length || 0) >= this.maxSelections;
    }

    protected choiceIsChecked(choice: string): boolean {
        return this.questionControl?.value?.includes(choice) || false;
    }

    protected onMultiSelectionChange(choice: string, event: any) {
        const selectedOptions = this.questionControl.value || [];

        if (event.target.checked) {
            this.questionControl.setValue([...selectedOptions, choice]);
        } else {
            const index = selectedOptions.indexOf(choice);
            if (index > -1) {
                selectedOptions.splice(index, 1);
                this.questionControl.setValue([...selectedOptions]);
            }
        }
    }
}
