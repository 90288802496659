import { AppComponentBase } from '@shared/common/app-component-base';
import { Component, OnInit, Injector, ViewChild, HostListener } from '@angular/core';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RequestService } from '@shared/services/requests.service';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { CandidatsDetailsComponent } from '../requests-informations/candidats-details/candidats-details.component';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { ToastrService } from 'ngx-toastr';
import { EmailSenderResult, EmailResult } from '@shared/models/shared/emailSenderResult';
import { AddressHistoryComponent } from '../requests-informations/address-history/address-history-current/address-history-current.component';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';
import { RequiredFieldsForCodes } from '@shared/utils/verificationsEnumAndFunctions';
import { AddressHistoryService } from '@shared/services/addressHistory.service';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { GridApi, ColDef } from '@ag-grid-enterprise/all-modules';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { RequestVerificationStatus } from '@shared/models/requestVerification/requestVerificationStatus';
import { RequestVerificationUpdateComponent } from '../request-verification-update/request-verification-update.component';
import { NoteDetailsComponent } from '../requests-informations/notes/notes-details/notes-details.component';
import { NotesService } from '@shared/services/note.service';
import { MenuItem } from 'primeng/api';
import { RequestverificationQuantityConfirmationComponent } from '../requestverification-quantity-confirmation/requestverification-quantity-confirmation.component';
import { InvoiceStatusEnum } from '@shared/models/InvoiceElements/invoice/invoiceStatus';
import { resultsCodes } from '@shared/models/requestVerification/requestVerificationResults';
import { SendingMethodType } from '@shared/models/request/sendingMethodType';
import { BillingPeriodTypeDTO } from '@shared/models/organization/billingInformation/BillingPeriodTypeDTO';
import { BinaryResponse } from '@shared/models/boolean-string.enum';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';
import {AppAuthService} from "@app/shared/common/auth/app-auth.service";
import { DialogService } from '@shared/services/dialog-service';
import {fileType} from "@shared/models/files/fileType";

@Component({
    selector: 'request-details',
    templateUrl: './request-details.component.html',
    styleUrls: ['./request-details.component.scss'],
    animations: [appModuleAnimation()]
})
export class RequestDetailsComponent extends AppComponentBase implements OnInit {
    @ViewChild('candidatsDetails', { static: true }) candidatsDetails: CandidatsDetailsComponent;
    @ViewChild('candidatsAddress') candidatsAddress: AddressHistoryComponent;
    @ViewChild('notesDetailsModal') notesDetailsModal: NoteDetailsComponent;
    fileTypeEnum = fileType;
    canSeeReports = false;
    paginationNumberFormatter;
    paginationPageSize = 20;
    isCancelled = true;
    request: RequestOutput;
    publicId: string;
    selectedCandidat: CandidatOutput;
    requestOpen: boolean;
    informationSource: InformationSourceDTO = InformationSourceDTO.Candidate;
    hasCandidatEmail: boolean;
    protected showAddressList = false;
    protected showReferenceList = false;
    protected showAccreditationList = false;
    protected showAcademicRecordList = false;
    isUserAllowToGenerateInvoice = false;
    shouldShowInvoiceStatus = false;
    currentLanguage: any;
    canSendInfoToGardium = false;
    containsDJI = false;
    containsADD = false;
    isInvoiceIdNull = true;
    protected isAllowedToUpdateRequest = false;
    items: MenuItem[];
    formGroup = new UntypedFormGroup({
        organization: new UntypedFormControl({ value: '', disabled: true }),
        candidat: new UntypedFormControl({ value: '', disabled: true }),
        status: new UntypedFormControl({ value: '', disabled: true }),
        user: new UntypedFormControl({ value: '', disabled: true }),
        userEmail: new UntypedFormControl({ value: '', disabled: true }),
        userNameCreator: new UntypedFormControl({ value: '', disabled: true }),
        additionalContact: new UntypedFormControl({ value: '', disabled: true }),
        organizationClientId: new UntypedFormControl({ value: '', disabled: true }),
        termsAndAccept: new UntypedFormControl({ value: '', disabled: true }),
        reportLanguage: new UntypedFormControl({ value: '', disabled: true }),
        invoiceStatus: new UntypedFormControl({ value: '', disabled: true }),
        purchaseOrder: new UntypedFormControl({ value: '', disabled: true }),
        billingPeriod: new UntypedFormControl({ value: '', disabled: true }),
        primaryContact: new UntypedFormControl({ value: '', disabled: true }),
    });
    statusOptions = RequestStatusLegend.getAllLegends();

    // Verifications
    gridRequestVerificationApi: GridApi;
    columnDefsRequestVerifications: ColDef[] = [];
    defaultColDefRequestVerifications: ColDef;
    requestVerifications: RequestVerificationOutput[] = [];
    isAgentPPO: boolean;
    // Modal
    modalRef: BsModalRef;

    constructor(
        injector: Injector,
        private readonly _route: ActivatedRoute,
        private readonly _service: RequestService,
        private readonly _notesService: NotesService,
        private readonly _addressHistoryService: AddressHistoryService,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly toastr: ToastrService,
        private readonly modalService: BsModalService,
        private readonly _router: Router,
        private readonly _appAuthService: AppAuthService,
        private readonly dialogueService: DialogService
    ) {
        super(injector);

        this.defaultColDefRequestVerifications = {
            resizable: true,
            sortable: true,
            filter: true
        };

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    // Events

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.gridRequestVerificationApi) {
            this.gridRequestVerificationApi.sizeColumnsToFit();
        }
    }

    ngOnInit() {
        if (this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');

            this.items = [
                {
                    label: this._appLocalizationService.l('Requests'),
                    routerLink: ['/requests']
                },
                { label: this._appLocalizationService.l('RequestDetails') }
            ];
            this.loadRequest();
        }

        this.isUserAllowToGenerateInvoice = this.isGranted('Pages.Administration.Invoices.Generate');
        if (this._route.snapshot.fragment) {
            this.requestEmailOpenNotes();
        }

        this.currentLanguage = this._appLocalizationService.currentLanguage.displayName;
        this.setIsAgentPPO();
    }

    setIsAgentPPO() {
        this.isAgentPPO = this._appAuthService.hasRole('AgentPPO');
    }

    validateAssignmentAndNotes(request: RequestOutput) {
        /* Will display a "note added warning" IF
           1. The user can see private info i.e is a Gardium Agent
           2. There is at least one note that wasn't created automatically
        */
        if (this.request && !this.request.assignedUserName && this.isGranted('Pages.Management.Requests.CanSeePrivateInformation')) {
            this._notesService.getNotesForRequest(request.publicId).subscribe(
                (result) => {
                    const notes = result.result;
                    if (notes && notes.length > 0) {
                        const hasUserMadeNote: boolean = notes?.some(note => !note.isAutomatic);
                        if (hasUserMadeNote) {
                            this.dialogueService.warn(this._appLocalizationService.l('RequestNotesAddedWarning'));
                        }
                    }
                },
                (error) => {
                    this.notify.error('Server error: ' + error.error.result);
                }
            );
        }
    }

    onPageSizeChanged(e) {
        this.gridRequestVerificationApi.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    isRequestCancelled() {
        this.isCancelled = this.request.status === RequestStatus.Canceled;
    }

    isRequestEzSignMethod(): boolean {
        return this.request.sendingMethod === SendingMethodType.EzSign;
    }

    isRequestInProgressGardium() {
        return this.request.status === RequestStatus.InProgressGardium;
    }

    isRequestCanBeSentToPpo() {
        return this.isRequestInProgressGardium() && this.isRequestHasCIPC() && this._appAuthService.userCanSendToPPO();
    }

    isRequestHasCIPC() {
        return this.request.requestVerifications.filter(el => el.verification.code === 'CIPC' || el.verification.code === 'CIPC+').length != 0;
    }

    canSeeSectionReports() {
        this.canSeeReports =
            this.isGranted('Pages.Management.Requests.CanUpdateReportLanguage') &&
            (this.isGranted('Pages.Management.Requests.CanAddReports') ||
                (!this.isGranted('Pages.Management.Requests.CanAddReports') &&
                    (this.request.status === RequestStatus.Completed ||
                        this.request.status === RequestStatus.MissingInformations ||
                        this.request.status === RequestStatus.InProgressGardium ||
                        this.request.status === RequestStatus.CantBeCompleted)) ||
                this.getAtLeastOneVerifRequestComplete());
    }

    getAtLeastOneVerifRequestComplete(): boolean {
        return this.request.requestVerifications.some((requestVerificationOutput) => requestVerificationOutput.status === RequestVerificationStatus.Completed);
    }

    loadRequest() {
        this._service.getRequest(this.publicId).subscribe(
            (response) => {
                this.request = response;
                this.selectedCandidat = this.request.candidat;
                this.requestOpen = this.request.status !== RequestStatus.Completed;
                this.checkInvoiceId();

                if (!this.request) {
                    return;
                }

                this.initializeColumns();
                this.checkRequestVerifications();
                this.checkCandidatEmail();
                this.checkInformationSource();
                this.checkCanSendInfoToGardium();
                this.tabsToShow();
                this.initializeRequestBaseInformation();
                this.disableCandidateControls();
                this.patchFormGroupCandidate();
                this.initCurrentAddress();
                this.isAllowedToUpdateRequest = this.getUserUpdatePermission();
                this.canSeeSectionReports();
                this.isRequestCancelled();

                this.validateAssignmentAndNotes(this.request);
            },
            (err) => {
                this.notify.error('Server error: ' + err.error.result);
            }
        );
    }

    initColumns() {
        this.columnDefsRequestVerifications = [
            {
                headerName: this.l('Code'),
                valueGetter: (params) => params.data.verification.code,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Type'),
                valueGetter: (params) => params.data.verification.type,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Status'),
                field: 'status',
                valueFormatter: (params) => this.l(RequestVerificationStatus[params.value]),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('Quantity'),
                field: 'quantity',
                valueFormatter: (params) => params.data.quantity,
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            },
            {
                headerName: this.l('VerificationExpressService'),
                field: 'verificationExpressService',
                valueFormatter: (params) => {
                    const isExpress = params.data.isExpress as boolean;
                    const canBeExpressValue = BinaryResponse[isExpress ? 'Yes' : 'No'];
                    const canBeExpress = params.data.verification.canBeExpress;
                    const translation = canBeExpress ? canBeExpressValue : 'NonEligible';

                    return this.l(translation);
                },
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            }
        ];

        if (this.isGranted('Pages.Management.Requests.CanUpdateReportLanguage')) {
            this.columnDefsRequestVerifications.push({
                headerName: this.l('Result'),
                field: 'result',
                valueFormatter: (params) => this.l(resultsCodes[params.data.result]),
                onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
            });
        }
    }

    addDJIColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: this.l('Country'),
            field: 'country.countryName',
            valueFormatter: (params) => params.data.verification.countryId,
            onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
        });
    }

    addADDColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: this.l('Service'),
            field: 'verificationCustomField',
            valueFormatter: (params) => params.data.verificationCustomField,
            onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
        });
    }

    addPriceColumn() {
        this.columnDefsRequestVerifications.push({
            headerName: this.l('CustomPrice'),
            field: 'verificationCustomPrice',
            valueGetter: this.totalGetter,
            onCellClicked: (params) => this.onRequestVerificationRowClicked(params)
        });
    }

    totalGetter(params) {
        if (params.data.verificationCustomPrice != null) {
            const customPrice = parseFloat(params.data.verificationCustomPrice);
            return customPrice.toFixed(2) + ' $';
        }
    }

    async requestEmailOpenNotes() {
        const noteId = +this._route.snapshot.fragment;
        await this._notesService
            .getNoteById(noteId)
            .toPromise()
            .then((noteResponse) => {
                this.notesDetailsModal.note = noteResponse.result;
                this.notesDetailsModal.show();
            });
    }

    initializeRequestBaseInformation() {
        this.formGroup.controls['user'].setValue(this.request.assignedUserName);
        this.formGroup.controls['userEmail'].setValue(this.request.assignedUserEmail);
        this.formGroup.controls['userNameCreator'].setValue(this.request.userNameCreator);
        this.formGroup.controls['primaryContact'].setValue(this.request.primaryContactName);
        this.formGroup.controls['additionalContact'].setValue(this.request.additionalContactName);
        this.formGroup.controls['organization'].setValue(this.request.organization.organizationName);
        this.formGroup.controls['organizationClientId'].setValue(this.request.organization.clientId);
        this.formGroup.controls['status'].setValue(this.request.status);
        this.initializeInvoiceStatusControl();
        this.formGroup.controls['candidat'].setValue(this.request.candidat.firstName + ' ' + this.request.candidat.lastName);
        this.formGroup.controls['reportLanguage'].setValue(this.request.reportLanguage);
        this.formGroup.controls['purchaseOrder'].setValue(this.request.purchaseOrder);
        this.formGroup.controls['billingPeriod'].setValue(this.l(BillingPeriodTypeDTO[this.request.organization.billingInfo.billingPeriod]));
    }

    initializeInvoiceStatusControl() {
        this.shouldShowInvoiceStatus = this.request.invoiceStatus != null;
        if (this.shouldShowInvoiceStatus) {
            this.formGroup.controls['invoiceStatus'].setValue(this.l(InvoiceStatusEnum[this.request.invoiceStatus]));
        }
    }

    initCurrentAddress() {
        if (!this.selectedCandidat) {
            return;
        }
        this._addressHistoryService
            .getAddressHistoryByCandidat(this.selectedCandidat.publicId)
            .toPromise()
            .then((response) => {
                this.initCurrentAddressHistory(response.result);
            });
    }

    initCurrentAddressHistory(addressHistories: AddressHistoryOutput[]) {
        addressHistories.forEach((element) => {
            if (element.type === AddressHistoryType.current) {
                this.candidatsAddress.formGroupAddress.controls['type'].setValue(element.type);
                this.candidatsAddress.formGroupAddress.controls['fromDate'].setValue(element.from);
                this.candidatsAddress.formGroupAddress.controls['civicNo'].setValue(element.address.civicNo);
                this.candidatsAddress.formGroupAddress.controls['street'].setValue(element.address.street);
                this.candidatsAddress.formGroupAddress.controls['app'].setValue(element.address.app);
                this.candidatsAddress.formGroupAddress.controls['city'].setValue(element.address.city);
                this.candidatsAddress.formGroupAddress.controls['country'].setValue(element.address.country);
                this.candidatsAddress.formGroupAddress.controls['state'].setValue(element.address.state);
                this.candidatsAddress.formGroupAddress.controls['countryName'].setValue(element.address.country.countryName);
                this.candidatsAddress.formGroupAddress.controls['stateName'].setValue(element.address.state.stateName);
                this.candidatsAddress.formGroupAddress.controls['postalCode'].setValue(element.address.postalCode);
            }
        });
    }

    tabsToShow() {
        for (const verificationCode of this.requestVerifications.map((rv) => rv.verification.code)) {
            if (RequiredFieldsForCodes.isMoreThanOneAddressFormerRequired(verificationCode)) {
                this.showAddressList = true;
            }
            if (RequiredFieldsForCodes.isReferenceRequired(verificationCode)) {
                this.showReferenceList = true;
            }
            if (RequiredFieldsForCodes.isProfessionnalAccreditationsRequired(verificationCode)) {
                this.showAccreditationList = true;
            }
            if (RequiredFieldsForCodes.isAcademicRecordsRequired(verificationCode)) {
                this.showAcademicRecordList = true;
            }
            this.candidatsDetails.formGroupCandidate.controls['driverLicenceNumber'].updateValueAndValidity();
            this.candidatsDetails.formGroupCandidate.controls['socialInsuranceNumber'].updateValueAndValidity();
        }
    }

    update() {
        this._router.navigate(['/requests-update', this.publicId]);
    }

    getUserUpdatePermission(): boolean {
        return this.request.status === RequestStatus.Completed ? this.isGranted('Pages.Management.Requests.UpdateWhenDone') : this.isGranted('Pages.Management.Requests.Update');
    }

    async updateRequestToNew() {
        await this._service
            .updateRequestToNew(this.request.publicId)
            .toPromise()
            .then((response) => {
                const result = response.result as EmailSenderResult;
                if (result.emailSentResult === EmailResult.sent) {
                    this.showSuccess('RequestSentToGardiumTitle', 'RequestSentToGardiumSuccessfully');
                    this.reload();
                } else {
                    this.showErrorMessage('RequestSentToGardiumTitle', 'RequestSentToGardiumError');
                }
            });
    }

    async generateInvoice() {
        this.confirmRequestVerificationQuantity(this.request);
    }

    confirmRequestVerificationQuantity(request: RequestOutput) {
        const initialState = {
            request: request
        };

        this.modalRef = this.modalService.show(RequestverificationQuantityConfirmationComponent, {
            initialState,
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static'
        });

        this.modalRef.content.modalSave.subscribe((result) => {
            this.reload();
        });
    }

    async reload() {
        this.loadRequest();
    }

    showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this.toastr.success(successMessage, title);
    }

    showErrorMessage(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this.toastr.error(errorMessage, title);
    }

    // Request Verification

    onRequestVerificationGridReady(params) {
        this.gridRequestVerificationApi = params.api;
        this.gridRequestVerificationApi.sizeColumnsToFit();
    }

    onRequestVerificationRowClicked(params) {
        const initialState = {
            requestVerification: params.data,
            isReadOnly: true,
            request: this.request,
            isAgentPpo: this.isAgentPPO
        };

        this.modalRef = this.modalService.show(RequestVerificationUpdateComponent, { initialState, class: 'modal-dialog-centered', backdrop: 'static' });
    }

    refreshVerificationList() {
        this.loadRequest();
    }

    private checkInvoiceId() {
        if (this.request.invoiceId !== 0) {
            this.isInvoiceIdNull = false;
        }
    }

    private initializeColumns() {
        if (this.isAgentPPO) {
            this.requestVerifications = this.request.requestVerifications.filter(el => el.verification.code === 'CIPC' || el.verification.code === 'CIPC+');
        } else {
            this.requestVerifications = this.request.requestVerifications;
        }

        this.initColumns();
    }

    private checkRequestVerifications() {
        this.containsADD = this.checkContainsVerification('ADD');
        this.containsDJI = this.checkContainsVerification('DJI');

        this.checkAddVerification();
        this.checkDjiVerification();
    }

    private checkContainsVerification(code: string) {
        return this.requestVerifications.findIndex((rv) => rv.verification.code === code) !== -1;
    }

    private checkAddVerification() {
        if (this.containsADD) {
            this.addADDColumn();
        }
    }

    private checkDjiVerification() {
        if (this.containsDJI) {
            if (!this.containsADD) {
                this.addPriceColumn();
            }
            this.addDJIColumn();
        }
    }

    private checkCandidatEmail() {
        if (this.selectedCandidat?.email !== '' && this.selectedCandidat?.email !== null && this.selectedCandidat?.email !== undefined) {
            this.hasCandidatEmail = true;
        } else {
            this.hasCandidatEmail = false;
        }
    }

    private checkInformationSource() {
        this.informationSource = this.request.myInformationSource;
    }

    private checkCanSendInfoToGardium() {
        this.canSendInfoToGardium =
            this.request.status === RequestStatus.Canceled ||
            this.request.status === RequestStatus.MissingInformations ||
            this.request.status === RequestStatus.CantBeCompleted ||
            this.request.status === RequestStatus.InProgressClient;
    }

    private disableCandidateControls() {
        this.candidatsDetails.formGroupCandidate.controls['socialInsuranceNumber'].disable();
        this.candidatsDetails.formGroupCandidate.controls['driverLicenceNumber'].disable();
        this.candidatsDetails.formGroupCandidate.controls['email'].disable();
    }

    private patchFormGroupCandidate() {
        this.candidatsDetails.formGroupCandidate.patchValue(this.selectedCandidat);
    }

    async sendToPpo() {
        this._service.sendToPpo(this.request.publicId).subscribe(
            () => {
                this.showSuccess('RequestSendPpo', 'RequestSentToPpoSuccessfully');
                this.reload();
            },
            () => {
                this.showErrorMessage('RequestSendPpo', 'RequestSentToPpoError');
            }
        );
    }
}
