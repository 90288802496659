import { Component, OnInit, ViewChild, ElementRef, Injector, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { AppComponentBase } from '@shared/common/app-component-base';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ToastrService } from 'ngx-toastr';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { fileType } from '@shared/models/files/fileType';
import { BehaviorSubject, Observable } from 'rxjs';
import {RequestFilesService} from "@shared/services/request-files.service";
import {FileValidationResult} from "@shared/models/files/fileValidationResult";
import {IErrorInfo} from "@shared/models/ajax-response";

@Component({
    selector: 'add-request-files',
    templateUrl: './add-request-files.component.html',
    styleUrls: ['./add-request-files.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AddRequestFilesComponent extends AppComponentBase implements OnInit {
    @ViewChild('labelImport') labelImport: ElementRef;
    @ViewChild('addRequestFilesModal') modal: ModalDirective;
    @ViewChild('importFile') importFile: ElementRef;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    @Input() fileType: fileType;
    public saving = false;
    public requestId: number;
    private readonly filesToUploadSubject = new BehaviorSubject<File[]>([]);
    protected formGroupRequest: UntypedFormGroup;
    protected canSave = false;
    protected readonly fileInputAccept : string;
    protected filesToUpload$: Observable<File[]> = this.filesToUploadSubject.asObservable();

    constructor(
        injector: Injector,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly _toastr: ToastrService,
        private readonly _requestFilesService: RequestFilesService
    ) {
        super(injector);
        this.fileInputAccept = this._requestFilesService.getAcceptedFileExtensions().join(',');
    }

    ngOnInit() {
        this.saving = false;
        this.formGroupRequest = new UntypedFormGroup({
            importFile: new UntypedFormControl('', [this.validateExt])
        });
    }

    close(): void {
        this.canSave = false;
        this.modal.hide();
        this.clearFilesToUpload();
    }

    show(): void {
        this.clearFilesToUpload();
        this.formGroupRequest.controls['importFile'].setValue('');
        this.modal.show();
    }

    preventDefault(event: Event) {
        event.preventDefault();
    }

    droppedFile(event: DragEvent) {
        event.preventDefault();
        const files = event.dataTransfer.files;
        if (files.length) {
            this.onFileChange(files);
        }
    }

    onFileChange(files: FileList) {
        this.formGroupRequest.updateValueAndValidity();
        this.canSave = true;
        for (const newFile of Array.from(files)) {
            const validationResult = this._requestFilesService.validateFile(newFile);

            switch(validationResult) {
                case FileValidationResult.InvalidSize:
                    this.showWarning('FileAddIssue', 'SizeFileTooLarge');
                    break;
                case FileValidationResult.InvalidExtension:
                    this.showWarning('FileAddIssue', 'SizeFile');
                    break;
                case FileValidationResult.Valid:
                    this.addFileToUpload(newFile);
            }
        }
        // Clear the input file
        this.importFile.nativeElement.value = '';
    }

    removeFileToUpload(file: File) {
        // Remove from array and update the DOM
        const filesToUpload = this.filesToUploadSubject.getValue();
        const index = filesToUpload.indexOf(file);
        filesToUpload.splice(index, 1);
        this.filesToUploadSubject.next([...filesToUpload]);
        // Update save button visibility state
        this.canSave = filesToUpload.length > 0;
    }

    save() {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showError('BadFormat', 'SizeFile');
            return;
        }
        this.saving = true;
        this.uploadFiles();
    }

    triggerFileInput() {
        this.importFile.nativeElement.click();
    }

    getFormatedFileName(file: File): string {
        const readableSize = this._requestFilesService.getReadableSize(file.size);
        return `${file.name} (${readableSize})`;
    }

    private addFileToUpload(file: File) {
        const filesToUpload = this.filesToUploadSubject.getValue();
        // Check if file already exists
        if (this.fileExists(file, filesToUpload)) {
            this.showWarning('Warning', 'FileExists');
            return;
        }
        // Update the DOM
        const updatedItems = [...filesToUpload, file];
        this.filesToUploadSubject.next(updatedItems);
    }

    private clearFilesToUpload() {
        this.filesToUploadSubject.next([]);
    }

    private uploadFiles() {
        const filesToUpload = this.filesToUploadSubject.getValue();
        this._requestFilesService.uploadFiles(filesToUpload, this.fileType, this.requestId).subscribe({
            next: (_) => {
                this.modalSave.emit(null);
                this.showSuccess('FileAddTitle', 'FileAdded');
                this.saving = false;
                this.modal.hide();
                this.clearFilesToUpload();
            },
            error: (error: IErrorInfo) => {
                console.log('Failed to upload file : ', error.message);
                this.showError('FileAddIssue', 'FileUpdateTryAgainLater');
                this.saving = false;
            }
        });
    }

    private readonly validateExt = (c: UntypedFormControl): { [key: string]: boolean } | null => {
        const fileName = c.value.toString();
        if (fileName.length > 0 && this._requestFilesService.validateFileExtension(fileName)) {
            return null;
        } else {
            return { 'Bad range': true };
        }
    }

    private showSuccess(title: string, successMessage: string) {
        title = this._appLocalizationService.l(title);
        successMessage = this._appLocalizationService.l(successMessage);
        this._toastr.success(successMessage, title);
    }

    private showWarning(title: string, warningMessage: string) {
        title = this._appLocalizationService.l(title);
        warningMessage = this._appLocalizationService.l(warningMessage);
        this._toastr.warning(warningMessage, title);
    }

    private showError(title: string, errorMessage: string) {
        title = this._appLocalizationService.l(title);
        errorMessage = this._appLocalizationService.l(errorMessage);
        this._toastr.error(errorMessage, title);
    }

    private isFormInvalid(): boolean {
        const filesToUpload = this.filesToUploadSubject.getValue();
        return !filesToUpload || filesToUpload.length === 0;
    }

    private markAllControlsAsTouch() {
        this.formGroupRequest.controls['importFile'].markAsTouched();
    }

    private fileExists(fileToFind: File, collection: File[]): boolean {
        return collection.some((existingFile) =>
            existingFile.name === fileToFind.name &&
            existingFile.size === fileToFind.size &&
            existingFile.type === fileToFind.type
        );
    }
}
