import { Directive, AfterViewInit } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import {Status} from '@shared/models/shared/status';

@Directive({
    selector: '[StyleRow]'
})
export class GridStyleRowInactiveDirective implements AfterViewInit {
    constructor(private readonly agGrid: AgGridAngular) {}

    ngAfterViewInit() {
        if (!this.agGrid.gridOptions) {
            this.agGrid.gridOptions = {};
        }
        this.agGrid.gridOptions.getRowStyle = function (params) {
            if (params.data?.status === Status.Inactif) {
                return { background: '#F3F3F3' };
            }
        };
    }
}
