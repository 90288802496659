import { Component, OnInit, ViewChild, Output, EventEmitter, Injector, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { FAQDescriptionInput } from '@shared/models/faq/faqDescriptionInput';
import { FAQDescriptionOutput } from '@shared/models/faq/faqDescriptionOutput';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ModalDirective, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { FaqService } from '@shared/services/faq.service';
import { finalize } from 'rxjs/operators';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CkeditorService } from '@shared/services/ckeditor.service';
import { FAQCategoryOutput } from '@shared/models/faq/faqCategoryOutput';
import { FAQCategoryListOutput } from '@shared/models/faq/faqCategoryListOutput';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-create-or-edit-faq-description-modal',
    templateUrl: './faq-description-create-edit-modal.component.html',
    styleUrls: ['./faq-description-create-edit-modal.component.scss']
})
export class FaqDescriptionCreateEditModalComponent extends AppComponentBase implements OnInit, OnDestroy {
    @ViewChild('FAQDescriptionCreateEditModal') FAQDescriptionCreateEditModal: ModalDirective;
    @Output() modalSave: EventEmitter<any> = new EventEmitter<any>();
    private subscriptions = new Subscription();
    private englishId: number;
    private allLanguagesFaqCategories: Map<number, FAQCategoryOutput[]> = new Map();
    private selectedLanguage: number;
    private canPutOnDashboard: boolean = false;
    private existingCategory: FAQCategoryOutput;
    private existingFaqDescription: FAQDescriptionInput;
    private active = false;
    public Editor = Editor;
    public formGroup: FormGroup;
    public editorConfig: any;
    public saving = false;
    public faqCategories: FAQCategoryOutput[] = [];
    public untranslatedQuestions: FAQDescriptionOutput[];
    public languages;
    public numberOnDashboard: number;
    public maxOnDashboard: number;
    public editing: boolean = false;
    public isEnglish: boolean = false;

    constructor(
        injector: Injector,
        public bsModalRef: BsModalRef,
        private toastr: ToastrService,
        private _service: FaqService,
        private _appLocalizationService: AppLocalizationService,
        private ckeditorService: CkeditorService,
        private _fb: FormBuilder
    ) {
        super(injector);
        this.languages = this._appLocalizationService.languages;
    }

    public ngOnInit() {
        this.editorConfig = this.ckeditorService.getConfig({ mediaEmbedding: true });
        this.formGroup = this._fb.group({
            language: [null, Validators.required],
            category: [{ value: null, disabled: true }, Validators.required],
            translation: [{ value: null, disabled: true }],
            question: ['', Validators.required],
            answer: ['', Validators.required],
            status: [1, Validators.required],
            onDashboard: [{ value: 0, disabled: true }, Validators.required]
        });
        this.subscriptions.add(
            this.formGroup.get('language').valueChanges.subscribe((languageId: number) => {
                if (this.active && this.selectedLanguage !== languageId) {
                    // enable the category dropdown and load the categories for this language
                    this.selectedLanguage = languageId;
                    this.isEnglish = this._appLocalizationService.isEnglish(this.selectedLanguage);
                    this.resetDropdowns(languageId);
                    this.faqCategories = this.allLanguagesFaqCategories.get(this.selectedLanguage);
                }
            })
        );
        this.subscriptions.add(
            this.formGroup.get('category').valueChanges.subscribe((categoryId: string) => {
                if (this.active) {
                    this.resetTranslationDropdown(true, null, categoryId);
                }
            })
        );
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    public show(
        category?: FAQCategoryOutput,
        existingQuestion?: FAQDescriptionOutput,
        allFaqDict?: Map<number, FAQCategoryOutput[]>,
        numberOnDashboard?: number,
        maxOnDashboard?: number
    ): void {
        this.resetForm();
        this.existingCategory = category;
        this.existingFaqDescription = existingQuestion;
        if (allFaqDict) {
            this.allLanguagesFaqCategories = allFaqDict;
            this.numberOnDashboard = numberOnDashboard;
            this.maxOnDashboard = maxOnDashboard;
        } else {
            this.getAllFaq();
        }
        if (this.existingCategory || this.existingFaqDescription) {
            this.setupForEdit();
        }
        this.active = true;
        this.FAQDescriptionCreateEditModal.show();
    }

    public save(): Promise<void> {
        if (this.isFormInvalid()) {
            this.markAllControlsAsTouch();
            this.showForNotValidMessage();
            return;
        }
        this.saving = true;
        const descriptionFaq = this.returnResult();
        const action = this.existingFaqDescription ? this._service.updateFAQDescription.bind(this._service) : this._service.createFAQDescription.bind(this._service);
        action(descriptionFaq)
            .pipe(
                finalize(() => {
                    this.saving = false;
                })
            )
            .toPromise()
            .then(() => {
                this.notify.info(this.l('SavedSuccessfully'));
                this.close();
                this.modalSave.emit(null);
            });
    }

    public close(): void {
        this.active = false;
        this.FAQDescriptionCreateEditModal.hide();
    }

    private resetForm() {
        this.editing = false;
        this.formGroup.reset({ status: 1, onDashboard: 0 });
        this.resetDropdowns();
    }

    private resetDropdowns(languageId?: number) {
        this.canPutOnDashboard = this.isEnglish && this.numberOnDashboard < this.maxOnDashboard;
        if (languageId == null) {
            this.resetLanguage(true);
            this.resetCategoryDropdown();
            this.resetOnDashboardDropdown();
        } else {
            this.resetCategoryDropdown(true);
            this.resetOnDashboardDropdown(this.canPutOnDashboard);
        }
        this.resetTranslationDropdown();
    }

    private resetLanguage(reenableAfter: boolean = false, newValue: number | null = null) {
        this.selectedLanguage = newValue;
        this.isEnglish = this._appLocalizationService.isEnglish(this.selectedLanguage);
        const languageDropdown = this.formGroup.get('language');
        languageDropdown.setValue(newValue);
        languageDropdown.disable();
        if (reenableAfter) {
            languageDropdown.enable();
        }
    }

    private resetCategoryDropdown(reenableAfter: boolean = false, newValue: string | null = null) {
        const categoryDropdown = this.formGroup.get('category');
        categoryDropdown.setValue(newValue);
        categoryDropdown.disable();
        if (reenableAfter) {
            categoryDropdown.enable();
        }
    }

    private resetOnDashboardDropdown(reenableAfter: boolean = false, newValue: number = 0) {
        const onDashboardDropdown = this.formGroup.get('onDashboard');
        onDashboardDropdown.setValue(newValue);
        onDashboardDropdown.disable();
        if (reenableAfter) {
            onDashboardDropdown.enable();
        }
    }

    private resetTranslationDropdown(reenable: boolean = false, newValue: string | null = null, categoryId?: string) {
        this.untranslatedQuestions = [];
        const translationDropdown = this.formGroup.get('translation');
        translationDropdown.setValue(newValue);
        translationDropdown.disable();
        if (reenable && categoryId) {
            this.setupTranslation(categoryId);
        }
    }

    private setupTranslation(categoryId: string) {
        // if the language is not English, enable the translation dropdown
        if (!this.isEnglish && this.formGroup.get('category').value != null) {
            this.formGroup.get('translation').enable();
            // Now we want to get the questions in english, in the same selected category, that doesn't have a translation
            const selectedCategoryInSelectedLanguage: FAQCategoryOutput = this.faqCategories?.find((category) => category.id === categoryId);
            const allEnglishFaqCategories: FAQCategoryOutput[] = this.allLanguagesFaqCategories.get(this._appLocalizationService.getEnglishLanguage()?.id);
            const equivalentCategoryInEnglish: FAQCategoryOutput = allEnglishFaqCategories.find((category) => category.id === selectedCategoryInSelectedLanguage?.translationOf);
            const englishFaqQuestionsInEquivalentCategory: FAQDescriptionOutput[] = equivalentCategoryInEnglish?.faqDescriptions || [];
            const allQuestionsInCategory: FAQDescriptionOutput[] = selectedCategoryInSelectedLanguage?.faqDescriptions;
            this.untranslatedQuestions =
                englishFaqQuestionsInEquivalentCategory.filter((faq) => {
                    return this.existingFaqDescription?.translationOf === faq.id || !allQuestionsInCategory?.some((question) => question.translationOf === faq.id);
                }) || [];
        }
    }

    private setupForEdit() {
        const categoryId = this.existingFaqDescription?.categoryId ?? this.existingCategory.id;
        this.selectedLanguage = this.existingFaqDescription ? this.existingFaqDescription.languageId : this.existingCategory.languageId;
        this.isEnglish = this._appLocalizationService.isEnglish(this.selectedLanguage);
        let canTranslate = false;
        this.faqCategories = this.allLanguagesFaqCategories.get(this.selectedLanguage);
        this.resetLanguage(false, this.selectedLanguage);
        this.resetCategoryDropdown(false, categoryId);
        if (this.existingFaqDescription) {
            this.editing = true;
            canTranslate = Boolean(this.existingFaqDescription?.translationOf || !this._appLocalizationService.isEnglish(this.existingFaqDescription?.languageId));
            this.formGroup.get('question').setValue(this.existingFaqDescription.question);
            this.formGroup.get('answer').setValue(this.existingFaqDescription.answer);
            this.formGroup.get('status').setValue(Number(this.existingFaqDescription.status));
            this.resetOnDashboardDropdown(this.existingFaqDescription.onDashboard || this.canPutOnDashboard, Number(this.existingFaqDescription.onDashboard));
        } else {
            canTranslate = !this._appLocalizationService.isEnglish(this.existingCategory?.languageId);
        }
        this.resetTranslationDropdown(canTranslate, this.existingFaqDescription?.translationOf, categoryId);
    }

    private getAllFaq() {
        this._service.getAllFAQDescriptions().subscribe((response) => {
            const faqListOutput: FAQCategoryListOutput = response.result;
            this.allLanguagesFaqCategories = this._service.splitFaqListPerLanguage(faqListOutput.faqList);
            this.maxOnDashboard = faqListOutput.maxOnDashboard;
            this.numberOnDashboard = faqListOutput.numberOnDashboard;
            this.faqCategories = this.allLanguagesFaqCategories.get(this._appLocalizationService.currentLanguage.id);
        });
    }

    private returnResult() {
        const faqDescription: FAQDescriptionInput = {
            ...this.existingFaqDescription,
            languageId: this.formGroup.get('language').value,
            question: this.formGroup.get('question').value,
            answer: this.formGroup.get('answer').value,
            categoryId: this.formGroup.get('category').value,
            translationOf: this.formGroup.get('translation').value,
            status: !!this.formGroup.get('status').value,
            onDashboard: !!this.formGroup.get('onDashboard').value
        };
        return faqDescription;
    }

    private isFormInvalid(): boolean {
        return this.formGroup.invalid;
    }

    private markAllControlsAsTouch() {
        this.formGroup.controls['language'].markAsTouched();
        this.formGroup.controls['category'].markAsTouched();
        this.formGroup.controls['question'].markAsTouched();
        this.formGroup.controls['translation'].markAsTouched();
        this.formGroup.controls['status'].markAsTouched();
        this.formGroup.controls['onDashboard'].markAsTouched();
        this.formGroup.controls['answer'].markAsTouched();
    }

    private showForNotValidMessage() {
        const title = this._appLocalizationService.l('VerifyAllControlsTitle');
        const message = this._appLocalizationService.l('VerifyAllControlsMessage');
        this.toastr.error(message, title);
    }
}
