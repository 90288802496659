import { Injectable } from '@angular/core';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import localizations from 'assets/localizations.json';
import { AppConsts } from '@shared/AppConsts';
import * as utils from '@shared/utils/utils';

export interface LocalizationLanguage {
    id: number;
    name: string;
    displayName: string;
    icon: string;
    isDefault: boolean;
}

@Injectable({
    providedIn: 'root',
    deps: [AppAuthService]
})
export default class LocalizationService {
    private readonly _languages: LocalizationLanguage[];
    private _currentLanguage: LocalizationLanguage;
    private readonly ENGLISH: string = 'English';

    constructor(private readonly authService: AppAuthService) {
        this._languages = localizations.languages;
    }

    get languages(): LocalizationLanguage[] {
        return this._languages;
    }

    get currentLanguage(): LocalizationLanguage {
        if (this._currentLanguage) {
            return this._currentLanguage;
        }

        if (this.authService.user.locale) {
            this._currentLanguage = this._languages.find((x) => x.name === this.authService.user.locale);
            return this._currentLanguage;
        }

        return this._languages.find((x) => x.isDefault);
    }

    set currentLanguage(language: LocalizationLanguage) {
        this._currentLanguage = language;
        localStorage.setItem('locale', language.name);
        window.location.reload();
    }

    public localize(key: string, sourceName: string, language: string = null): string {
        const source = localizations.values[language ?? this.currentLanguage.name][sourceName];

        if (!source || !key) {
            return key;
        }

        const keys = key.split('.');
        let localizedText = source;

        /*
            This allows to have nested object in a source like
            "Preemploi": {
                "RequestPage": {
                    "Title": "Request"
                }
            }
            Then we can access it using {{ "RequestPage.Title" | localize }} with the pipe
        */
        for (const subKey of keys) {
            localizedText = localizedText[subKey];
            if (!localizedText) {
                return key;
            }
        }

        return localizedText;
    }

    public isEnglish(languageId: number): boolean {
        return this.getEnglishLanguage()?.id === languageId;
    }

    public getEnglishLanguage(): LocalizationLanguage | undefined {
        return this._languages?.find((l) => l.displayName === this.ENGLISH);
    }

    public localizeTo(language: string, key: string, source: string = null): string {
        return this.localize(key, source ?? AppConsts.localization.defaultLocalizationSourceName, language);
    }

    public l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(AppConsts.localization.defaultLocalizationSourceName);
        return this.ls.apply(this, args);
    }

    public ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args?.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return utils.formatString.apply(this, args);
    }

    public localizeGridText(key: string, defaultValue: string) {
        const gridKey = `Grid.${key}`;
        const translatedText = this.l(gridKey);
        return translatedText === gridKey ? defaultValue : translatedText;
    }
}
