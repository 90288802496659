<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'Requests' | localize" [description]="'RequestDetails' | localize">
            <div *ngIf="!isCancelled" role="actions">
                <button class="btn btn-secondary requestDetails-btn" (click)="generateInvoice()"
                    *ngIf="isUserAllowToGenerateInvoice && !shouldShowInvoiceStatus"
                    data-test-id="request-details-generateInvoice-btn">
                    {{ 'GenerateInvoice' | localize }}
                </button>
                <button class="btn btn-secondary requestDetails-btn" (click)="sendToPpo()"
                        *ngIf="isRequestCanBeSentToPpo()">
                    {{ 'RequestSendPpo' | localize }}
                </button>
                <button class="btn btn-primary" (click)="update()" *ngIf="isAllowedToUpdateRequest"
                    data-test-id="request-details-edit-btn">
                    <i class="la la-edit"></i>
                    {{ 'Edit' | localize }}
                </button>
                <button class="btn btn-primary" (click)="updateRequestToNew()" *ngIf="canSendInfoToGardium">
                    {{ 'SentRequestToGardium' | localize }}
                </button>
            </div>
        </sub-header>
    </div>
    <div [class]="containerClass">
        <div class="row" class="breadCrumbContainer">
            <div class="col pl-0 pr-0">
                <p-breadcrumb [model]="items"></p-breadcrumb>
            </div>
        </div>
    </div>
    <div class="m-content">
        <form [formGroup]="formGroup" autocomplete="off">
            <div [class]="containerClass">
                <div class="card card-custom mb-10">
                    <div class="card-body">
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="status">{{ 'StatusRequest' | localize }}</label>
                                <select id="status" formControlName="status" type="text" name="status"
                                    class="form-control">
                                    <option *ngFor="let status of statusOptions" [value]="status.id">{{ status.name |
                                        localize }}</option>
                                </select>
                            </div>
                            <div class="form-group col-md-6">
                                <label for="organizationClientId">{{ 'ClientId' | localize }}</label>
                                <input id="organizationClientId" formControlName="organizationClientId" type="text"
                                    name="organizationClientId" class="form-control" maxlength="256" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="organization">{{ 'Organization' | localize }}</label>
                                <input id="organization" formControlName="organization" type="text" name="organization"
                                    class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="candidat">{{ 'Candidat' | localize }}</label>
                                <input id="candidat" formControlName="candidat" type="text" name="candidat"
                                    class="form-control" maxlength="256" />
                            </div>
                        </div>
                        <div class="row" *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                            <div class="form-group col-md-6">
                                <label for="userNameCreator">{{ 'CreatedBy' | localize }}</label>
                                <input id="userNameCreator" formControlName="userNameCreator" type="text"
                                    name="userNameCreator" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="primaryContact">{{ 'PrimaryContact' | localize }}</label>
                                <input id="primaryContact" formControlName="primaryContact" type="text"
                                       name="primaryContact" class="form-control" maxlength="256" />
                            </div>
                        </div>
                        <div class="row" *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                            <div class="form-group col-md-6">
                                <label for="additionalContact">{{ 'AdditionalContact' | localize }}</label>
                                <input id="additionalContact" formControlName="additionalContact" type="text"
                                       name="additionalContact" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="user">{{ 'AssignedTo' | localize }}</label>
                                <input id="user" formControlName="user" type="text" name="user" class="form-control"
                                    maxlength="256" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col-md-6">
                                <label for="userEmail">{{ 'AssignedToEmail' | localize }}</label>
                                <input id="userEmail" formControlName="userEmail" type="text" name="userEmail"
                                       class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6" *ngIf="shouldShowInvoiceStatus">
                                <label for="invoiceStatus">{{ 'StatusInvoice' | localize }}</label>
                                <input id="invoiceStatus" formControlName="invoiceStatus" type="text"
                                    name="invoiceStatus" class="form-control" maxlength="256"
                                    data-test-id="request-details-invoiceStatus-input" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="purchaseOrder">{{ 'PurchaseOrder' | localize }}</label>
                                <input id="purchaseOrder" formControlName="purchaseOrder" type="text"
                                    name="purchaseOrder" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6"
                                *ngIf="isGranted('Pages.Management.Requests.CanSeePrivateInformation')">
                                <label for="billingPeriod">{{ 'BillingPeriod' | localize }}</label>
                                <input id="billingPeriod" formControlName="billingPeriod" type="text"
                                    name="billingPeriod" class="form-control" maxlength="256" />
                            </div>
                            <div class="form-group col-md-6">
                                <label for="reportLanguage">{{ 'ReportLanguage' | localize }}</label>
                                <select name="reportLanguage" class="form-control" id="reportLanguage"
                                    formControlName="reportLanguage" data-test-id="request-details-field">
                                    <option value="">{{ 'Select' | localize }}</option>
                                    <option value="english">{{ 'English' | localize }}</option>
                                    <option value="french">{{ 'French' | localize }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card card-custom mb-10">
                    <div class="card-body">
                        <h5>{{ 'Verifications' | localize }}</h5>
                        <h6 style="font-weight: normal">{{ 'VerificationsDescription' | localize }}</h6>
                        <div>
                            <grid-pagination class="grid-pagination"
                                (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
                            <ag-grid-angular localizeText class="ag-theme-material gardium-small-grid"
                                [paginationNumberFormatter]="paginationNumberFormatter" [rowData]="requestVerifications"
                                [columnDefs]="columnDefsRequestVerifications"
                                [defaultColDef]="defaultColDefRequestVerifications"
                                [paginationPageSize]="paginationPageSize" [pagination]="true"
                                (gridReady)="onRequestVerificationGridReady($event)"></ag-grid-angular>
                        </div>
                    </div>
                </div>
                <div class="card card-custom mb-10">
                    <div class="candidatDetails">
                        <h4>{{ 'CandidateInformation' | localize }}</h4>
                        <h6 style="font-weight: normal">{{ 'CandidateInformationDescription' | localize }}</h6>
                        <tabset class="tab-container tabbable-line">
                            <tab heading="{{ 'CandidatDetails' | localize }}">
                                <request-candidats-details #candidatsDetails></request-candidats-details>
                            </tab>
                            <tab heading="{{ 'AddressCurrent' | localize }}">
                                <request-address-history #candidatsAddress *ngIf="selectedCandidat != undefined"
                                    [requestVerifications]="requestVerifications" [readOnly]="true"
                                    [selectedCandidat]="selectedCandidat"></request-address-history>
                            </tab>
                            <tab heading="{{ 'AddressHistory' | localize }}" *ngIf="showAddressList">
                                <request-address-history-list
                                    *ngIf="selectedCandidat != undefined"
                                    [informationSource]="informationSource"
                                    [requestPublicId]="publicId"
                                    [selectedCandidat]="selectedCandidat">
                                </request-address-history-list>
                            </tab>
                            <tab heading="{{ 'References.Title' | localize}}" *ngIf="showReferenceList">
                                <references-list
                                    [request]="request">
                                </references-list>
                            </tab>
                            <tab heading="{{ 'ProfessionnalAccreditations' | localize }}" *ngIf="showAccreditationList">
                                <professionnal-accreditations-list
                                    (modalRefresh)="refreshVerificationList()"
                                    [candidateId]="request.candidat.id"
                                    [candidatePublicId]="request.candidat.publicId"
                                    [informationSource]="informationSource"
                                    [requestPublicId]="request.publicId">
                                </professionnal-accreditations-list>
                            </tab>
                            <tab heading="{{ 'AcademicRecord' | localize }}" *ngIf="showAcademicRecordList">
                                <academic-record-list
                                    (modalRefresh)="refreshVerificationList()"
                                    [candidateId]="request.candidat.id"
                                    [candidatePublicId]="request.candidat.publicId"
                                    [informationSource]="informationSource"
                                    [requestPublicId]="request.publicId">
                                </academic-record-list>
                            </tab>
                        </tabset>
                    </div>
                </div>

                <div class="card card-custom mb-10">
                    <div class="requestFileBorder" *ngIf="request">
                        <list-request-files #filesList
                            [requestIsOpen]="requestOpen" [fileListType]="fileTypeEnum.Filled"
                            [requestId]="request.id"></list-request-files>
                    </div>
                </div>

                <div class="card card-custom mb-10" *ngIf="canSeeReports">
                    <div class="requestFileBorder" *ngIf="request">
                        <list-request-files #filesReports
                            [requestIsOpen]="requestOpen" [fileListType]="fileTypeEnum.Reports"
                            [requestId]="request.id"></list-request-files>
                    </div>
                </div>
                <div class="card card-custom mb-10">
                    <div class="card-body">
                        <notes-list #notes *ngIf="request" [requestId]="request.id" [requestStatus]="request.status"
                            [requestPublicId]="request.publicId"></notes-list>
                        <notes-details #notesDetailsModal></notes-details>
                    </div>
                </div>
                <div *ngIf="!isCancelled" class="col text-end mt-3 mt-md-0">
                    <button class="btn btn-secondary requestDetails-btn" (click)="generateInvoice()"
                        *ngIf="!shouldShowInvoiceStatus && isUserAllowToGenerateInvoice">
                        {{ 'GenerateInvoice' | localize }}
                    </button>
                    <button class="btn btn-primary" (click)="update()" *ngIf="isAllowedToUpdateRequest">
                        <i class="la la-edit"></i>
                        {{ 'Edit' | localize }}
                    </button>
                    <button class="btn btn-primary" (click)="updateRequestToNew()" *ngIf="canSendInfoToGardium">
                        {{ 'SentRequestToGardium' | localize }}
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>
<br />
