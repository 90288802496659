import { Directive, AfterViewInit } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppLocalizationService } from '../common/localization/app-localization.service';


@Directive({
    selector: '[localizeText]'
})
export class GridLocalizationDirective implements AfterViewInit {
    constructor(private readonly localizationService: AppLocalizationService, private readonly agGrid: AgGridAngular) {}

    ngAfterViewInit() {
        if (!this.agGrid.gridOptions) {
            this.agGrid.gridOptions = {};
        }
        this.agGrid.gridOptions.localeTextFunc = this.localizationService.localizeGridText.bind(this.localizationService);
    }
}
