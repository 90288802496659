import { Component, EventEmitter, Input, Output, ViewChild, OnDestroy, AfterContentInit, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgIf, DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { ModalModule, ModalDirective, ModalOptions } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UtilsModule } from '@shared/utils/utils.module';
import { atLeastOneFieldRequired } from '@shared/utils/validation/atLeastOneFieldRequired-validator';
import { AppConsts } from '@shared/AppConsts';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ReferenceService } from '@shared/services/reference.service';
import ReferenceCreationInput from '@shared/models/reference/referenceCreationInput';
import { RequestVerificationOutput } from '@shared/models/requestVerification/requestVerificationOutput';
import Reference, { ReferenceProgressStatus } from '@shared/models/reference/reference';
import { LanguageDropdownInputComponent } from '@app/shared/form/custom-inputs/language-dropdown-input/language-dropdown-input.component';
import { EmailInputComponent } from '@app/shared/form/custom-inputs/email-input/email-input.component';
import { PhoneInputComponent } from '@app/shared/form/custom-inputs/phone-input/phone-input.component';
import { NameParts, NameInputComponent } from '@app/shared/form/custom-inputs/name-input/name-input.component';
import { CustomFormComponent } from '@app/custom-form/custom-form/custom-form.component';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { TextTransformDirective } from '@app/shared/directives/text-transform.directive';
import { DropdownChoice } from '@shared/models/dropdownChoice';
import { SingleSelectionListComponent } from '@app/shared/form/base-inputs/single-selection-list/single-selection-list.component';
import { DialogService } from '@shared/services/dialog-service';
import { VerificationForm } from '@shared/models/custom-form/verificationForm';
import { FormQuestionConfiguration, FormQuestionType } from '@shared/models/custom-form/formQuestionConfiguration';

@Component({
    standalone: true,
    selector: 'references-modal',
    imports: [
        ReactiveFormsModule,
        NgIf,
        DatePipe,
        UtilsModule,
        ModalModule,
        LanguageDropdownInputComponent,
        EmailInputComponent,
        PhoneInputComponent,
        NameInputComponent,
        CustomFormComponent,
        FormGroupComponent,
        SingleSelectionListComponent
    ],
    templateUrl: './references-modal.component.html',
    providers: [TextTransformDirective]
})
export class ReferencesModalComponent implements OnDestroy, AfterContentInit, OnInit {
    @ViewChild('modal', { static: false }) private readonly modal: ModalDirective;
    @ViewChild('customForm', {static: false}) private readonly customForm: CustomFormComponent;

    @Input() protected readonly requestVerifications: RequestVerificationOutput[] = [];
    @Output() private readonly referenceCreated = new EventEmitter<ReferenceCreationInput>();
    @Output() private readonly referenceUpdated = new EventEmitter<Reference>();
    @Output() private readonly onClose = new EventEmitter<void>();

    // Edit mode properties
    protected existingReference?: Reference | null = null;
    protected editMode: boolean = false;
    protected saving: boolean = false;
    protected modalConfig: ModalOptions = { backdrop: 'static', keyboard: false };

    // Form Configurations
    protected readonly referenceForm: FormGroup;
    protected selectedVerificationId: number | null = null;
    protected selectedLanguage: string | null = null;
    protected readonly dateTimeFormat = AppConsts.dateTimeFormat;
    protected readonly dateTimeZone = AppConsts.mtlTimezone;
    protected readonly nameParts = NameParts;
    protected readonly statuses: DropdownChoice[];
    protected requestVerificationChoices: DropdownChoice[];
    protected sending: boolean = false;
    private readonly destroy$ = new Subject<void>();

    constructor(
        private readonly fb: FormBuilder,
        private readonly referenceService: ReferenceService,
        private readonly toast: ToastrService,
        private readonly changeDetector: ChangeDetectorRef,
        protected readonly localizationService: AppLocalizationService,
        protected readonly dialogService: DialogService
    ) {
        this.referenceForm = this.fb.group({
            [this.requestVerificationControlName]: [null, Validators.required],
            verificationForm: this.fb.group({}),
            status: null,
            [LanguageDropdownInputComponent.formControlName]: null
        });

        this.referenceForm
            .get(this.requestVerificationControlName)
            ?.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((newRequestVerificationId?: string) => {
                if (newRequestVerificationId && this.referenceForm.dirty) {
                    const requestVerification = this.requestVerifications.find((rv) => rv.id.toString() === newRequestVerificationId);

                    if (requestVerification?.verificationId) {
                        this.selectedVerificationId = requestVerification.verificationId;
                    }
                }
            });

        this.referenceForm
            .get(LanguageDropdownInputComponent.formControlName)
            ?.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((newLanguage?: string) => {
                this.selectedLanguage = newLanguage;
            });

        this.statuses = Object.keys(ReferenceProgressStatus)
            .filter((key) => !isNaN(Number(key)))
            .map((s) => ({
                value: s,
                display: localizationService.ls('PreEmploiReferenceStatus', s)
            }));
    }

    ngOnInit() {
        this.requestVerificationChoices = this.requestVerifications.map((rv) => ({
            value: rv.id.toString(),
            display: rv.verification?.code || `Verification: ${rv.verificationId}`
        }));
    }

    ngAfterContentInit() {
        // We must add this after initialization because the form controls are not built yet in the constructor
        this.referenceForm.addValidators(atLeastOneFieldRequired(EmailInputComponent.formControlName, PhoneInputComponent.formControlName));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public show(existingRef: Reference | null = null) {
        this.referenceForm.reset({ status: ReferenceProgressStatus.New.toString() });
        this.existingReference = existingRef;
        this.editMode = this.existingReference !== null;
        this.selectedVerificationId = this.existingReference?.verificationForm?.verificationId || null;

        // If we have an existing reference, we want to populate the form with the existing reference
        if (this.editMode) {
            this.referenceForm.patchValue({
                ...this.existingReference,
                requestVerificationId: this.existingReference.requestVerificationId,
                status: this.existingReference.status.toString()
            });
            this.changeDetector.detectChanges();
        }

        this.modal?.show();
    }

    protected get isFormTypeSelectionDisabled(): boolean {
        return this.editMode || this.selectedLanguage === null;
    }

    protected get requestVerificationControlName(): string {
        return 'requestVerificationId';
    }

    protected get statusControlName(): string {
        return 'status';
    }

    protected get newStatus(): string {
        return ReferenceProgressStatus.New.toString();
    }

    protected get verificationForm(): FormGroup {
        return this.referenceForm.get('verificationForm') as FormGroup;
    }

    protected get canSend(): boolean {
        return this.referenceForm.get(EmailInputComponent.formControlName)?.value && this.referenceForm.valid;
    }

    protected get busyText(): string {
        return this.localizationService.l('SavingWithThreeDot');
    }

    protected get sendingBusyText(): string {
        return this.localizationService.l('References.SendingToReference');
    }

    protected get saveAndCompleteBusyText(): string {
        return this.localizationService.l('SaveAndCompleteWithThreeDot');
    }
    protected close() {
        this.modal?.hide();
        this.onClose.emit();
    }

    protected confirm(completeReference: boolean = false) {
        if (this.formIsValid()) {
            this.saving = true;
            this.save(completeReference).toPromise().then(() => {
                this.saving = false
                this.close();
            });
        }
    }

    protected send() {
        if (this.referenceForm.dirty && this.formIsValid()) {
            this.dialogService.confirm(
                this.localizationService.l('References.SaveBeforeSendingPrompt'),
                this.localizationService.l('Warning'), (confirmed) => {
                    if (confirmed) {
                        // First we save the user's changes and then we send the form to the reference
                        this.save().subscribe((reference) => {
                            this.sendToReference(reference.id);
                        });
                    }
                }
            );
        } else if (this.referenceForm.pristine) {
            this.sendToReference(this.existingReference?.id);
        }
    }

    // Send the form to the reference
    private sendToReference(referenceId: number) {
        this.sending = true;
        this.referenceService.sendToReference(referenceId).subscribe(_ => {
            this.sending = false;
            this.showToast(true, 'References.Toasts.SentTitle', 'References.Toasts.SentMessage');
            this.close();
        });
    }

    // Checks if the form is valid
    private formIsValid(): boolean {
        this.referenceForm.markAllAsTouched();
        return this.referenceForm.valid;
    }

    // Depending on the mode, we either create or update the reference
    private save(completeReference: boolean = false): Observable<Reference> {
        const { verificationForm, ...rest } = this.referenceForm.getRawValue();
        
        if (completeReference) {
            rest.status = ReferenceProgressStatus.Completed;
        }

        const transformedVerificationForm = this.transformFormValues(verificationForm);
        if (this.editMode) {
            return this.updateReference({
                ...rest,
                id: this.existingReference?.id,
                verificationForm: transformedVerificationForm
            } as Reference);
        } else {
            return this.createReference({
                ...rest,
                verificationForm: transformedVerificationForm
            } as ReferenceCreationInput);
        }
    }

    /**
     * Transforms the answers from the form values to the format that the server expects
     * @param values those are the values from the raw form.
     * @returns the transformed form values into a VerificationForm object
     */
    private transformFormValues(values: Record<string, unknown>): VerificationForm {
        const {questions, ...restConfig} = this.customForm.formConfiguration;
        return {
            ...restConfig,
            questions: Object.entries(values).map(([id, answer]): FormQuestionConfiguration => {
                const question: FormQuestionConfiguration = questions.find((question) => question.id == parseInt(id));
                const updatedQuestion: FormQuestionConfiguration = {...question};
                const answerIsArray: boolean = Array.isArray(answer);

                if (question?.type === FormQuestionType.list || answerIsArray) {
                    if (question?.isMultiSelection || answerIsArray) {
                        updatedQuestion.answerChoicesIndexes = answer as number[];
                    } else {
                        updatedQuestion.answerChoicesIndexes = [parseInt(answer as string)];
                    }
                } else {
                    updatedQuestion.answer = answer as string;
                }

                return updatedQuestion;
            })
        };
    }

    // Send the reference to the server
    private createReference(input: ReferenceCreationInput): Observable<Reference> {
        return this.referenceService.addReference(input).pipe(
            tap({
                next: () => {
                    this.showToast(true, 'References.Toasts.Add', 'References.Toasts.Added');
                    this.referenceCreated.emit(input);
                },
                error: (_) => {
                    this.saving = false;
                    this.changeDetector.detectChanges();
                    this.showToast(false, 'References.Toasts.Issue', 'References.Toasts.AddTryAgainLater');
                }
            })
        );
    }

    // Update the existing reference
    private updateReference(reference: Reference): Observable<Reference> {
        return this.referenceService.updateReference(reference).pipe(
            tap({
                next: () => {
                    this.showToast(true, 'References.Toasts.Update', 'References.Toasts.Updated');
                    this.referenceUpdated.emit(reference);
                },
                error: (_) => {
                    this.saving = false;
                    this.changeDetector.detectChanges();
                    this.showToast(false, 'References.Toasts.Issue', 'References.Toasts.UpdateTryAgainLater');
                }
            })
        );
    }

    // Show the result of the operation to the user
    private showToast(isSuccess: boolean, titleKey: string, messageKey: string) {
        const title = this.localizationService.l(titleKey);
        const successMessage = this.localizationService.l(messageKey);
        isSuccess ? this.toast.success(successMessage, title) : this.toast.error(successMessage, title);
    }
}
