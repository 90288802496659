import { Component, OnInit, Injector, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { ProfessionnalAccreditationsAddComponent } from '../professionnal-accreditations-add/professionnal-accreditations-add.component';
import { AgGridAngular } from '@ag-grid-community/angular';
import { ProfessionnalAccreditationOutput } from '@shared/models/CandidatDetails/professionnal-accreditations/professionnalAccreditationOutput';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { LazyLoadEvent } from 'primeng/api';
import { ProfessionnalAccreditationsUpdateComponent } from '../professionnal-accreditations-update/professionnal-accreditations-update.component';
import { ToastrService } from 'ngx-toastr';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { DatePipe } from '@angular/common';
import { ProfessionnalAccreditationService } from '@shared/services/professionnalAccreditation.service';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';

@Component({
    selector: 'professionnal-accreditations-list',
    templateUrl: './professionnal-accreditations-list.component.html',
    providers: [DatePipe]
})
export class ProfessionnalAccreditationsListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('accreditationAddModal') accreditationAddModal: ProfessionnalAccreditationsAddComponent;
    @Input() requestPublicId: string;
    @Input() candidateId: number;
    @Input() candidatePublicId: string;
    @Input() informationSource: InformationSourceDTO = InformationSourceDTO.Candidate;
    @Output() modalRefresh: EventEmitter<any> = new EventEmitter<any>();
    @Input() readOnly = true;

    paginationNumberFormatter;
    paginationPageSize = 20;

    public defaultColDef;
    rowData: ProfessionnalAccreditationOutput[] = [];
    columnDefs: any;
    publicId: string;
    selectedAccreditation: ProfessionnalAccreditationOutput;
    bsModalRef: BsModalRef;
    rowSelection: string;

    constructor(
        private readonly injector: Injector,
        private readonly modalService: BsModalService,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly _professionnalAccreditationService: ProfessionnalAccreditationService,
        private readonly toastr: ToastrService
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    async ngOnInit() {
        await this.initializeRowData();

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('Year'),
                width: 150,
                field: 'year',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Institution'),
                width: 150,
                field: 'instutition',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Title'),
                width: 150,
                field: 'title',
                sortable: true,
                filter: true
            },
            {
                headerName: this._appLocalizationService.l('Location'),
                width: 200,
                field: 'location',
                sortable: true,
                filter: true
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getEditBtnIfNotReadOnly(),
                cellRendererParams: { action: this.updateAccreditation }
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getDeleteBtnIfNotReadOnly(),
                cellRendererParams: { action: this.deleteAccreditation }
            }
        ];

        this.defaultColDef = { resizable: true, sortable: true, filter: true };
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getEditBtnIfNotReadOnly() {
        return this.readOnly ? null : GridBtnUpdateComponent;
    }

    getDeleteBtnIfNotReadOnly() {
        return this.readOnly ? null : GridBtnDeleteComponent;
    }

    async initializeRowData() {
        await this._professionnalAccreditationService
            .getProfessionnalAccreditationsByCandidat(this.candidatePublicId)
            .toPromise()
            .then((response) => {
                this.rowData = response.result;
            });
    }

    addAccreditation() {
        this.accreditationAddModal.show();
    }

    updateAccreditation = async (element: ProfessionnalAccreditationOutput) => {
        this.selectedAccreditation = element;
        this.bsModalRef = this.modalService.show(ProfessionnalAccreditationsUpdateComponent, {
            initialState: {
                selectedAccreditation: this.selectedAccreditation
            },
            backdrop: 'static'
        });
        this.bsModalRef.content.modalSave.subscribe((result) => {
            this.refresh();
        });
    };

    deleteAccreditation = async (element: ProfessionnalAccreditationOutput) => {
        this._professionnalAccreditationService.deleteProfessionnalAccreditation(element.publicId).subscribe((response) => {
            const accreditation = response.result;
            this.initializeRowData();
            this.modalRefresh.emit(null);
            if (accreditation.id != null) {
                this.showSuccess();
            } else {
                this.showErrorMessage();
            }
        });
    };

    showSuccess() {
        const title = this._appLocalizationService.l('AccreditationDelete');
        const successMessage = this._appLocalizationService.l('AccreditationDeleted');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('AccreditationDeleteIssue');
        const errorMessage = this._appLocalizationService.l('AccreditationDeleteTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeRowData();
        this.modalRefresh.emit(null);
    }
}
