import { Component, Injector, OnDestroy } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { PrimengTableHelper } from 'shared/helpers/PrimengTableHelper';
import { UiCustomizationSettingsDto } from '@shared/service-proxies/service-proxies';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { EventService } from '@shared/services/event.service';
import * as notify from '@shared/utils/notify';
import { SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import { DialogService } from '@shared/services/dialog-service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

interface EventSubscription {
    eventName: string;
    callback: (...args: any[]) => void;
}

@Component({
    template: ''
})
export abstract class AppComponentBase implements OnDestroy {
    localization: AppLocalizationService;
    primengTableHelper: PrimengTableHelper;
    dateTimeService: DateTimeService;

    eventSubscriptions: EventSubscription[] = [];
    appAuthService: AppAuthService;
    eventService: EventService;
    message: {
        success: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        error: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        info: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        warn: (message: string, title?: string, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<SweetAlertResult<any>>;
        confirm: (message: string, title?: string, callback?: Function, options?: SweetAlertOptions & { isHtml?: boolean; }) => Promise<void>;
    };
    notify: {
        success: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
        error: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
        info: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
        warn: (message: string, title?: string, options?: SweetAlertOptions & { imageClass?: string; }) => void;
    };

    constructor(injector: Injector) {
        this.localization = injector.get(AppLocalizationService);
        this.appAuthService = injector.get(AppAuthService);
        this.primengTableHelper = new PrimengTableHelper();
        this.dateTimeService = injector.get(DateTimeService);
        this.eventService = injector.get(EventService);
        const dialogService = injector.get(DialogService);

        this.message = {
            success: dialogService.success,
            error: dialogService.error,
            info: dialogService.info,
            warn: dialogService.warn,
            confirm: dialogService.confirm
        };
        this.notify = {
            success: notify.success,
            error: notify.error,
            info: notify.info,
            warn: notify.warn
        };
    }
    get currentTheme(): UiCustomizationSettingsDto {
        return AppConsts.theme;
    }

    get containerClass(): string {
        return 'container-fluid';
    }

    l = (key: string, ...args: any[]): string => {
        return this.localization.l(key, ...args);
    };

    ls = (sourcename: string, key: string, ...args: any[]): string => {
        return this.localization.ls(sourcename, key, ...args);
    };

    isGranted(permissionName: string): boolean {
        return this.appAuthService.hasPermission(permissionName);
    }

    ngOnDestroy(): void {
        this.unSubscribeAllEvents();
    }

    protected subscribeToEvent(eventName: string, callback: (...args: any[]) => void): void {
        this.eventService.on(eventName, callback);
        this.eventSubscriptions.push({
            eventName,
            callback
        });
    }

    private unSubscribeAllEvents() {
        this.eventSubscriptions.forEach((s) => this.eventService.off(s.eventName, s.callback));
        this.eventSubscriptions = [];
    }
}
