<div
    bsModal
    #modal="bs-modal"
    class="modal fade"
    [config]="modalConfig"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form [formGroup]="referenceForm" autocomplete="off" (ngSubmit)="confirm()">
                <div class="card-body">
                    <div class="modal-header">
                        <h2 class="modal-title">
                            {{ (editMode ? 'References.Edit' : 'References.New') | localize }}
                        </h2>
                        <div>
                            <p *ngIf="existingReference">
                                {{ 'References.LastTimeSentToReference' | localize }}:
                                <span>
                                    {{ existingReference.lastTimeSentToReference | date : dateTimeFormat : dateTimeZone }}
                                </span>
                            </p>
                            <button
                                [disabled]="!canSend"
                                type="button"
                                class="btn btn-primary"
                                [attr.title]="canSend ? null : ('References.InvalidReferenceFormTooltip' | localize)"
                                (click)="send()"
                                [buttonBusy]="sending"
                                [busyText]="sendingBusyText">
                                <i class="fas fa-paper-plane margin-right-5"></i>{{ 'References.SendToReference' | localize }}
                            </button>
                        </div>
                    </div>

                    <div class="modal-body">
                        <div class="row">
                            <name-input class="col-6" [parentFormControl]="referenceForm" [nameType]="nameParts.FirstName"></name-input>
                            <name-input class="col-6" [parentFormControl]="referenceForm" [nameType]="nameParts.LastName"></name-input>
                        </div>

                        <div class="row">
                            <email-input class="col-6" [parentFormControl]="referenceForm"/>
                            <phone-input class="col-6" [parentFormControl]="referenceForm"/>
                        </div>

                        <div class="row">
                            <language-dropdown-input class="col-4" [parentFormControl]="referenceForm" [isMandatory]="true"/>
                            <form-group class="col-4" label="References.Type" [parentFormControl]="referenceForm" [name]="requestVerificationControlName"
                                [options]="({isMandatory: true})">
                                <single-selection-list #inputControl [name]="requestVerificationControlName" [choices]="requestVerificationChoices"
                                    [disabled]="isFormTypeSelectionDisabled"/>
                            </form-group>
                            <form-group class="col-4" label="Status" [parentFormControl]="referenceForm" [name]="statusControlName" [startingValue]="newStatus">
                                <single-selection-list #inputControl [name]="statusControlName" [choices]="statuses"/>
                            </form-group>
                        </div>

                        <!-- Dynamic Form part depending on selected reference type -->
                        <custom-form
                            #customForm
                            *ngIf="selectedVerificationId"
                            [verificationId]="selectedVerificationId"
                            [existingFormControl]="verificationForm"
                            [verificationFormModel]="existingReference?.verificationForm"
                            [ignoreIsMandatory]="true"
                            [editMode]="editMode"
                            [formLanguage]="selectedLanguage"
                        >
                        </custom-form>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" (click)="close()" [disabled]="saving">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="button" class="btn btn-success" (click)="confirm(true)" [buttonBusy]="saving" [busyText]="saveAndCompleteBusyText">
                            <i class="la la-floppy-o"></i>
                            {{ 'SaveAndComplete' | localize }}
                        </button>
                        <button type="submit" class="btn btn-primary" [buttonBusy]="saving" [busyText]="busyText">
                            <i class="la la-floppy-o"></i>
                            {{ 'Save' | localize }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
