<div *ngIf="templateIconStyle; then iconTemplate else textTemplate"></div>
<ng-template #textTemplate>
    <button class="btn btn-danger btn-sm" (click)="onActivationHandler($event)">
        {{"Delete" | localize}}
    </button>
</ng-template>
<ng-template #iconTemplate>
    <app-svg-icon
        iconName="ga-delete"
        (click)="onActivationHandler($event)"
        (keydown.enter)="onActivationHandler($event)"
        (keydown.space)="onActivationHandler($event)"
    ></app-svg-icon>
</ng-template>
