import { Component, Input } from '@angular/core';
import { NgxMaskModule } from 'ngx-mask';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';
import { NgIf } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';

/**
 * This component is used to display a standard input in a form. For more specialised versions you can use other inputs from the form folder or
 * specify the type of standard html input you want to use.
 * @params *__name__* - The name of the input in the form, will also be used as the DOM id.
 * @params *__questionControl__* - The form control connected to the question. If used inside a {@link FormGroupComponent} the control will be added automatically.
 * @params *__type__* - *(Optional)* The html type of the input, default is text.
 * @params *__options__* - *(Optional)* Extra options for the question's html element like masks, text transform and other optional directives.
 */
@Component({
    standalone: true,
    selector: 'standard-input[name]',
    imports: [ReactiveFormsModule, NgxMaskModule, NgIf, UtilsModule],
    templateUrl: './standard-input.component.html'
})
export class StandardInputComponent {
    @Input() protected readonly name: string;
    @Input() protected readonly questionControl: FormControl;
    @Input() protected readonly type: string = 'text';
    @Input() protected readonly options: FormQuestionOptions | null = null;
}
