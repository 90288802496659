import { Injector, Pipe, PipeTransform } from '@angular/core';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

@Pipe({
    name: 'localize',
})
export class LocalizePipe implements PipeTransform {
    localization: AppLocalizationService;

    constructor(injector: Injector) {
        this.localization = injector.get(AppLocalizationService);
    }

    transform(key: string, ...args: any[]): string {
        return this.localization.l(key, args);
    }
}
