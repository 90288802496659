import { Component, Input } from '@angular/core';
import { ReactiveFormsModule, FormControl } from '@angular/forms';
import { NgIf } from '@angular/common';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

/**
 * This component is used to display a text area input for a form question.
 * @params *__name__* - The id of the question used for the control's name. Will also be used for the html id.
 * @params *__questionControl__* - The form control connected to the question. If used inside a {@link FormGroupComponent} the control will be added automatically.
 * @params *__maxLength__* - *(Optional)* The maximum length of the text that can be entered. If none is provided, the text area will not have a character counter.
 * @params *__placeholder__* - *(Optional)* The placeholder text to display in the text area. Default to Forms.AnswerPlaceholder. Pass null to prevent a placeholder from being displayed.
 */
@Component({
    standalone: true,
    selector: 'textarea-input[name]',
    imports: [ReactiveFormsModule, NgIf],
    templateUrl: './textarea-input.component.html'
})
export class TextAreaInputComponent {
    @Input() protected readonly name: string;
    @Input() protected readonly questionControl: FormControl;
    @Input() protected readonly maxLength: number | null = null;
    @Input() protected readonly placeholder: string | null = 'Forms.AnswerPlaceholder';


    constructor(private readonly localizationService: AppLocalizationService) { }

    protected get placeholderText(): string | null {
        return this.localizationService.l(this.placeholder);
    }

    protected get characterCount(): number {
        return this.questionControl?.value?.length || 0;
    }
}
