import { Component, Injector } from '@angular/core';
import { ThemesLayoutBaseComponent } from '../themes/themes-layout-base.component';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { LocalizationLanguage } from '@shared/services/localization.service';

@Component({
    selector: 'language-switch-dropdown',
    templateUrl: './language-switch-dropdown.component.html'
})
export class LanguageSwitchDropdownComponent extends ThemesLayoutBaseComponent {
    protected languages: LocalizationLanguage[];
    protected currentLanguage: LocalizationLanguage;

    public constructor(injector: Injector, private readonly appLocalizationService: AppLocalizationService) {
        super(injector);
        this.languages = this.appLocalizationService.languages;
        this.currentLanguage = this.appLocalizationService.currentLanguage;
    }

    changeLanguage(language: LocalizationLanguage): void {
        this.appLocalizationService.currentLanguage = language;
    }
}
