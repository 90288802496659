<div class="form-group">

    <label [for]="name">{{ label | localize }}<span class="asterix" *ngIf="options?.isMandatory">*</span></label>

    <small *ngIf="options?.subText">{{ options.subText | localize }}</small>

    <ng-content></ng-content>

    <validation-messages [formCtrl]="questionControl"></validation-messages>

</div>
