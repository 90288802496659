<select
    *ngIf="questionControl"
    [id]="name"
    [formControl]="questionControl"
    [name]="name"
    class="form-control"
>
    <option [ngValue]="null" disabled>{{ 'Select' | localize }}</option>
    <option *ngFor="let choice of values" [value]="choice.value">
        {{ choice.display | localize }}
    </option>
</select>
