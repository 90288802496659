import { Component, OnInit, Injector } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { FaqService } from '@shared/services/faq.service';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { FAQCategoryOutput } from '@shared/models/faq/faqCategoryOutput';

@Component({
    selector: 'app-faq-list',
    templateUrl: './faq-list.component.html',
    styleUrls: ['./faq-list.component.scss'],
    animations: [appModuleAnimation()]
})
export class FaqListComponent extends AppComponentBase implements OnInit {
    filterText = '';
    currentFilterText: string;
    faqCategories: FAQCategoryOutput[] = [];
    filteredCategories: FAQCategoryOutput[] = [];
    selectedFilter: string;
    highlightFaq: string;

    constructor(injector: Injector, private _service: FaqService, private _appLocalizationService: AppLocalizationService, private route: ActivatedRoute) {
        super(injector);
    }

    ngOnInit() {
        this.highlightFaq = this.route.snapshot.fragment;
        const lang = this._appLocalizationService.currentLanguage.id;
        this._service.getAllFAQDescriptions(lang).subscribe((response) => {
            this.faqCategories = response.result.faqList;
            this.filteredCategories = this.faqCategories;
            if (this.highlightFaq) {
                this.filteredCategories.forEach((category) => {
                    category.faqDescriptions.forEach((faq) => {
                        faq.expanded = faq.id === this.highlightFaq;
                    });
                });
                setTimeout(this.scrollToFaq.bind(this), 500);
            }
        });
    }

    scrollToFaq(): void {
        const id = '#faq-' + this.highlightFaq;
        const element = document.querySelector(id);
        element?.scrollIntoView({ behavior: 'smooth' });
        element?.classList.add('highlight');
    }

    find(): void {
        this.currentFilterText = this.filterText
            .toLowerCase()
            .normalize('NFD')
            .replace(/\p{Diacritic}/gu, '')
            .trim();
        if (this.searching()) {
            const results: FAQCategoryOutput = {
                id: null,
                name: this.l('Result'),
                icon: null,
                languageId: null,
                faqDescriptions: this.faqCategories.flatMap((category) =>
                    category.faqDescriptions.filter(
                        (description) =>
                            category.name
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/\p{Diacritic}/gu, '')
                                .includes(this.currentFilterText) ||
                            description.question
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/\p{Diacritic}/gu, '')
                                .includes(this.currentFilterText) ||
                            description.answer
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/\p{Diacritic}/gu, '')
                                .includes(this.currentFilterText)
                    )
                )
            };
            this.filteredCategories = [results];
        } else {
            this.filteredCategories = this.faqCategories;
        }
    }

    searching(): boolean {
        return this.currentFilterText?.length > 0;
    }

    filter(categoryId: string): void {
        if (this.selectedFilter == null || this.selectedFilter !== categoryId) {
            this.selectedFilter = categoryId;
            this.filteredCategories = this.faqCategories.filter((category) => category.id === this.selectedFilter);
        } else {
            this.selectedFilter = null;
            this.filteredCategories = this.faqCategories;
        }
    }

    isSelected(categoryId: string): boolean {
        return this.selectedFilter === categoryId;
    }
}
