import { Component, OnInit, ViewChild, Input, Injector } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { AddressHistoryOutput } from '@shared/models/addresshistory/addressHistoryOutput';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { ValueFormatterParams } from '@ag-grid-enterprise/all-modules';
import { AddressHistoryType } from '@shared/models/addresshistory/addressHistoryType';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { ActivatedRoute } from '@angular/router';
import { CreateAddressHistoryFormerComponent } from '../create-address-history-former/create-address-history-former.component';
import { GridBtnUpdateComponent } from '@app/shared/grid/grid-btn-update/grid-btn-update.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { AppConsts } from '@shared/AppConsts';
import { LazyLoadEvent } from 'primeng/api';
import { UpdateAddressHistoryFormerComponent } from '../update-address-history-former/update-address-history-former.component';
import { InformationSourceDTO } from '@shared/models/request/informationSourceDTO';
import { AddressHistoryService } from '@shared/services/addressHistory.service';
import { GridBtnDeleteComponent } from '@app/shared/grid/grid-btn-delete/grid-btn-delete.component';
import { ToastrService } from 'ngx-toastr';
import {AppAuthService} from "@app/shared/common/auth/app-auth.service";

@Component({
    selector: 'request-address-history-list',
    templateUrl: './request-address-history-list.component.html',
    styleUrls: ['./request-address-history-list.component.scss'],
    providers: [DatePipe],
})
export class RequestAddressHistoryListComponent extends AppComponentBase implements OnInit {
    @ViewChild('agGrid') agGrid: AgGridAngular;
    @ViewChild('createFormerAddressModal')
        createFormerAddressModal: CreateAddressHistoryFormerComponent;
    @Input() selectedCandidat: CandidatOutput;
    @Input() requestPublicId: string;
    @Input() informationSource: InformationSourceDTO = InformationSourceDTO.Candidate;
    @Input() readOnly = true;

    bsModalRef: BsModalRef;
    filterText = '';
    columnDefs: any;
    rowData: AddressHistoryOutput[] = [];
    rowSelection: string;
    request: RequestOutput;
    candidat: CandidatOutput;
    selectedAddressHistory: AddressHistoryOutput;
    publicId: string;
    result: CandidatOutput;

    paginationNumberFormatter;
    paginationPageSize = 20;

    constructor(
        private readonly modalService: BsModalService,
        private readonly _appLocalizationService: AppLocalizationService,
        private readonly injector: Injector,
        private readonly _route: ActivatedRoute,
        private readonly datePipe: DatePipe,
        private readonly service: AddressHistoryService,
        private readonly toastr: ToastrService,
        private readonly _appAuthService: AppAuthService,
    ) {
        super(injector);
        this.rowSelection = 'single';

        this.paginationNumberFormatter = function (params) {
            return params.value.toLocaleString();
        };
    }

    async ngOnInit() {
        this.publicId = this._route.snapshot.paramMap.get('publicId');
        this.readOnly = this.readOnly || !this._appAuthService.hasPermission('Pages.Management.Candidat.Update');
        await this.initializeRowData();

        this.columnDefs = [
            {
                headerName: this._appLocalizationService.l('AddressHistoryAddress'),
                width: 300,
                valueGetter: this.concatAddress,
                sortable: true,
                filter: true,
                resizable: true,
            },
            {
                headerName: this._appLocalizationService.l('AddressHistoryFrom'),
                field: 'from',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.dateFormater.bind(this),
            },
            {
                headerName: this._appLocalizationService.l('AddressHistoryTo'),
                field: 'to',
                sortable: true,
                filter: true,
                resizable: true,
                valueFormatter: this.dateFormater.bind(this),
            },
            {
                headerName: this._appLocalizationService.l('AddressHistoryType'),
                width: 120,
                field: 'type',
                sortable: true,
                resizable: true,
                filter: true,
                valueFormatter: this.toEnum.bind(this),
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getEditBtnIfNotReadOnly(),
                cellRendererParams: { action: this.updateFormerAddress },
            },
            {
                headerName: '',
                width: 110,
                cellRendererFramework: this.getDeleteBtnIfNotReadOnly(),
                cellRendererParams: { action: this.deleteAddressHistory },
            },
        ];
    }

    onPageSizeChanged(e) {
        this.agGrid.api.paginationSetPageSize(Number(e));
        this.paginationPageSize = e;
    }

    getEditBtnIfNotReadOnly() {
        return this.readOnly ? null : GridBtnUpdateComponent;
    }

    getDeleteBtnIfNotReadOnly() {
        return this.readOnly ? null : GridBtnDeleteComponent;
    }

    async initializeRowData() {
        await this.service
            .getAddressHistoryByCandidat(this.selectedCandidat.publicId)
            .toPromise()
            .then((response) => {
                const historyListWithoutCurrent = this.removeCurrentAddress(response.result);
                this.rowData = historyListWithoutCurrent;
            });
    }

    removeCurrentAddress(addressHistories: AddressHistoryOutput[]): AddressHistoryOutput[] {
        const addressFormers: AddressHistoryOutput[] = [];

        addressHistories.forEach((element) => {
            // tslint:disable-next-line: triple-equals
            if (element.type === AddressHistoryType.former) {
                addressFormers.push(element);
            }
        });
        return addressFormers;
    }

    dateFormater(params: ValueFormatterParams) {
        return this.datePipe.transform(params.value, AppConsts.dateTimeFormatOnlyDate);
    }

    concatAddress(params) {
        if (params.data.address.app === null) {
            params.data.address.app = '';
        }
        const app = params.data.address.app !== '' ? params.data.address.app + ' - ' : '';
        return app + params.data.address.civicNo + ' ' + params.data.address.street + ', ' + params.data.address.city + ' ' + params.data.address.postalCode;
    }

    toEnum(params: ValueFormatterParams): string {
        return this._appLocalizationService.l(AddressHistoryType[params.value]);
    }

    createFormerAddress() {
        this.createFormerAddressModal.show();
    }

    updateFormerAddress = async (element: AddressHistoryOutput) => {
        this.selectedAddressHistory = element;
        this.bsModalRef = this.modalService.show(UpdateAddressHistoryFormerComponent, {
            initialState: {
                selectedCandidat: this.selectedCandidat,
                selectedAddressHistory: this.selectedAddressHistory,
            },
            backdrop: 'static',
        });
        this.bsModalRef.content.modalSave.subscribe((result) => {
            this.refresh();
        });
    };

    deleteAddressHistory = async (element: AddressHistoryOutput) => {
        this.service.deleteAddressHistory(element.publicId).subscribe((response) => {
            const addressHistory = response.result;

            this.initializeRowData();
            if (addressHistory.id != null) {
                this.showSuccess();
            } else {
                this.showErrorMessage();
            }
        });
    };

    showSuccess() {
        const title = this._appLocalizationService.l('AddressHistoryDelete');
        const successMessage = this._appLocalizationService.l('AddressHistoryDeleted');
        this.toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('AddressHistoryDeleteIssue');
        const errorMessage = this._appLocalizationService.l('AddressHistoryDeleteTryAgainLater');
        this.toastr.error(errorMessage, title);
    }

    refresh(event?: LazyLoadEvent) {
        this.initializeRowData();
    }
}
