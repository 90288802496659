import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Custom validator to ensure that at least one of the specified fields has a value
export function atLeastOneFieldRequired(...fields: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        // Check if at least one of the fields has a non-empty value
        const hasAtLeastOneValue = fields.some((field) => {
            const value = control.get(field)?.value;
            return !!value;
        });

        // We add an error to the relevant fields to highlight them
        fields.forEach((fieldName) => {
            const fieldControl = control.get(fieldName);

            if (!fieldControl) {
                return;
            }

            if (hasAtLeastOneValue) {
                if (fieldControl.errors?.atLeastOneRequired) {
                    const { atLeastOneRequired, ...otherErrors } = fieldControl.errors;
                    fieldControl.setErrors(Object.keys(otherErrors).length ? otherErrors : null);
                }
            } else {
                const existingErrors = fieldControl.errors || {};
                fieldControl.setErrors({ ...existingErrors, atLeastOneRequired: true });
            }
        });

        // We also return an error so that it can be added to the form itself for a generic message if we want
        return hasAtLeastOneValue ? null : { atLeastOneRequired: true };
    };
}
