import { Component, ViewChild, OnInit, Input } from '@angular/core';
import { AgGridAngular } from '@ag-grid-community/angular';
import { AppConsts } from '@shared/AppConsts';
import { DatePipe } from '@angular/common';
import {
    ValueFormatterParams,
    GridOptions,
    ColDef,
    PaginationChangedEvent,
    ProcessCellForExportParams,
    GridApi,
    GridReadyEvent,
    SortChangedEvent,
    FilterChangedEvent
} from '@ag-grid-enterprise/all-modules';
import { ToastrService } from 'ngx-toastr';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { RequestListOutput } from '@shared/models/request/requestListOutput';
import { Router } from '@angular/router';
import { RequestService } from '@shared/services/requests.service';
import { AdvancedSearchRequestInput } from '@shared/models/request/advancedSearchRequestInput';
import { SortModel } from '@shared/models/request/sortModel';
import { RequestStatusPillComponent } from '@app/requests/request-table/request-status-pill/request-status-pill.component';
import { Extensions } from '@shared/utils/extensions';
import { GridBtnCancelComponent } from '@app/shared/grid/grid-btn-cancel/grid-btn-cancel.component';
import { RequestVerificationStatusGridComponent } from '@app/requests/request-verification-status-grid/request-verification-status-grid.component';
import { Subject } from 'rxjs';
import { GridConfigurationChangedEvent } from '@shared/models/shared/gridConfigurationChangedEvent';
import LocalizationService from '@shared/services/localization.service';
import { AppAuthService } from '@app/shared/common/auth/app-auth.service';
import { DateTimeService } from '@app/shared/common/timing/date-time.service';
import { DialogService } from '@shared/services/dialog-service';

/**
 * A component used to display a list of requests. It allows sorting and filtering and the filters can be applied both in the table
 * and by using the {@link RequestTableComponent.filter} method.
 * @params *__includeLegend__* - Include the legend at the top of the table for the verification status. By default, it is set to true.
 * @params *__widgetMode__* - In widget mode, the table will not show contextual menu and will not be resizable.
 * @params *__initialFilters__* - The initial filters to apply to the table. By default, it will get the settings from the user's cache.
 * @params *__initialSorting__* - The initial sorting to apply to the table. By default, it will get the settings from the user's cache.
 * @params *__paginationPageSize__* - The number of requests to display per page. By default, it is set to 20.
 * @methods *__filter__* - Filter the requests based on the provided filters.
 * @methods *__sort__* - Sort the requests based on the provided sort models.
 * @methods *__resetSorting__* - Reset the sorting of the requests.
 * @methods *__resetFilters__* - Reset the filters of the requests.
 * @events *__gridInitialized__* - Event fired when the grid is initialized with the initial settings.
 */
@Component({
    selector: 'request-table',
    templateUrl: './request-table.component.html',
    providers: [DatePipe]
})
export class RequestTableComponent implements OnInit {
    @ViewChild('agGrid') private agGrid: AgGridAngular;
    @Input() protected includeLegend: boolean = true;
    @Input() protected widgetMode: boolean = false;
    @Input() protected initialFilters: AdvancedSearchRequestInput | null = null;
    @Input() protected initialSorting: SortModel[] | null = null;
    @Input() protected paginationPageSize: number = 20;
    public gridConfigurationChanged = new Subject<GridConfigurationChangedEvent>();
    protected gridOptions: GridOptions = null;
    private gridApi: GridApi;
    private blockLoadedEvent: CustomEvent<void> = new CustomEvent<void>('blockLoaded');
    private columnsAreResetting: boolean;

    constructor(
        private readonly _router: Router,
        private readonly toastr: ToastrService,
        private readonly datePipe: DatePipe,
        private readonly requestService: RequestService,
        private readonly localizationService: LocalizationService,
        private readonly appAuthService: AppAuthService,
        private readonly dateTimeService: DateTimeService,
        private readonly message: DialogService
    ) {
    }

    ngOnInit() {
        this.gridOptions = this.getGridOptions();
    }

    /* ---------------- Publicly available methods --------------- */

    // This function filters the requests based on the provided filters.
    public filter(filters: AdvancedSearchRequestInput): void {
        this.gridApi.setFilterModel({
            globalFilter: filters.globalFilter
                ? {filterType: 'text', type: 'contains', filter: filters.globalFilter}
                : null,
            status: filters.requestStatus?.length > 0
                ? {filterType: 'text', type: 'contains', filter: filters.requestStatus.join(';')}
                : null,
            assignedUserName: filters.assignedUsername
                ? {filterType: 'text', type: 'contains', filter: filters.assignedUsername}
                : null,
            creationTime: filters.from && filters.to
                ? {
                    filterType: 'date',
                    type: 'inRange',
                    dateFrom: this.dateTimeService.formatDate(filters.from, 'YYYY-MM-DD'),
                    dateTo: this.dateTimeService.formatDate(filters.to, 'YYYY-MM-DD')
                }
                : null,
            organizationName: filters.organizationName
                ? {filterType: 'text', type: 'contains', filter: filters.organizationName}
                : null,
            verifications: filters.verificationIds?.length > 0
                ? {filterType: 'text', type: 'contains', filter: filters.verificationIds.join(';')}
                : null
        });
    }

    // This function sorts the requests based on the provided sorting models.
    public sort(sorting: SortModel[]): void {
        this.gridApi.setSortModel(sorting);
    }

    // This function resets the sorting of the requests.
    public resetSorting(): void {
        this.gridApi.setSortModel(null);
        localStorage.removeItem('request-sort-model');
    }

    // This function resets the filters of the requests.
    public resetFilters(): void {
        this.gridApi.setFilterModel(null);
        localStorage.removeItem('request-filter-model');
    }

    // This function resets the visual configurations of the columns in the table
    public resetColumns(): void {
        this.columnsAreResetting = true;
        this.gridOptions.columnApi.resetColumnState();
    }

    /* --------------------- Private methods --------------------- */

    // Action to cancel a request associated to the button in the grid
    private cancelRequest = (requestListOutput: RequestListOutput): void => {
        const _ = this.message.confirm(this.localizationService.l('RequestCancelWarningMessage'), this.localizationService.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                if (requestListOutput.publicId !== '') {
                    const task = requestListOutput.status === RequestStatus.InProgressCustomerEzSign
                        ? this.requestService.cancelEzsignRequest(requestListOutput.publicId)
                        : this.requestService.cancelRequest(requestListOutput.publicId);
                    task.subscribe({
                        next: () => {
                            this.agGrid.api.setServerSideDatasource(this.requestService);
                            this.toastr.success(this.localizationService.l('RequestCanceled'), this.localizationService.l('CancelRequest'));
                        },
                        error: () => {
                            this.toastr.error(this.localizationService.l('RequestCanceledIssue'), this.localizationService.l('CancelRequest'))
                        }
                    });
                } else {
                    this.toastr.error(this.localizationService.l('RequestCanceledIssue'), this.localizationService.l('CancelRequest'))
                }
            }
        }, {
            confirmButtonText: this.localizationService.l('Yes'),
            cancelButtonText: this.localizationService.l('No')
        });
    }

    // This function is used to jump to a specific index (page) in the table
    private jumpToIndex(index: number): void {
        const rowNode = this.gridApi.getDisplayedRowAtIndex(index);

        if (rowNode && rowNode.data) {
            // If the row is already loaded, scroll to it
            this.gridApi.ensureIndexVisible(index, "bottom");
        } else {
            // If the row is not loaded yet, listen for data load events and retry
            const blockLoadedHandler = () => {
                const recheckRow = this.gridApi.getDisplayedRowAtIndex(index);
                if (recheckRow && recheckRow.data) {
                    // Once the row is loaded, scroll to it
                    window.removeEventListener("blockLoaded", blockLoadedHandler);
                    this.gridApi.ensureIndexVisible(index, "bottom");
                } else {
                    // Retry if not yet loaded
                    setTimeout(() => this.jumpToIndex(index), 100);
                }
            };

            window.addEventListener("blockLoaded", blockLoadedHandler);
            this.gridApi.ensureIndexVisible(index, "bottom");
        }
    }

    /*
        Initialize the filtering, sorting and pagination of the table based
        either on the options passed by the parent component or from the
        last settings used by the user from its local storage. This method fire an event
        to notify the parent component that the grid is initialized and with what settings.
    */
    private initializeGridState(): void {
        // Restore column state
        if (!this.widgetMode) {
            const columnState = JSON.parse(localStorage.getItem('request-column-state'));
            if (columnState) {
                this.gridOptions.columnApi.setColumnState(columnState);
                this.resetVerificationColumnFlex();
            }
        }

        // Setup filters
        if (this.initialFilters) {
            // If the parent component provided a filter model, we use it
            this.filter(this.initialFilters);
        } else if (!this.widgetMode) {
            const savedFilterJson = localStorage.getItem('request-filter-model');
            this.gridApi.setFilterModel(savedFilterJson ? JSON.parse(savedFilterJson) : this.getDefaultFilterModel());
        }

        // Setup sorting
        const defaultSorting = [{colId: 'creationTime', sort: 'desc'}];
        if (this.initialSorting) {
            // If the parent component provided a sorting model, we use it
            this.sort(this.initialSorting);
        } else if (this.widgetMode) {
            this.gridApi.setSortModel(defaultSorting);
        } else {
            const savedSortJson = localStorage.getItem('request-sort-model');
            this.gridApi.setSortModel(savedSortJson ? JSON.parse(savedSortJson) : defaultSorting);
        }

        // Setup pagination
        if (!this.widgetMode) {
            const savedPaginationIndex: number = JSON.parse(localStorage.getItem('request-pagination-index'));
            if (savedPaginationIndex) {
                this.jumpToIndex(savedPaginationIndex);
            }
            this.gridApi.addEventListener('paginationChanged', this.onPaginationChange.bind(this));
        }
    }

    // This function reset the flex property of the verification column to make sure the content of the table covers the width.
    private resetVerificationColumnFlex(): void {
        /* This function is necessary because Ag-Grid has some weird bug where the flex property of a column is not respected when
           we set the column state (when we call initializeGridState or reset). The colDef still shows a flex=1 property but the column
           object has flex=0 and the flex is not saved in the state. We also need to cast the flex as any because the flex property is
           private and doesn't offer a setter. */
        const col = this.gridOptions.columnApi.getColumn('verifications');
        (col as any).flex = 1;
    }

    /* ------------------------- Listeners ----------------------- */

    // Called when the sorting of the requests is changed. It's used to save the sorting model in the local storage.
    private onSortChanged(params: SortChangedEvent) {
        this.gridApi.paginationGoToFirstPage();
        localStorage.setItem('request-sort-model', JSON.stringify(params.api.getSortModel()));
        this.onConfigurationChanged();
    }

    // Called when the filters of the requests are changed. It's used to save the filter model in the local storage.
    private onFilterChanged(params: FilterChangedEvent) {
        this.gridApi.paginationGoToFirstPage();
        localStorage.setItem('request-filter-model', JSON.stringify(params.api.getFilterModel()));
        this.onConfigurationChanged();
    }

    // Called when the column state is changed. It's used to save the column state in the local storage.
    private onColumnStateChanges(): void {
        if (this.columnsAreResetting) {
            localStorage.removeItem('request-column-state');
            this.columnsAreResetting = false;
        } else {
            localStorage.setItem('request-column-state', JSON.stringify(this.gridOptions.columnApi.getColumnState()));
        }
        this.resetVerificationColumnFlex();
        this.onConfigurationChanged();
    }

    // Setups the server side data source and keep a reference to the api.
    private onGridReady(params: GridReadyEvent): void {
        this.gridApi = params.api;
        this.initializeGridState();
        this.agGrid.api.setServerSideDatasource(this.requestService);
    }

    // Called when the first data is rendered.
    private onFirstDataRendered(): void {
        this.onConfigurationChanged();
    }

    /* Each time the model is updated, we check if all data is loaded and dispatch a custom event.
    *  This is required because we use a server side data source and the existing events fire before the table
    *  is fully loaded so it leads to problem with the pagination.
    */
    private onModelUpdated(): void {
        if (!this.gridApi) return;

        const cacheBlockState = this.gridApi.getCacheBlockState();
        for (let row in cacheBlockState) {
            if (cacheBlockState[row].pageStatus === 'loaded') {
                window.dispatchEvent(this.blockLoadedEvent);
            }
        }
    }

    // Navigate to the request details page
    private onSelection(): void {
        const selectedRows = this.agGrid.gridOptions.api.getSelectedRows();
        if (selectedRows.length > 0) {
            const selectedRequests = selectedRows[0] as RequestListOutput;
            if (selectedRequests) {
                const shouldGoToWizard = this.appAuthService.hasPermission('Pages.Management.Requests.Create.Wizzard')
                    && selectedRequests.status === RequestStatus.Draft;
                this._router.navigate([shouldGoToWizard ? '/requests-wizard' : '/requests-details', selectedRequests.publicId]);
            }
        }
    }

    // When a user change page, we save the index of the page in the local storage
    private onPaginationChange(event: PaginationChangedEvent): void {
        const pageNumber = event.api.paginationGetCurrentPage();
        const index = pageNumber * this.paginationPageSize;
        localStorage.setItem('request-pagination-index', JSON.stringify(index));
        this.onConfigurationChanged();
    }

    /** Manually called whenever a configuration changes. It's used to notify the subscribers components of the changes.
     *  In this case, it's used to notify the parent component of the changes in the filters, sorting, pagination and
     *  column state so that the advanced form can reflect what's been done in the table.
     * */
    private onConfigurationChanged(): void {
        this.gridConfigurationChanged.next({
            filterModel: this.gridApi.getFilterModel(),
            sortModel: this.gridApi.getSortModel(),
            paginationSize: this.paginationPageSize,
            page: this.gridApi.paginationGetCurrentPage(),
            columnState: this.gridOptions.columnApi.getColumnState()
        });
    }

    /* --------------------- Formating methods ------------------- */

    // Localize the date
    private dateFormater(params: ValueFormatterParams): string {
        return params.value
            ? this.datePipe.transform(this.dateTimeService.toUtcDate(params.value).toLocal().toString(), AppConsts.dateTimeFormat)
            : '';
    }

    /* This function is used to process the cell for export to an Excel or csv file.
    *  If a column has a valueFormatter, we use it to format the value, otherwise we return the value as is.
    */
    private processCellForExport(params: ProcessCellForExportParams) {
        const colDef = params.column.getColDef();

        if (colDef.valueFormatter) {
            return colDef.valueFormatter({
                value: params.value,
                column: params.column,
                api: params.api,
                columnApi: params.columnApi,
                context: params.context,
                data: params.node.data,
                node: params.node,
                colDef: colDef
            });
        }

        return params.value;
    }

    /* ------------------- Default Grid Settings ----------------- */

    // Returns the default filter model which is the requests created in the last month.
    private getDefaultFilterModel() {
        const aMonthAgo = new Date();
        aMonthAgo.setMonth(aMonthAgo.getMonth() - 1);

        return {
            creationTime: {
                filterType: 'date',
                type: 'inRange',
                dateFrom: this.dateTimeService.formatDate(aMonthAgo, 'YYYY-MM-DD'),
                dateTo: this.dateTimeService.formatDate(new Date(), 'YYYY-MM-DD')
            }
        };
    }

    // Returns the grid options for the table
    private getGridOptions(): GridOptions {
        const canGoToDetailsPage = this.appAuthService.hasPermission('Pages.Management.Requests.Details');

        return <GridOptions>{
            rowModelType: 'serverSide',
            animateRows: true,
            defaultExportParams: {
                processCellCallback: this.processCellForExport.bind(this)
            },
            onGridReady: this.onGridReady.bind(this),
            onFirstDataRendered: this.onFirstDataRendered.bind(this),
            onSortChanged: this.widgetMode ? null : this.onSortChanged.bind(this),
            onFilterChanged: this.widgetMode ? null : this.onFilterChanged.bind(this),
            onModelUpdated: this.onModelUpdated.bind(this),
            onColumnMoved: this.widgetMode ? null : this.onColumnStateChanges.bind(this),
            onColumnPinned: this.widgetMode ? null : this.onColumnStateChanges.bind(this),
            onColumnVisible: this.widgetMode ? null : this.onColumnStateChanges.bind(this),
            onColumnResized: this.widgetMode ? null : this.onColumnStateChanges.bind(this),
            onRowSelected: canGoToDetailsPage ? this.onSelection.bind(this) : null,
            rowSelection: canGoToDetailsPage ? 'single' : null,
            defaultColDef: {resizable: !this.widgetMode, suppressMenu: this.widgetMode},
            columnDefs: this.getColumnDef(),
            pagination: true, // This always needs to be true even in widget mode otherwise the table breaks
            paginationPageSize: this.paginationPageSize,
            // If it's a widget, the user can't change page so no need to keep multiple pages in cache
            cacheBlockSize: this.widgetMode ? this.paginationPageSize : this.paginationPageSize * 10, // Load 10 pages at a time
            maxBlocksInCache: this.widgetMode ? 1 : 5 // Keep up to (5 * cacheBlockSize = 50) pages max in cache

        };
    }

    // Returns the details and settings for the column of the table
    private getColumnDef(): ColDef[] {
        const commonColumns: ColDef[] = [
            {
                headerName: this.localizationService.l('ClientId'),
                field: 'clientId',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 100,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: this.localizationService.l('Candidate'),
                field: 'candidatName',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 200,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: this.localizationService.l('Organization'),
                field: 'organizationName',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 150,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: this.localizationService.l('Status'),
                field: 'status',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 180,
                suppressMenu: true,
                cellRendererFramework: RequestStatusPillComponent,
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: this.localizationService.l('Creation'),
                field: 'creationTime',
                sortable: true,
                filter: 'agDateColumnFilter',
                width: 100,
                suppressFiltersToolPanel: true,
                sort: 'desc',
                valueFormatter: this.dateFormater.bind(this),
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: this.localizationService.l('LastModification'),
                field: 'lastModificationTime',
                sortable: true,
                filter: 'agDateColumnFilter',
                width: 130,
                filterParams: {
                    filterOptions: ['inRange'],
                    suppressAndOrCondition: true
                },
                valueFormatter: this.dateFormater.bind(this),
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: this.localizationService.l('Verifications'),
                field: 'verifications',
                colId: 'verifications',
                cellRendererFramework: RequestVerificationStatusGridComponent,
                autoHeight: true,
                sortable: false,
                filter: 'agTextColumnFilter',
                suppressMenu: true,
                minWidth: 200,
                flex: 1,
                resizable: false,
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            },
            {
                headerName: '',
                cellRendererFramework: GridBtnCancelComponent,
                cellRendererParams: {action: this.cancelRequest},
                width: 80,
                resizable: false,
                autoHeight: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                lockVisible: true
            },
            {
                headerName: '',
                field: 'globalFilter',
                sortable: false,
                filter: 'agTextColumnFilter',
                filterParams: {filterOption: ['contains']},
                hide: true,
                suppressColumnsToolPanel: true,
                lockVisible: true
            }
        ];

        if (this.appAuthService.hasPermission('Pages.Management.Requests.CanSeePrivateInformation')) {
            commonColumns.splice(6, 0, {
                headerName: this.localizationService.l('AssignedTo'),
                field: 'assignedUserName',
                sortable: true,
                filter: 'agTextColumnFilter',
                width: 150,
                filterParams: {
                    filterOptions: ['contains'],
                    suppressAndOrCondition: true
                },
                getQuickFilterText: Extensions.replaceSpecialCharactersParams.bind(this),
                cellStyle: {
                    'padding-left': '7px',
                    'padding-right': '7px'
                }
            });
        }

        return commonColumns;
    }
}
