import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { RequestFilesService } from './request-files.service';
import { fileType } from '@shared/models/files/fileType';
import * as html2pdf from 'html2pdf.js';
import { RequestInput } from '@shared/models/request/requestInput';
import { filterBlankPagesOut } from '@shared/helpers/PDFRendererHelper';
import {FilesOutput} from "@shared/models/files/filesOutput";

@Injectable()
export class RequestReportsService {
    constructor(
        private readonly requestFileService: RequestFilesService
    ) { }

    updateReport(request: RequestInput, fileType: fileType): Observable<FilesOutput[]> {
        const pages = Array.from(document.querySelectorAll('app-report-page'));
        return this.uploadGeneratedReportPDF(this.buildPDF(pages, 0), request, fileType, this.getRequestFileName(request));
    }

    uploadGeneratedReportPDF(worker, request: RequestInput, fileType: fileType, fileName: string) {
        return (request.generatedReportPublicId
            ? this.requestFileService.deleteFile(request.generatedReportPublicId, request.id)
                .pipe(
                    catchError(() => of(null))
                )
            : of(null)
        ).pipe(
            switchMap(() => from(worker.get('pdf').output('blob'))),
            switchMap((blob: Blob) => {
                const file = this.requestFileService.blobToFile(blob, fileName);
                return this.requestFileService.uploadFiles([file], fileType, request.id);
            }),
            tap((files: FilesOutput[]) => {
                if (files.length > 0) {
                    request.generatedReportPublicId = files[0].publicId;
                }
            })
        );
    }

    private getRequestFileName(request: RequestInput) {
        const firstName = request.candidat.firstName.trim();
        const lastName = request.candidat.lastName.toUpperCase().trim();

        return `${request.organization.clientId}_${firstName}${lastName}.pdf`
            .replace(' ', '')
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '');
    }

    private buildPDF(elements: Element[], marginSize: number) {
        let worker = html2pdf()
            .set({
                margin: marginSize,
                image: { type: 'jpeg', quality: 75 },
                jsPDF: {
                    unit: 'in',
                    format: 'letter',
                    orientation: 'portrait',
                },
                html2canvas: {
                    scrollX: 0, scrollY: 0,
                    dpi: 192,
                    scale: 4
                },
            })
            .from(elements[0])
            .then(filterBlankPagesOut);

        if (elements.length > 1) {
            worker = worker.toPdf();
            elements.slice(1).forEach((element) => {
                worker = worker
                    .get('pdf')
                    .then((pdf) => {
                        pdf.addPage();
                    })
                    .from(element)
                    .toContainer()
                    .toCanvas()
                    .then(filterBlankPagesOut)
                    .toPdf();
            });
        }
        return worker;
    }
}
