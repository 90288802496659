import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { SingleSelectionListComponent } from '@app/shared/form/base-inputs/single-selection-list/single-selection-list.component';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';
import { DropdownChoice } from '@shared/models/dropdownChoice';

/**
 * This component is a dropdown input for selecting a language. It will use the {@link LocalizationService} to get the list of languages and return
 * the selected language's name as the value.
 * @params *__parentFormControl__* - The form group in which we will add this question's control.
 * @params *__isMandatory__* - If the question is mandatory or not. If true, a required validator will be added to the control.
 * @params *__startingValue__* - *(Optional)* The starting value for the input.
 * @params *__options__* - *(Optional)* Additional options for the question. By default, it only has the value of the *isMandatory* input.
 * @params *__disabled__* - *(Optional)* If the list should be disabled.
 */
@Component({
    standalone: true,
    selector: 'language-dropdown-input[parentFormControl]',
    imports: [FormGroupComponent, SingleSelectionListComponent],
    templateUrl: 'language-dropdown-input.component.html'
})
export class LanguageDropdownInputComponent implements OnInit {
    @Input() protected readonly parentFormControl!: FormGroup;
    @Input() protected readonly isMandatory = false;
    @Input() protected readonly startingValue: string | null = null;
    @Input() protected options: FormQuestionOptions = {};
    @Input() protected readonly disabled: boolean = false;

    public static readonly formControlName = 'language';

    protected readonly languages: DropdownChoice[];

    constructor(private readonly localizationService: AppLocalizationService) {
        this.languages = this.localizationService.languages.map((l) => ({
            value: l.name,
            display: l.displayName
        }));
    }

    ngOnInit(): void {
        this.options = {
            ...this.options,
            isMandatory: this.isMandatory
        };
    }

    protected get name(): string {
        return LanguageDropdownInputComponent.formControlName;
    }

    protected get label(): string {
        return 'Language';
    }
}
