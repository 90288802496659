import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { emailAddressValidator } from '@shared/utils/validation/email-validator.directive';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { StandardInputComponent } from '@app/shared/form/base-inputs/standard-input/standard-input.component';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';

/**
 * This is a standard email input for a form anywhere on the website. If you need to add additional composed validators for special cases like
 * {@link atLeastOneFieldRequired}, you can add them to the parent's controller after the form is created using the {@link EmailInputComponent.formControlName}.
 * If you need to pass validators for this field only, you can use the {@link FormQuestionOptions.extraValidators} property.
 * @params *__parentFormControl__* - The form group in which we will add this question's control.
 * @params *__isMandatory__* - *(Optional)* If the question is mandatory or not. If true, a required validator will be added to the control.
 * @params *__startingValue__* - *(Optional)* The starting value for the input.
 * @params *__options__* - *(Optional)* Additional options to be passed to the form question component. By default, the following are added:
 * - *isMandatory*: The value of the isMandatory parameter.
 * - *maxLength*: 256
 * - *extraValidators*: An array containing the email validator with any other validators that was passed along.
 */
@Component({
    standalone: true,
    selector: 'email-input[parentFormControl]',
    imports: [FormGroupComponent, StandardInputComponent],
    templateUrl: 'email-input.component.html'
})
export class EmailInputComponent implements OnInit {
    @Input() protected readonly parentFormControl: FormGroup;
    @Input() protected readonly isMandatory = false;
    @Input() protected readonly startingValue: string | null = null;
    @Input() protected options: FormQuestionOptions = {};

    public static readonly formControlName = 'email';

    ngOnInit(): void {
        this.options = {
            ...this.options,
            isMandatory: this.isMandatory,
            maxLength: 256,
            extraValidators: [...(this.options.extraValidators || []), emailAddressValidator]
        };
    }

    protected get name(): string {
        return EmailInputComponent.formControlName;
    }

    protected get label(): string {
        return 'Email';
    }
}
