<div *ngIf="questionControl" class="checkbox-list">
    <label *ngFor="let choice of values" class="form-check">
        <input
            type="checkbox"
            class="form-check-input"
            [disabled]="maxSelectionsReached"
            [checked]="choiceIsChecked(choice.value)"
            (change)="onMultiSelectionChange(choice.value, $event)"
        />
        {{ choice.display }}
    </label>
</div>
