<form-group
    [label]="label"
    [name]="name"
    [options]="options"
    [parentFormControl]="parentFormControl"
>
    <input
        #inputControl
        [id]="name"
        [formControl]="formControl"
        type="text"
        [name]="name"
        Class="form-control"
        [maxlength]="options?.maxLength || null"
        [mask]="mask"
        prefix="+ 1 "
        [specialCharacters]="[ '+', '(' , ')' , ' ', '-' ]"
        [dropSpecialCharacters]="false"
        [showMaskTyped]="true"/>
</form-group>
