import { FormQuestionTranslation } from '@shared/models/custom-form/formQuestionTranslation';

export enum FormQuestionType {
    text = 0,
    boolean = 1,
    list = 2,
    standard,
    file,
    number,
    range
}

export interface FormQuestionConfiguration {
    id: number;
    index?: number;
    isMandatory?: boolean;
    maxLength?: number;
    isMultiSelection?: boolean;
    translations?: FormQuestionTranslation[];
    type?: FormQuestionType;
    answer?: string;
    answerChoicesIndexes?: number[];
}
