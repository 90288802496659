import { Component, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject, Observable } from 'rxjs';
import { CustomFormService } from '@shared/services/custom-form.service';
import { VerificationForm } from '@shared/models/custom-form/verificationForm';
import { NgIf, NgFor } from '@angular/common';
import { takeUntil } from 'rxjs/operators';
import { CustomFormQuestionComponent } from '@app/custom-form/custom-form-question/custom-form-question.component';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

/**
 * @params *__verificationId__* - The id of the verification for which to fetch the form model. Can alternatively pass the form id.
 * @params *__formId__* - The id of the form model to fetch. Can alternatively pass the verification id.
 * @params *__existingFormControl__* - The control in which we will add the question's controls.
 * @params *__verificationFormModel__* - The configuration of the form and its questions.
 * @params *__ignoreIsMandatory__* - This is to prevent forcing the agent to fill the form.
 * @params *__editMode__* - This is when we don't want the model to change like with an existing form.
 * @params *__formLanguage__* - The language of the form.
 */
@Component({
    standalone: true,
    selector: 'custom-form[existingFormControl]',
    imports: [NgIf, NgFor, CustomFormQuestionComponent],
    templateUrl: './custom-form.component.html'
})
export class CustomFormComponent implements OnDestroy, OnChanges {
    @Input() private readonly verificationId: number;
    @Input() private readonly formId: string;
    @Input() protected readonly existingFormControl!: FormGroup;
    @Input() protected verificationFormModel: VerificationForm | null = null;
    @Input() protected readonly ignoreIsMandatory: boolean = false; // This is to prevent forcing the agent to fill the form
    @Input() protected readonly editMode: boolean = false;
    @Input() protected formLanguage: string | null = null;


    private readonly destroy$ = new Subject<void>();

    constructor(
        protected readonly localizationService: AppLocalizationService,
        private readonly formService: CustomFormService,
        private readonly cdr: ChangeDetectorRef
    ) {
        this.formLanguage = this.localizationService.currentLanguage.name;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!this.editMode && (changes['verificationId']?.currentValue || changes['formId']?.currentValue)) {
            this.getNewVerificationForm();
        }
    }

    public get formConfiguration(): VerificationForm | null {
        return this.verificationFormModel;
    }

    // Fetch the verification form from the server
    private getNewVerificationForm() {
        if (!this.formId && !this.verificationId) {
            return;
        }

        const form$: Observable<VerificationForm> = this.formId
            ? this.formService.getVerificationForm(this.formId)
            : this.formService.getVerificationFormFromVerificationId(this.verificationId);

        form$.pipe(takeUntil(this.destroy$)).subscribe((verificationForm) => {
            CustomFormComponent.RemoveAllControls(this.existingFormControl);
            this.verificationFormModel = verificationForm;
            this.verificationFormModel?.questions.sort((a, b) => a.index - b.index);
            this.cdr.detectChanges();
        });
    }

    // Empty the form control without replacing the reference to the form control
    private static RemoveAllControls(form: FormGroup) {
        Object.keys(form.controls).forEach((controlName) => {
            form.removeControl(controlName);
        });
    }
}
