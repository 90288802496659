import { Component, Injector, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { appModuleAnimation } from '@shared/animations/routerTransition';
import { AppComponentBase } from '@shared/common/app-component-base';
import { RequestInput } from '@shared/models/request/requestInput';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { RequestService } from '@shared/services/requests.service';
import { WizardComponent } from 'angular-archwizard';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs/internal/observable/of';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';
import { RequestStatus } from '@shared/models/request/requestStatus';
import { VerificationOutput } from '@shared/models/verification/verificationOutput';
import { verificationStatus } from '@shared/models/verification/verificationStatus';
import { VerificationService } from '@shared/services/verification.service';
import { RequestVerificationService } from '@shared/services/requestVerification.service';
import { CandidatOutput } from '@shared/models/candidat/candidatOutput';
import { BehaviorSubject, Subject } from 'rxjs';
import { RequestStatusLegend } from '@shared/models/request/requestStatusLegend';

@Component({
    selector: 'app-wizzard-request',
    templateUrl: './wizzard-request.component.html',
    styleUrls: ['./wizzard-request.component.scss'],
    animations: [appModuleAnimation()]
})
export class WizzardRequestCreatorComponent extends AppComponentBase implements OnInit {
    @ViewChild(WizardComponent) public wizard: WizardComponent;
    candidateEmailSent: string;
    requestOrganizationId = 0;
    disabledNavigation = true;
    publicId: string;
    request: RequestInput;
    title: string;
    subTitle: string;
    verifications: VerificationOutput[] = null;
    organizationChanged = new BehaviorSubject<boolean>(false);
    candidateChanged = new BehaviorSubject<CandidatOutput>(null);
    verificationsChanged = new BehaviorSubject(false);
    requestSaved = new BehaviorSubject(false);
    beforeRequestSaved = new Subject<null>();

    mandatoryFilesNotOptional = [];
    constructor(
        injector: Injector,
        private _route: ActivatedRoute,
        private requestService: RequestService,
        private _router: Router,
        private _appLocalizationService: AppLocalizationService,
        public _toastr: ToastrService,
        private verificationService: VerificationService,
        private requestVerificationService: RequestVerificationService
    ) {
        super(injector);
    }

    async ngOnInit(): Promise<void> {
        if (this._route.snapshot.paramMap.get('publicId') !== null && this._route.snapshot.paramMap.get('publicId') !== '') {
            this.publicId = this._route.snapshot.paramMap.get('publicId');
            this.loadRequest();
            this.title = this.l('Requests');
            this.subTitle = this.l('UpdateRequest');
        } else {
            this.title = this.l('RequestWizzardIntroTitle');
            this.subTitle = this.l('RequestWizzardIntroSubtitle');
            this.request = new RequestInput();
            this.request.reportLanguage =
                this.request.reportLanguage === 'french' || this.request.reportLanguage === 'english' ? this.request.reportLanguage : this.request.organization.language;
        }
    }

    setMandatoryFilesNotOptional(mandatoryFiles) {
        this.mandatoryFilesNotOptional = mandatoryFiles;
    }

    async loadRequest() {
        await this.requestService
            .getRequestByPublicId(this.publicId)
            .toPromise()
            .then((data) => {
                const requestOutput: RequestOutput = data.result;
                this.request = RequestInput.FromOutput(requestOutput);
            });
        this.requestOrganizationId = this.request.organization.id;
        this.request.reportLanguage =
            this.request.reportLanguage === 'french' || this.request.reportLanguage === 'english' ? this.request.reportLanguage : this.request.organization.language;
        await this.getVerifications();
    }

    isRequestCreated(): boolean {
        return this.request?.id !== 0;
    }

    isRequestDraft(): boolean {
        return this.request?.status === RequestStatus.Draft;
    }

    isRequestSendCandidate(): boolean {
        return this.request?.status === RequestStatus.InProgressClient;
    }

    isRequestSendGuardium(): boolean {
        return this.request?.status === RequestStatus.New;
    }

    requestRequiresFiles(): boolean {
        return this.mandatoryFilesNotOptional.length > 0;
    }


    async delete() {
        this.message.confirm(this.l('RequestDeleteWarningMessage'), this.l('AreYouSure'), (isConfirmed) => {
            if (isConfirmed) {
                try {
                    this.requestService.deleteRequest(this.request.publicId).subscribe((response) => {
                        this.showDeleteSuccess();
                        this.goToRequestList();
                    });
                } catch (e) {
                    this.showDeleteErrorMessage();
                }
            }
        }, {
            confirmButtonText: this.l('Yes'),
            cancelButtonText: this.l('No')
        });
    }

    showDeleteSuccess() {
        const title = this._appLocalizationService.l('DeleteRequest');
        const successMessage = this._appLocalizationService.l('RequestDeleted');
        this._toastr.success(successMessage, title);
    }

    showDeleteErrorMessage() {
        const title = this._appLocalizationService.l('DeleteRequest');
        const errorMessage = this._appLocalizationService.l('RequestDeletedIssue');
        this._toastr.error(errorMessage, title);
        return of(null);
    }

    removeDuplicates(myArr, prop) {
        return myArr.filter((obj, pos, arr) => arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos);
    }

    async save(quit: boolean) {
        this.beforeRequestSaved.next();

        this.filterDeletedUnsavedVerifications();
        let requestVerifications = this.request.requestVerifications?.map((verif) => ({
            ...verif,
            requestId: this.request.id,
            expressPrice: verif.expressPrice || 0,
        }));

        requestVerifications = this.removeDuplicates(requestVerifications, 'verificationId');

        if (this.isRequestCreated() && this.request.candidat.id !== 0) {
            this.requestVerificationService
                .updateRequestVerifications(requestVerifications)
                .pipe(
                    catchError((error) => {
                        this.showErrorMessage();
                        return of(null);
                    })
                )
                .subscribe(({ result }) => {
                    this.request.requestVerifications = result;
                    requestVerifications = result;
                });

            const requestToSave = this.request;
            requestToSave.requestVerifications = [];
            this.requestService
                .updateRequest(requestToSave)
                .pipe(
                    catchError((error) => {
                        this.showErrorMessage();
                        return of(null);
                    })
                )
                .subscribe((response) => {
                    this.request = RequestInput.FromOutput(response.result);
                    this.request.requestVerifications = requestVerifications;
                    this.verificationsChanged.next(true);
                    this.showSuccess();
                });
        } else {
            //2 is step candidat, we can only create request when organization and candidat are filled.
            if (this.wizard.currentStepIndex >= 2) {
                this.filterDeletedUnsavedVerifications();
                this.requestService
                    .createRequest(this.request)
                    .pipe(
                        catchError((error) => {
                            this.showErrorMessage();
                            return of(null);
                        })
                    )
                    .subscribe((response) => {
                        this.request = RequestInput.FromOutput(response.result);
                        this.showSuccess();
                    });
            }
        }
        this.requestSaved.next(true);
        if (quit) {
            this.goToRequestList();
        }
    }

    filterDeletedUnsavedVerifications() {
        //To ensure no verification deleted but not saved are send to back end
        this.request.requestVerifications = this.request.requestVerifications.filter((verif) => verif !== undefined);
    }

    goToRequestList() {
        this._router.navigate(['/requests']);
    }

    showSuccess() {
        const title = this._appLocalizationService.l('UpdateRequest');
        const successMessage = this._appLocalizationService.l('RequestUpdated');
        this._toastr.success(successMessage, title);
    }

    showErrorMessage() {
        const title = this._appLocalizationService.l('UpdateRequest');
        const errorMessage = this._appLocalizationService.l('RequestUpdateIssue');
        this._toastr.error(errorMessage, title);
    }

    getStatusLabel(status: number): string {
        return this.l(RequestStatusLegend.getLegendFromId(status)?.name);
    }

    onRequestChange(event: RequestOutput) {
        //Initialize verifications only if organization has changed
        if (this.request.organization.id !== this.requestOrganizationId) {
            this.requestOrganizationId = this.request.organization.id;
            this.getVerifications();
            this.request.candidat = new CandidatOutput();
            this.organizationChanged.next(true);
        }
    }

    getOldEmail(savedEmail: string) {
        this.candidateEmailSent = savedEmail;
    }

    async getVerifications() {
        await this.verificationService
            .getVerificationsLinkedToOrganization(this.request.organization.publicId)
            .toPromise()
            .then((value) => {
                // Extracted function to perform the sorting
                this.verifications = [...value.result].filter((v) => v.status === verificationStatus.Actif).sort(this.compareCodes);
                return this.verifications;
            });
    }

    private compareCodes(a, b) {
        const textA = a.code.toUpperCase();
        const textB = b.code.toUpperCase();
        if (textA < textB) {
            return -1;
        } else if (textA > textB) {
            return 1;
        }
        return 0;
    }
}
