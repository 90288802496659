import { Component, Input, OnInit, inject } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { CustomSvgIconService } from './custom-svg-icons.service';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-svg-icon',
    template: '<div class="svg-wrapper {{class}}" [style.color]="color" [style]="style" [innerHTML]="svgIcon$ | async"></div>'
})
export class SvgIconComponent implements OnInit {
    @Input() class: string = "";
    @Input() iconName: string;
    @Input() color: string;
    @Input() style: string;
    public svgIcon$: Observable<SafeHtml>;
    private customSvgIconService: CustomSvgIconService = inject(CustomSvgIconService);

    ngOnInit() {
        this.svgIcon$ = this.customSvgIconService.getIcon(this.iconName);
    }
}
