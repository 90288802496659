<div class="row align-items-center">
  <div class="mr-auto col-auto">
    <h5>{{ title }} <ng-content></ng-content></h5>
    <h6 style="font-weight:normal;">{{ description }}</h6>
  </div>
  <div class="col mt-3 mt-md-0 mb-2">
    <div class="text-end ">
      <a href="javascript:;" (click)="addFile()" class="btn btn-primary" *ngIf="!allReadOnly"
        [buttonBusy]="addRequestFiles.saving">
        <i class="fa fa-plus"></i>
        {{'UploadFiles' | localize}}
      </a>
    </div>
  </div>
</div>
<div>
  <grid-pagination class="grid-pagination" (sizeValue)="onPageSizeChanged($event)"></grid-pagination>
  <ag-grid-angular localizeText #agGrid class="ag-theme-material gardium-small-grid"
    [rowData]="rowData" [columnDefs]="columnDefs" [suppressCellSelection]="true" [suppressRowHoverHighlight]="true"
    [paginationNumberFormatter]="paginationNumberFormatter" (gridReady)="onGridReady($event)"
    [defaultColDef]="defaultColDef" [paginationPageSize]="paginationPageSize" [pagination]="true">
  </ag-grid-angular>
</div>
<add-request-files (modalSave)="getAllFiles()" #addRequestFiles [fileType]="fileListType"></add-request-files>
