import { ValidatorFn, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { AppConsts } from '@shared/AppConsts';

export const emailAddressValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    if (control.value && typeof control.value === 'string') {
        //Valid pattern test@test.com || test.test@test.ca || test@test.test.com || test@test.test.ca
        if (!AppConsts.regex.email.test(control.value)) {
            return { BadEmailAddress: { requiredPattern: AppConsts.patterns.email } };
        }

        return null;
    }
};
