import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormGroupComponent } from '@app/shared/form/form-group/form-group.component';
import { TextTransform } from '@app/shared/directives/text-transform.directive';
import { StandardInputComponent } from '@app/shared/form/base-inputs/standard-input/standard-input.component';
import { FormQuestionOptions } from '@shared/models/custom-form/formQuestionOptions';

/**
 * Enum representing the different parts of a name.
 */
export enum NameParts {
    FirstName = 'firstName',
    LastName = 'lastName'
}

/**
 * Component that represents a form input asking for a name part. Either First Name or Last Name.
 * @params *__parentFormControl__* - The form group in which we will add this question's control.
 * @params *__nameType__* - The type of name part we are asking for. See the {@link NameParts } enum.
 * @params *__isMandatory__* - *(Optional)* If the question is mandatory or not. If true, a required validator will be added to the control.
 * @params *__startingValue__* - *(Optional)* The starting value for the input.
 * @params *__options__* - *(Optional)* Options for the question. See {@link FormQuestionOptions } for more details. By default, the followings are applied:
 * - maxLength
 * - isMandatory
 * - appTextTransform
 */
@Component({
    standalone: true,
    selector: 'name-input[parentFormControl][nameType]',
    imports: [FormGroupComponent, StandardInputComponent],
    templateUrl: 'name-input.component.html'
})
export class NameInputComponent implements OnInit {
    @Input() protected readonly parentFormControl: FormGroup;
    @Input() protected readonly nameType: NameParts;
    @Input() protected readonly isMandatory = false;
    @Input() protected readonly startingValue: string | null = null;
    @Input() protected options: FormQuestionOptions;

    ngOnInit(): void {
        this.options = {
            maxLength: 256,
            isMandatory: this.isMandatory,
            appTextTransform: TextTransform.Title
        };
    }

    protected get questionLabel(): string {
        return NameInputComponent.getCapitalisedName(this.nameType);
    }

    private static getCapitalisedName(namePart: NameParts): string {
        return namePart.toString()[0].toUpperCase() + namePart.toString().slice(1);
    }
}
