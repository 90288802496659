import { NgModule } from '@angular/core';
import { GridLocalizationDirective } from './grid/grid-localization-directives';
import { GridStyleRowInactiveDirective } from './grid/grid-style-row-inactive.directive';
import { BtnScrollTopComponent } from '@app/shared/btn-scroll/btn-scroll-top';
import { CommonModule } from '@angular/common';
import { AddressFieldsComponent } from './address/address-fields/address-fields.component';
import { UtilsModule } from '@shared/utils/utils.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputMaskModule } from 'primeng/inputmask';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { NgxMaskModule } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule} from 'ngx-bootstrap/timepicker';
import { GridUpdateListDirective } from './grid/grid-updateList-directive';

const components = [
    GridLocalizationDirective,
    GridUpdateListDirective,
    GridStyleRowInactiveDirective,
    BtnScrollTopComponent,
    AddressFieldsComponent,
];
@NgModule({
    imports: [
        CommonModule,
        UtilsModule,
        FormsModule,
        ReactiveFormsModule,
        InputMaskModule,
        TypeaheadModule,
        NgxMaskModule,
        BsDatepickerModule,
        TimepickerModule,
    ],
    declarations: [
        ...components
    ],
    exports: [...components],
})
export class SharedModule {}
