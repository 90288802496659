import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Inject } from '@angular/core';
import { RequestInput } from '@shared/models/request/requestInput';
import { RequestOutput } from '@shared/models/request/requestOutput';
import { Observable } from 'rxjs';
import { RequestAllInformation } from '@shared/models/request/requestInformation/requestInformation';
import { map } from 'rxjs/operators';
import { environment as env } from 'environments/environment';
import { IServerSideDatasource, IServerSideGetRowsParams } from '@node_modules/@ag-grid-enterprise/all-modules';
import { overallAdjustLocalDateRangeToUtcDateTimeRange } from '@shared/utils/date-time/adjust-local-date-range-to-utc-date-time-range';
import { RequestsFilteredOutput } from '@shared/models/request/requestsFilteredOutput';
import { IAjaxResponse } from '@shared/models/ajax-response';

@Injectable()
export class RequestService implements IServerSideDatasource {
    private http: HttpClient;
    private readonly baseUrl: string;

    constructor(@Inject(HttpClient) http: HttpClient) {
        this.http = http;
        this.baseUrl = env.api;
    }

    getRequest(publicId: string) {
        return this.getRequestByPublicId(publicId).pipe(map((r: any) => r.result));
    }

    createRequest(request: RequestInput): Observable<RequestOutput> {
        const url_ = this.getUrl('/api/requests');
        return this.http.post<RequestOutput>(url_, request);
    }

    getRequestByPublicId(publicId: string): Observable<any> {
        const url_ = this.getUrl('/api/requests');
        const params = new HttpParams().set('publicId', publicId);
        return this.http.get<any>(url_, { params });
    }

    updateRequest(request: RequestInput): Observable<any> {
        const url_ = this.getUrl('/api/requests');
        return this.http.put<any>(url_, request);
    }

    cancelRequest(publicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/Cancel/${publicId}`);
        return this.http.put<any>(url_, publicId);
    }

    deleteRequest(publicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/Delete/${publicId}`);
        return this.http.delete<any>(url_);
    }

    updateAllRequest(anonymousInput: RequestAllInformation): Observable<any> {
        const url_ = this.getUrl('/api/requests/UpdateAllRequestInfo');
        return this.http.put<any>(url_, anonymousInput);
    }

    updateRequestToNew(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/updateRequestToNew/${requestPublicId}`);
        return this.http.get<any>(url_);
    }

    getRequestForOrganizationWithNotInvoice(organizationPublicId): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetRequestForOrganizationWithNotInvoice');
        const params = new HttpParams().set('organizationPublicId', organizationPublicId);
        return this.http.get<any>(url_, { params });
    }

    getRequestsWithNoInvoiceByOrganizationPublicIds(organizationPublicIds: string[]): Observable<any> {
        const url_ = this.getUrl('/api/requests/GetRequestsWithNoInvoiceByOrganizationPublicIds');
        return this.http.post<any>(url_, organizationPublicIds);
    }

    getCandidateRequestCountNotDraftOrInProgressCandidate(candidateId): Observable<number> {
        const url_ = this.getUrl('/api/requests/GetCandidateRequestCountNotDraftOrInProgressCandidate');
        const params = new HttpParams().set('candidateId', candidateId);
        return this.http.get<any>(url_, { params });
    }

    sendEzsignRequest(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/SendEzsignRequest/${requestPublicId}`);
        return this.http.post<any>(url_, {});
    }

    cancelEzsignRequest(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/CancelEzsignRequest/${requestPublicId}`);
        return this.http.delete<any>(url_);
    }

    sendToPpo(requestPublicId: string): Observable<any> {
        const url_ = this.getUrl(`/api/requests/SendPpoRequest/${requestPublicId}`);
        return this.http.post<any>(url_, {});
    }

    getRequestsStatistics(numberOfDays: number): Observable<any> {
        const url_ = this.getUrl('/api/requests/statistics');
        const params = new HttpParams().set('numberOfDays', numberOfDays);
        return this.http.get<any>(url_, { params });
    }

    getRows(params: IServerSideGetRowsParams): void {
        const request = {
            startRow: params.request.startRow,
            endRow: params.request.endRow,
            sortModel: params.request.sortModel,
            filterModel: overallAdjustLocalDateRangeToUtcDateTimeRange(params.request.filterModel)
        }
        this.http.post<IAjaxResponse<RequestsFilteredOutput>>(this.getUrl('/api/requests/filter'), request).toPromise().then(
            value => {
                params.successCallback(value.result.results, value.result.count);
            },
            error => {
                params.failCallback();
            }
        );
    }

    private getUrl(url: string): string {
        let url_ = this.baseUrl + url;
        url_ = url_.replace(/[?&]$/, '');
        return url_;
    }
}
