import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UtilsModule } from '@shared/utils/utils.module';
import { NgIf } from '@angular/common';
import { AppLocalizationService } from '@app/shared/common/localization/app-localization.service';

/**
 * A component used to display a boolean input using a radio button with the labels yes/no.
 * @params *__name__* - The id of the question used for the control's name. Will also be used for the html id.
 * @params *__questionControl__* - The form control connected to the question. If used inside a {@link FormGroupComponent} the control will be added automatically.
 */
@Component({
    standalone: true,
    selector: 'boolean-input[name]',
    imports: [UtilsModule, ReactiveFormsModule, NgIf],
    templateUrl: './boolean-input.component.html'
})
export class BooleanInputComponent {
    @Input() protected readonly name: string;
    @Input() protected readonly questionControl!: FormControl;
    @Input() protected readonly language: string;

    constructor(
        private readonly appLocalizationService: AppLocalizationService,
    ) { }

    protected get yesLabel(): string {
        return this.appLocalizationService.localizeTo(this.getLanguage(), 'Forms.Choices.Yes');
    }

    protected get noLabel(): string {
        return this.appLocalizationService.localizeTo(this.getLanguage(), 'Forms.Choices.No');
    }

    private getLanguage(): string {
        return this.language ?? this.appLocalizationService.currentLanguage.name;
    }
}
