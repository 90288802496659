import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment as env } from 'environments/environment';
import ReferenceCreationInput from '@shared/models/reference/referenceCreationInput';
import Reference from '@shared/models/reference/reference';
import { catchError, map } from '@node_modules/rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ReferenceService {
    private static readonly getUrl = (url: string): string => (env.api + '/api/reference' + url).replace(/[?&]$/, '');

    constructor(private readonly http: HttpClient) { }

    public addReference(referenceInput: ReferenceCreationInput): Observable<Reference> {
        const reference: Reference = {
            firstName: referenceInput.firstName,
            lastName: referenceInput.lastName,
            email: referenceInput.email,
            phoneNumber: referenceInput.phoneNumber,
            creationDate: new Date(),
            lastUpdateDate: null,
            lastTimeSentToReference: null,
            status: referenceInput.status,
            requestVerificationId: referenceInput.requestVerificationId,
            verificationForm: referenceInput.verificationForm,
            language: referenceInput.language
        };

        return this.http.post<{ result: Reference }>(ReferenceService.getUrl('/'), reference).pipe(
            map(response => response.result),
            catchError(err => throwError(err))
        );
    }

    public getReferencesByRequestId(requestId: string): Observable<Reference[]> {
        return this.http.get<{ result: Reference[] }>(ReferenceService.getUrl(`/by-request-id?requestId=${requestId}`)).pipe(
            map(response => response.result),
            catchError(err => throwError(err))
        );
    }

    public updateReference(reference: Reference): Observable<Reference> {
        return this.http.put<{ result: Reference }>(ReferenceService.getUrl('/'), reference).pipe(
            map(response => response.result),
            catchError(err => throwError(err))
        );
    }

    public deleteReference(referenceId: number): Observable<void> {
        return this.http.delete<void>(ReferenceService.getUrl(`/${referenceId}`)).pipe(
            catchError(err => throwError(err))
        );
    }

    public sendToReference(referenceId: number): Observable<void> {
        return this.http.post<void>(ReferenceService.getUrl('/send-reference-mail'), referenceId).pipe(
            catchError(err => throwError(err))
        );
    }
}
